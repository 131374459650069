/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import ItemReview from './component/ItemReview';
import ItemSlide from '../../component/slider/ItemSlide';
import ModalContext from '../../context/modal/modal';
import UserContext from '../../context/user/user';
import { isFree } from '../../util/itemData';
import DownloadButton from './component/DownloadButton';
import BuyDataNowButton from './component/BuyDataNowButton';
import RewardBuyButton from './component/RewardBuyButton';
import ItemBanner from './component/ItemBanner';
import ItemComment from './component/ItemComment';
import ItemInfo from './component/ItemInfo';
import ItemPrice from './component/ItemPrice.jsx';
import './ItemDetail.css';
import {
  createCartAdd,
  getItemComments,
  getItemDetail,
  getItemInfo,
  getItemInfoV2,
  getItemLike,
  getItemReview,
  getMyTickets,
  getStudio,
  isItemLike,
  toggleItemLike,
  getItemOwn,
} from '../../action/request';
import LangContext from '../../context/lang/lang';
import { useParams, useLocation } from 'react-router-dom';
import ItemRecommand from '../../component/item/ItemRecommand';
import CartContext from '../../context/cart.js/carts';
import { gtagAddToCart, gtagViewItemDetailPage } from '../../action/gTag';
import { ls } from '../../constant';
import CommentImageViewer from '../../component/comment/CommentImageViewer';
import DownloadProcessingModal from '../../component/modal/DownloadProcessingModal';
import styled, { css } from 'styled-components';
import { ImgStyled } from '../../styles/ImgStyled.jsx';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { paginationAtom, preDesignerListAtom, designerListAtom } from '../../atom/atom.js';
import { DESIGNERS } from '../../route/constant.js';
import { removeSpecialCharactersAndSpaces } from '../../component/section/util.js';
import { updateLikeState } from '../../util/updateLikeState.js';

const ItemDetail = () => {
  const navigate = useNavigate();
  const setPreDesignerList = useSetRecoilState(preDesignerListAtom);
  const [twitterUrl, setTiwitterUrl] = useState('');
  const [pinUrl, setPinUrl] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [itemLikeCount, setItemLikeCount] = useState(0);
  const [detail, setDetail] = useState({});
  const [studio, setStudio] = useState({});
  const [studioId, setStudioId] = useState();
  const [itemData, setItemData] = useState({});
  const [itemDataV2, setItemDataV2] = useState({});
  const [hasOwn, setHasOwn] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [review, setReviews] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewPage, setReviewPage] = useState(1);
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [commentPage, setCommentPage] = useState(1);
  const [commentTotalPage, setCommentTotalPage] = useState(1);
  const [freeTicketCount, setFreeTicketCount] = useState();
  const [isStudioDescriptionVisible, setIsStudioDescriptionVisible] = useState(false);
  const [iframeSrc, setIframeSrc] = useState([]);
  const [desc, setDesc] = useState();
  const [showImgViewer, setShowImgViewer] = useState(false);
  const [imgData, setImgData] = useState({ index: null, data: {} });
  const [isLoginState, setIsLoginState] = useState(false);
  const [downloadModalStep, setDownloadModalStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);
  const [, setLikeState] = useState();

  const { action } = useContext(ModalContext);
  const { state } = useContext(UserContext);
  const { action: langAction } = useContext(LangContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const { id } = useParams();

  const [, setIndex] = useRecoilState(paginationAtom);

  const index = useLocation();
  const { state: location } = useLocation();
  const fromDesignerSection = location?.location === 'fromDesignerSection';

  // 트위터 공유
  const buildTwitterUrl = item => {
    setTiwitterUrl(
      'https://twitter.com/intent/tweet?text=' +
        encodeURIComponent(item.name) +
        '&url=' +
        encodeURIComponent('https://fab365.net/items/') +
        item.id
    );
  };
  // 핀터레스트 공유
  const buildPinUrl = item => {
    setPinUrl(
      'https://www.pinterest.com/pin/create/button/?description=' +
        encodeURIComponent(item.name) +
        '&url=' +
        encodeURIComponent('https://fab365.net/items/') +
        item.id +
        '&media=' +
        encodeURIComponent(item.listImg)
    );
  };
  // 페이스북 공유
  const buildFacebookUrl = item => {
    setFacebookUrl(
      'https://www.facebook.com/dialog/share?app_id=' +
        encodeURIComponent(process.env.REACT_APP_FB_APP_ID) +
        '&display=popup&href=' +
        encodeURIComponent('https://fab365.net/items/') +
        item.id
    );
  };

  const addBag = async designerId => {
    if (!state.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      action.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);
          alert(res.result);

          const list = designerList?.filter(
            item => Number(item.designer.id_studio) === Number(designerId)
          );

          if (list) {
            const newCards = list[0].items.map(card =>
              card.product_id === id
                ? {
                    ...card,
                    in_cart: !card.in_cart,
                  }
                : card
            );
            const result = designerList.map(item =>
              Number(item.designer.id_studio) === Number(designerId)
                ? {
                    designer: item.designer,
                    items: newCards,
                  }
                : item
            );

            setDesignerList(result);
          }

          // gtag 장바구니 추가 태그
          // console.log('price', Number(detail.price));
          gtagAddToCart({ value: Number(detail.price), itemId: id });
        }
      } catch (e) {}
    }
  };

  // 좋아요 버튼 클릭
  const onClickLikeButton = async designerId => {
    if (!state.isLogin) {
      action.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({
        idItem: id,
      });

      if (designerList) {
        const list = designerList?.filter(
          item => Number(item.designer.id_studio) === Number(designerId)
        );

        if (data) {
          const updatedCards = await updateLikeState({
            id: id,
            setLikeState: setLikeState,
            cards: list[0]?.items,
            count: itemLikeCount,
            data: data,
          });

          const result =
            updatedCards &&
            designerList?.map(item =>
              Number(item.designer.id_studio) === Number(designerId)
                ? {
                    designer: item.designer,
                    items: updatedCards,
                  }
                : item
            );

          setDesignerList(result);
        }
      }

      // console.log('좋아요 토글', likeToggleRes);
      // 좋아요 개수 업데이트
      getLikeCount(id);
      // // 사용자가 좋아요 눌렀는지 상태 확인
      getUserLikeState(id);
    }
  };

  const fetchData = async () => {
    try {
      const { data: resDetail } = await getItemDetail({ id, isLoginState });

      setDetail(resDetail.item);
      setDesc(resDetail.item.desc);
      setStudioId(resDetail.item.studio_id);
      // setHasOwn(resDetail.item.isOwn);
      // console.log(resDetail);
      const { data: resInfo } = await getItemInfo({ id });
      setItemData(resInfo.item);

      // 좋아요 개수 업데이트
      getLikeCount(id);
      // 사용자가 좋아요 눌렀는지 상태 확인
      getUserLikeState(id);
      setIsLoading(false);
      const { data: resOwn } = await getItemOwn({
        idItem: id,
      });
      setHasOwn(resOwn.result);

      // TODO:meta image

      const { data: resItemInfoV2 } = await getItemInfoV2({
        id,
      });
      setItemDataV2(resItemInfoV2.item);

      reviewPageChangeHandler();
      commentPageHandler();

      // gtag 아이템 상세페이지 조회 태그
      if (id) {
        gtagViewItemDetailPage(id);
        // console.log('tag test', window.location.href, id);
      }

      // 로그인 상태라면 프리티켓 조회
      if (state.isLogin) {
        const {
          data: { data: freeTickets },
        } = await getMyTickets();
        setFreeTicketCount(freeTickets.ticketCount);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    let isLoginUsingToken =
      localStorage.getItem(ls.accessToken) !== 'undefined' &&
      localStorage.getItem(ls.accessToken) !== 'null' &&
      localStorage.getItem(ls.accessToken);
    if (isLoginUsingToken) {
      setIsLoginState(true);
    } else {
      setIsLoginState(false);
    }
  }, [state.isLogin]);

  useEffect(() => {
    if (index.state) {
      setIndex(index.state);
    }
    fetchData();
  }, [state?.isLogin, isLoginState]);

  useEffect(() => {
    // console.log('isLoginState', isLoginState);
  }, [isLoginState]);

  // detail 상태 변화시 SNS 공유 정보 업데이트
  useEffect(() => {
    buildFacebookUrl(detail);
    buildPinUrl(detail);
    buildTwitterUrl(detail);
  }, [detail]);

  // detail.item.desc에서 비디오 src 추출
  useEffect(() => {
    if (desc) {
      const iframeRegex = /<iframe[^>]*src="([^"]*)"[^>]*>/g;
      const matches = desc.matchAll(iframeRegex);
      const srcList = Array.from(matches, match => match[1]);
      setIframeSrc(srcList);
      // console.log('srcList', srcList);
    }
  }, [desc]);

  // 좋아요 개수 확인
  const getLikeCount = async id => {
    const { data: likeCountRes } = await getItemLike({ id: id });
    setItemLikeCount(likeCountRes.result);
  };

  // 사용자가 좋아요 눌렀는지 상태 확인
  const getUserLikeState = async idItem => {
    const { data: getUserLike } = await isItemLike({ idItem });
    // 좋아요 상태 업그레이드
    setIsLike(getUserLike.result);
  };

  const reviewPageConfrim = page => {
    setReviewPage(page);
  };

  useEffect(() => {
    reviewPageChangeHandler();
  }, [reviewPage]);

  // 스튜디오 아이디로 스튜디오 정보 가져오기
  const getStudioInfo = async () => {
    const { data: resStudio } = await getStudio({
      studioId: studioId,
    });
    setStudio(resStudio.data);
  };

  // 스튜디오 아이디 업데이트 되면 실행
  useEffect(() => {
    getStudioInfo(studioId);
  }, [studioId]);

  const reviewPageChangeHandler = async () => {
    const { data: resItemReview } = await getItemReview({
      idItem: id,
      page: reviewPage,
    });
    setReviews(resItemReview.data.reviews);
    setReviewCount(resItemReview.data.count);
  };

  const commentPageConfirm = page => {
    setCommentPage(page);
  };

  useEffect(() => {
    commentPageHandler();
  }, [commentPage]);

  const commentPageHandler = async () => {
    const { data: resItemComments } = await getItemComments({
      idItem: id,
      page: commentPage,
    });
    const comments = [];
    const results = resItemComments?.data?.comments;
    if (results === null || results === undefined) {
      return setComments([]);
    }
    for (let i = 0; i < results.length; i++) {
      if (results[i]?.id_parent === '0') {
        comments.push(results[i]);
      }
    }
    for (let i = 0; i < results.length; i++) {
      if (results[i]?.id_parent !== '0') {
        for (let j = 0; j < comments.length; j++) {
          if (comments[j].id_comment === results[i].id_parent) {
            comments[j].replys = comments[j].replys ? comments[j].replys : [];
            comments[j].replys.push(results[i]);
          }
        }
      }
    }
    setComments(comments);
    setCommentTotalPage(resItemComments.data.groupCount);
    setCommentCount(resItemComments.data.commentCount);
  };

  const onClickPage = studio => {
    navigate(`${DESIGNERS}/${removeSpecialCharactersAndSpaces(studio?.name)}`, {
      state: { id: studio?.id_studio },
    });
  };

  useEffect(() => {
    setPreDesignerList(fromDesignerSection);
  }, []);

  return (
    <>
      <PageMobileStyle id='main' step={downloadModalStep}>
        <Layout>
          {studio && (
            <div id='main2'>
              <section className='store-detail-section'>
                <div className='container'>
                  <div className='store-detail-content'>
                    <ItemSlide data={itemData?.imgs} studioName={studio?.name} />
                    <div className='store-detail-content-description'>
                      <div className='store-detail-content-description-header'>
                        <div className='product-info'>
                          <h2 className='product-name'>{detail.name}</h2>
                          {isLoading ? (
                            <div style={{ height: '45px' }}></div>
                          ) : (
                            detail.status !== 'secret' && (
                              <ItemPrice
                                itemNumber={detail.id}
                                price={isFree(detail.free) ? 0 : detail.price}
                                freeTicketCount={freeTicketCount}
                                isLogin={state.isLogin}
                                qty={detail.qty}
                                discountRate={detail.discountRate}
                                originPrice={detail.originPrice}
                                finalPrice={detail.price}
                                secretProduct={detail.status}
                              />
                            )
                          )}
                        </div>

                        {detail && (
                          <div className='product-actions'>
                            {state.isLogin && detail.isOwn && (
                              <DownloadButton
                                price={detail.price}
                                id={detail.id}
                                isOwn={hasOwn}
                                isLogin={state.isLogin}
                                freeTicketCount={freeTicketCount}
                                isLoading={isLoading}
                                setStep={setDownloadModalStep}
                                secretProduct={detail.status}
                              />
                            )}
                            {detail.price !== '0.00' && !detail.isOwn && (
                              <>
                                <BuyDataNowButton
                                  price={detail.price}
                                  id={detail.id}
                                  isOwn={hasOwn}
                                  isLogin={state.isLogin}
                                  freeTicketCount={freeTicketCount}
                                  isLoading={isLoading}
                                  setStep={setDownloadModalStep}
                                  showProduct={detail.status}
                                />
                              </>
                            )}
                            {detail.price === '0.00' && !detail.isOwn && (
                              <RewardBuyButton
                                qty={detail.qty}
                                id={detail.id}
                                isLogin={state.isLogin}
                                freeTicketCount={freeTicketCount}
                                isLoading={isLoading}
                                secretProduct={detail.status}
                              />
                            )}
                            <div className='btns'>
                              {hasOwn ||
                                (detail.price > 0 && (
                                  <button
                                    className={
                                      detail.status === 'secret'
                                        ? 'btn btn-cart btn-cart-add disabled-color'
                                        : 'btn btn-cart btn-cart-add'
                                    }
                                    disabled={detail.status === 'secret' && true}
                                    onClick={() => addBag(Number(detail?.studio_id))}
                                  >
                                    {detail.status === 'secret' ? 'Not available' : 'Add to Cart'}
                                  </button>
                                ))}
                              <button
                                className={
                                  detail.status === 'secret'
                                    ? 'btn disabled-border-gray btn-heart like-toggle disabled-color '
                                    : 'btn btn-green btn-heart like-toggle'
                                }
                                onClick={() => onClickLikeButton(Number(detail?.studio_id))}
                                disabled={detail.status === 'secret' && true}
                              >
                                <span
                                  className={
                                    'count like-count ' +
                                    (isLike ? 'active' : '') +
                                    (detail.status === 'secret' ? 'disabled' : '')
                                  }
                                >
                                  {itemLikeCount}
                                </span>
                              </button>
                            </div>
                            <ItemBanner price={detail.price} isLogin={state.isLogin} />
                          </div>
                        )}

                        <div className='share'>
                          <a
                            href={twitterUrl}
                            target='_new'
                            className={detail.status === 'secret' ? 'share-disabled' : ''}
                          >
                            <i className='fab fa-x-twitter'></i>
                          </a>
                          <a
                            href={facebookUrl}
                            target='_new'
                            className={detail.status === 'secret' ? 'share-disabled' : ''}
                          >
                            <i className='fab fa-facebook-f'></i>
                          </a>
                          <a
                            href={pinUrl}
                            target='_new'
                            className={detail.status === 'secret' ? 'share-disabled' : ''}
                          >
                            <i className='fab fa-pinterest'></i>
                          </a>
                        </div>
                        <div className='creator'>
                          {/* TODO:디자이너 모델 상세 페이지의 디자이너 이름 부분*/}
                          <div className='creator-header' onClick={() => onClickPage(studio)}>
                            <div className='profile-pic'>
                              <ImgStyled src={studio?.logo} alt='' />
                            </div>
                            <div className='creator-info'>
                              <div className='creator-job'>Designer</div>
                              <h3 className='creator-name'>{studio?.name}</h3>
                            </div>
                          </div>
                          <div className='creator-body'>
                            <div
                              className={
                                'creator-bio ' + (isStudioDescriptionVisible ? 'active' : '')
                              }
                            >
                              <p>{langAction.isKo() ? studio?.desc_ko : studio?.desc_en}</p>
                            </div>
                            <div className='readmore'>
                              <a
                                className='txt-green'
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  setIsStudioDescriptionVisible(!isStudioDescriptionVisible)
                                }
                              >
                                <span className='more'>+ more</span>
                                <span className='less'>- less</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='store-detail-tabs'>
                  <div className='store-detail-tab-menu'>
                    <div className='container'>
                      <ul className='menu'>
                        <li className={selectedTab === 0 ? 'active' : ''}>
                          <a
                            className='item-tab'
                            data-target='#store_detail_information'
                            onClick={() => setSelectedTab(0)}
                            style={{ cursor: 'pointer' }}
                          >
                            <span
                              className='qlabel'
                              its-ta-ident-ref='http://www.wikidata.org/entity/Q11028'
                            >
                              Information
                            </span>
                          </a>
                        </li>
                        <li className={selectedTab === 1 ? 'active' : ''}>
                          <a
                            className='item-tab'
                            data-target='#store_detail_comments'
                            onClick={() => setSelectedTab(1)}
                            style={{ cursor: 'pointer' }}
                          >
                            <span>
                              Comments <small className='badge'>{commentCount}</small>
                            </span>
                          </a>
                        </li>
                        <li className={selectedTab === 2 ? 'active' : ''}>
                          <a
                            className='item-tab'
                            data-target='#store_detail_review'
                            style={{ cursor: 'pointer' }}
                            onClick={() => setSelectedTab(2)}
                          >
                            <span>
                              Review <small className='badge'>{reviewCount}</small>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='store-detail-tab-contents'>
                  {selectedTab === 0 && (
                    <ItemInfo
                      item={detail}
                      itemDatas={itemData}
                      itemdata2s={itemDataV2}
                      iframeSrc={iframeSrc}
                    />
                  )}
                  {selectedTab === 1 && (
                    <>
                      <ItemComment
                        id={detail.id}
                        comments={comments}
                        count={commentTotalPage}
                        page={commentPage}
                        onReload={commentPageHandler}
                        onCommentPageConfirm={commentPageConfirm}
                        setShowImgViewer={setShowImgViewer}
                        setImgData={setImgData}
                        showProduct={detail.status}
                      />
                    </>
                  )}
                  {selectedTab === 2 && (
                    <ItemReview
                      id={detail.id}
                      reviews={review}
                      count={reviewCount}
                      onReviwPageConfirm={reviewPageConfrim}
                      page={reviewPage}
                      showProduct={detail.status}
                    />
                  )}
                </div>
                <hr />
                <ItemRecommand itemId={detail.id} />
              </section>
            </div>
          )}
        </Layout>
      </PageMobileStyle>
      {showImgViewer && (
        <CommentImageViewer imgData={imgData} setShowImgViewer={setShowImgViewer} />
      )}
      {/* 다운로드 모달 */}
      {downloadModalStep > 0 && (
        <DownloadProcessingModal
          itemId={detail.id}
          step={downloadModalStep}
          setStep={setDownloadModalStep}
        />
      )}
    </>
  );
};

export default ItemDetail;

const PageMobileStyle = styled.div`
  ${props =>
    props.step !== 0 &&
    css`
      height: 100vh;
      overflow: hidden;
    `}
  /* STL다운로드 모달 640px 이하에서 띄울 시 스크롤바 없애기 */
   @media screen and (max-width: 640px) {
    ${props =>
      props.step !== 0 &&
      css`
        display: none;
      `}
  }
`;
