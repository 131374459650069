import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { NOTICE } from '../../route/constant';

const NoticePopupContainer = styled.div`
  position: absolute;
  z-index: 999;
  width: 300px;
  height: 400px;
  border-radius: 6px;
  top: 100px;
  left: 100px;
  display: grid;
  grid-template-rows: 7fr 1fr;
  user-select: none;
  @media (max-width: 640px) {
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    transition: all.2s;
  }
  @media (max-width: 320px) {
    width: 90%;
  }
  .content-wrap {
    width: 100%;
    background-image: url('/assets/img/notice/background.png');
    border-radius: 6px 6px 0 0;
    background-size: cover;
    text-align: center;
    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 22px;
      line-height: 27.72px;
      padding-top: 35px;
      color: #fff;
      span {
        color: #72ff6f;
      }
    }
    img {
      width: 48px;
      padding: 10px 0 5px 0;
    }
    p {
      font-weight: 300;
      color: #fff;
      text-align: center;
      font-size: 14px;
    }
    button {
      width: 160px;
      height: 35px;
      background-color: #fff;
      border-radius: 999px;
      color: #2a2a2a;
      padding: 10px;
      font-size: 12px;
      border: none;
      margin-top: 20px;
      cursor: pointer;
      font-family: Montserrat, sans-serif;
    }
  }
  .button-wrap {
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 0 0 6px 6px;
    display: flex;
    padding: 10px 15px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    input,
    label {
      cursor: pointer;
      font-size: 12px;
    }
    div {
      display: flex;
      align-items: center;
    }
    label {
      padding-left: 5px;
      color: #2a2a2a;
      font-weight: 400;
    }
    button {
      border: none;
      font-size: 14px;
      width: auto;
      background-color: transparent;
      color: #2a2a2a;
      padding: 0 0 2px 0;
      border-bottom: 1px solid #2a2a2a;
      font-weight: 400;
      cursor: pointer;
      font-family: Montserrat, sans-serif;
    }
  }
`;

const NoticePopup = ({ setShowPopup }) => {
  const [isCheck, setIsCheck] = useState(false);
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <NoticePopupContainer>
      <div className='content-wrap'>
        <h2>
          SERVICE
          <br />
          IMPROVEMENT
          <br />
          <span>NOTICE</span>
        </h2>
        <img src='/assets/img/notice/notice-icon.png' alt='' />
        <p>
          To provide better service,
          <br />
          our reward policy will change.
          <br />
          Please check the detailed
          <br />
          information on the linked page.
        </p>
        <button onClick={e => goTo(e, NOTICE)}>Go to see the details</button>
      </div>
      <div className='button-wrap'>
        <div>
          <input
            type='checkbox'
            id='1Day'
            checked={isCheck}
            onClick={() => {
              setIsCheck(prev => !prev);
            }}
          />
          <label htmlFor='1Day'>Do not show for a day</label>
        </div>
        <button
          className='close-btn'
          onClick={e => {
            if (isCheck) {
              localStorage.setItem('lastPopupViewed', new Date().toISOString());
              setShowPopup(false);
            } else {
              setShowPopup(false);
            }
          }}
        >
          Close
        </button>
      </div>
    </NoticePopupContainer>
  );
};

export default NoticePopup;
