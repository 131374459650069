/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { MY_SALES_PRODUCT } from '../../../route/constant';
import { getProductSales } from '../../../action/request';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';
import { formatDesignerNumber } from './utils';
import { useRecoilState } from 'recoil';
import { seletecdMoreAtom, seletecdPidAtom } from '../../../atom/atom';
import { MyContents } from '../../../component/designers/designers';
import { BackBtn } from '../../../component/designers/designers';
const SalesProduct = ({ moveScroll }) => {
  const [sales, setSales] = useState([]);
  const [isDownloadASC, setIsDownloadASC] = useState(false);
  const [isEarningASC, setIsEarningASC] = useState(false);
  const [totalDownload, setTotalDownload] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [, setSelectedMore] = useRecoilState(seletecdMoreAtom);
  const [, setPid] = useRecoilState(seletecdPidAtom);

  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    fetchProductSales();
  }, []);

  const fetchProductSales = async () => {
    try {
      const { data: res } = await getProductSales();
      // console.log('getProductSales', res);
      if (res) {
        setSales(res.data.salesProduct.reverse());
        setProductsCount(res.data.productsCount);
      }
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    let d = 0;
    let e = 0;
    sales.forEach(s => {
      d += parseInt(s.count);
      e += parseFloat(s.earning);
    });
    setTotalDownload(d);
    setTotalEarning(e);
  }, [sales]);

  const sortDownload = () => {
    setSales(sort('count', !isDownloadASC));
    setIsDownloadASC(!isDownloadASC);
  };
  const sortEarning = () => {
    setSales(sort('earning', !isEarningASC));
    setIsEarningASC(!isEarningASC);
  };

  const swap = (src, l, r) => {
    var temp = src[l];
    src[l] = src[r];
    src[r] = temp;
  };
  const sort = (field, desc) => {
    let result = [...sales];
    for (let i = 0; i < result.length; i++) {
      for (let j = i + 1; j < result.length; j++) {
        if (field === 'count') {
          var checked = desc
            ? parseInt(result[i].count) > parseInt(result[j].count)
            : parseInt(result[i].count) < parseInt(result[j].count);
          if (checked) {
            swap(result, i, j);
          }
        }

        if (field === 'earning') {
          var checked = desc
            ? parseFloat(result[i].earning) > parseFloat(result[j].earning)
            : parseFloat(result[i].earning) < parseFloat(result[j].earning);
          if (checked) {
            swap(result, i, j);
          }
        }
      }
    }
    return result;
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  const goToMySales = e => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedMore('my');
    moveScroll();
  };

  return (
    <>
      <>
        <MyContents id='main'>
          <section className='mypage-section mysale'>
            <div className='container'>
              <div className='content-box mysales'>
                <div className='content-box-body'>
                  <div className='__grid'>
                    <div className='fab-mypage-mysales-box __fullwidth '>
                      <div className='__header'>
                        <h3 className='__title'>My Products</h3>
                      </div>
                      <div className='__body'>
                        <div className='__value'>
                          <strong>{formatDesignerNumber(productsCount)} ea</strong>
                        </div>
                      </div>
                    </div>

                    <BackBtn>
                      <div className='__footer'>
                        <button className='fab-btn-back' onClick={goToMySales}>
                          <svg
                            width='36'
                            height='42'
                            viewBox='0 0 36 42'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.2957 30.7041L7.42523 22.8336L24.9996 22.8336L24.9996 19.1669L7.42523 19.1669L15.2957 11.2964L12.7034 8.7041L0.407226 21.0003L12.7034 33.2964L15.2957 30.7041Z'
                              fill='#4200F8'
                            />
                          </svg>{' '}
                          Back
                        </button>
                      </div>
                    </BackBtn>
                    <div className='fab-mypage-mysales-table __fullwidth'>
                      <div className='__header'>
                        <div className='__title-area'>
                          <h4 className='__title'>Total Earning by Products</h4>
                          <p className='__desc'>
                            Shows each product's earnings and total downloads.
                          </p>
                        </div>
                      </div>
                      <div className='__body'>
                        <div className='fab-table-wrapper'>
                          <div className='__scroll-area'>
                            <table>
                              <colgroup>
                                <col width='50%' />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '13.33%' }} />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th className='__txt-left'>Products</th>
                                  <th>
                                    Download{' '}
                                    <button
                                      className={'__sort' + (isDownloadASC ? ' __desc' : '')}
                                      onClick={sortDownload}
                                    >
                                      <svg
                                        width='7'
                                        height='6'
                                        viewBox='0 0 7 6'
                                        fill='none'
                                        value='download'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M3.5 6L0.468912 0.749999L6.53109 0.75L3.5 6Z'
                                          fill='black'
                                        />
                                      </svg>
                                    </button>
                                  </th>
                                  <th>
                                    Earnings{' '}
                                    <button
                                      className={'__sort' + (isEarningASC ? ' __desc' : '')}
                                      onClick={sortEarning}
                                    >
                                      <svg
                                        width='7'
                                        height='6'
                                        viewBox='0 0 7 6'
                                        fill='none'
                                        value='earning'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M3.5 6L0.468912 0.749999L6.53109 0.75L3.5 6Z'
                                          fill='black'
                                        />
                                      </svg>
                                    </button>
                                  </th>
                                  <th>Detail</th>
                                </tr>
                              </thead>
                              <tbody id='product-list'>
                                {sales.map(s => {
                                  return (
                                    <tr key={s.id_item}>
                                      <td className='__txt-left'>{s['items']?.name_en}</td>
                                      <td>{formatDesignerNumber(s.count)}</td>
                                      <td>$ {formatDesignerNumber(s.earning.toFixed(2))}</td>
                                      <td>
                                        <a
                                          href=''
                                          onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // navigate(MY_SALES_PRODUCT + '/' + s.id_item);
                                            setSelectedMore('Product');
                                            setPid(s.id_item);
                                            moveScroll();
                                          }}
                                        >
                                          more
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {sales.length === 0 && (
                                  <tr>
                                    <td colSpan='5'>
                                      <div className='nodata'>No Data to Display</div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td className='__txt-right'>Total</td>
                                  <td>{formatDesignerNumber(totalDownload)}</td>
                                  <td>$ {formatDesignerNumber(totalEarning.toFixed(2))}</td>
                                  <td></td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='__footer'>
                        <button className='fab-btn-back' onClick={goToMySales}>
                          <svg
                            width='36'
                            height='42'
                            viewBox='0 0 36 42'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M15.2957 30.7041L7.42523 22.8336L24.9996 22.8336L24.9996 19.1669L7.42523 19.1669L15.2957 11.2964L12.7034 8.7041L0.407226 21.0003L12.7034 33.2964L15.2957 30.7041Z'
                              fill='#4200F8'
                            />
                          </svg>{' '}
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </MyContents>
      </>
    </>
  );
};

export default SalesProduct;
