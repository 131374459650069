import React, { useState } from 'react';
import styled from 'styled-components';

const LinkModal = ({ saveLink }) => {
  const [linkText, setLinkText] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  return (
    <Link>
      <input placeholder='텍스트' value={linkText} onChange={e => setLinkText(e.target.value)} />
      <div className='link'>
        <input placeholder='링크' value={linkUrl} onChange={e => setLinkUrl(e.target.value)} />
        <button onClick={() => saveLink(linkText, linkUrl)}>적용</button>
      </div>
    </Link>
  );
};

export default LinkModal;

const Link = styled.div`
  width: 400px;
  background-color: #ffffff;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 500;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 20px;

  input {
    width: 80%;
    padding: 5px;
  }

  .link {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    button {
      height: 30px;
      padding: 0px 10px;
      border: none;
    }
  }
`;
