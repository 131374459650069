/* eslint-disable jsx-a11y/anchor-is-valid */
import { sanitize } from 'dompurify';
import { useState } from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import UserContext from '../../context/user/user';
import {
  formatHrefLink,
  imgHtml,
  imgsHtml,
  imgsToMedias,
  isEditableItemComment,
} from '../../util/comment';
import { DEFAULT_AVATAR } from '../avatar/const';
import { uploadItemImage } from '../../action/request';
import { preventScroll } from '../../util/commentImageViewer';
import { ImgStyled } from '../../styles/ImgStyled';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CommentBody = ({
  comment,
  pkComment,
  onDelete,
  onEdit,
  showReply,
  setShowImgViewer,
  setImgData,
  showProduct,
  value,
  onReload,

  designerId,
  onClickModel,
}) => {
  const [editBody, setEditBody] = useState(comment['comment']);
  const [isEdit, setIsEdit] = useState(false);
  const [imgs, setImgs] = useState(imgsHtml(comment));
  const [medias, setMedias] = useState(imgsToMedias(comment['imgs']));
  const [loading, setLoading] = useState(false);
  const imageInput = useRef(null);
  const { state } = useContext(UserContext);
  const designer = Number(designerId) === Number(comment?.user?.id_user);
  const designerItemCheck = !comment?.item || comment.id_item === '0';

  // body = 댓글 내용 (스트링)
  const body = comment['comment'];
  const forData = {
    oid_comment: comment[pkComment],
    body: body,
    medias: medias,
  };

  const imageChanged = async e => {
    try {
      const files = e.target.files;
      const newMedias = [...medias];
      const newImages = [...imgs];
      setLoading(true);
      for (let i = 0; i < files.length; i++) {
        const { data: res } = await uploadItemImage({
          file: files[i],
        });
        newImages.push(imgHtml(res.img.thumbs[0].url));
        newMedias.push(res.img);
      }
      setMedias(newMedias);
      setImgs(newImages);
      setLoading(false);
    } catch (e) {
      alert('request failed');
    }
  };

  const editComment = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    // 아무 내용 없다면 알림
    if (!editBody || editBody === '') {
      return alert('Comment is empty');
    }

    if (value === 'designer') {
      onEdit(id, editBody, medias);
    } else {
      onEdit({
        comment: comment[pkComment],
        msg: editBody,
        medias,
        imgs: medias.map(media => JSON.stringify(media)),
      });
    }

    setIsEdit(false);
    onReload();
  };
  const onDeleteImage = idx => {
    const newMedias = medias.filter((_, i) => i !== idx);
    const newImages = imgs.filter((_, i) => i !== idx);
    setMedias(newMedias);
    setImgs(newImages);
  };

  const onClickImg = (imgArr, idx) => {
    setShowImgViewer(true);
    setImgData({ index: idx, data: imgArr });
    preventScroll();
  };

  return (
    <>
      {value === 'designer' ? (
        <>
          {designer && designerItemCheck ? (
            <Box>
              <ToBox bg='#4300F8'>Designer</ToBox>
            </Box>
          ) : (
            <>
              {comment?.item && comment.id_item !== '0' ? (
                <Box>
                  <Link onClick={() => onClickModel()} to={`/items/${comment?.id_item}`}>
                    <div className='to-model'>
                      <img src={comment?.item?.list_img} className='item-img' />
                      <div className='model-info'>
                        <ToBox bg='#565656'>Model</ToBox>
                        <p>{comment.item.name_en}</p>
                      </div>
                    </div>
                  </Link>
                </Box>
              ) : (
                <Box>
                  <ToBox bg='#0CA101'>User</ToBox>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        ''
      )}

      <div className='comment-item' data-comment={sanitize(JSON.stringify(forData))}>
        {isEdit && (
          <div className='comment-write'>
            <div className='profile'>
              <div className='profile-pic'>
                <ImgStyled
                  src={comment.user.thumbnail ? comment.user.thumbnail : DEFAULT_AVATAR}
                  alt=''
                />
                {Number(designerId) === Number(comment?.user?.id_user) ? (
                  <img src={'/assets/img/designers/crown.png'} className='crown' />
                ) : (
                  ''
                )}
              </div>
            </div>
            <form className='comment-write-form' data-action='1101-comment-edit-submit'>
              <div className='write-form'>
                <input type='hidden' name='comment' value={comment[pkComment]} />
                <textarea
                  placeholder='Please input your text'
                  name='msg'
                  value={editBody}
                  onChange={e => setEditBody(e.target.value)}
                ></textarea>
                <div className='attachments'>
                  <ul className='files'>
                    {imgs?.map((thumb, idx) => {
                      return (
                        <li key={idx + thumb.url}>
                          <div className='file-item'>
                            <a>
                              <ImgStyled src={thumb.url} alt='' />
                            </a>
                            <button
                              type='button'
                              className='btn-delete'
                              onClick={() => onDeleteImage(idx)}
                            >
                              ×
                            </button>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className='form-action'>
                  <div className='file-uploader'>
                    <input
                      ref={imageInput}
                      type='file'
                      accept='image/*'
                      multiple
                      onChange={imageChanged}
                    />
                    <button
                      type='button'
                      className={'btn-upload-file loading_color ' + (loading && 'loading')}
                      onClick={() => imageInput.current.click()}
                    >
                      Edit Image
                    </button>
                  </div>
                  <button
                    type='submit'
                    className='btn btn-green btn-fill'
                    onClick={e => editComment(e, comment?.id_comment)}
                    id='addBtn'
                  >
                    Edit Comments
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

        {!isEdit && (
          <>
            <div className='comment-item-header'>
              <div className='comment-item-header'>
                <div className='profile'>
                  <div className='profile-pic'>
                    {Number(designerId) === Number(comment?.user?.id_user) ? (
                      <img src={'/assets/img/designers/crown.png'} className='crown' />
                    ) : (
                      ''
                    )}
                    <ImgStyled
                      src={comment?.user?.thumbnail ? comment?.user?.thumbnail : DEFAULT_AVATAR}
                      alt=''
                    />
                  </div>
                  <div className='profile-name'>
                    {comment?.user?.name ? comment?.user?.name : 'noname'}
                  </div>
                  <div className='comment-date'>{comment.date_reg}</div>
                </div>
              </div>
              {/* 댓글 수정 가능한 상태의 UI */}
              {isEditableItemComment(comment, String(state.id)) && (
                <>
                  <div className='util-btns'>
                    <button
                      className='btn-edit'
                      onClick={() => {
                        setIsEdit(true);
                      }}
                    >
                      Edit
                    </button>
                    <button className='btn-delete' onClick={onDelete}>
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className='comment-item-body'>
              <div className='comment-content'>
                <p dangerouslySetInnerHTML={formatHrefLink(comment.comment)}></p>
              </div>
              <div className='attachments' data-image-gallery='true'>
                {imgs?.map((img, idx) => {
                  return (
                    <a
                      className='item-detail-comment'
                      target='_blank'
                      onClick={() => onClickImg(imgs, idx)}
                      data-tb='img'
                      key={img.url}
                      style={{
                        width: `5.875rem`,
                        height: `5.875rem`,
                        backgroundImage: `url(${img.url})`,
                        backgroundSize: `cover`,
                        backgroundPosition: `center`,
                      }}
                    >
                      {/* <img className='item-detail-comment-img' src={img.src} alt='' /> */}
                    </a>
                  );
                })}
              </div>
            </div>
            {state.id && showProduct !== 'secret' && (
              <div className='comment-item-footer'>
                <a className={`btn btn-xs`} data-action='1102-comment-reply' onClick={showReply}>
                  Reply
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CommentBody;

const Box = styled.div`
  margin-bottom: 30px;
  .to-model {
    display: flex;
    align-items: center;
  }

  .model-info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin-top: 5px;
    }
  }

  .item-img {
    width: 50px;
    height: 50px;
  }
`;

const ToBox = styled.span`
  background-color: ${props => props.bg};
  color: #fff;
  padding: 3px 10px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 14px;
`;
