/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react';
import ModalContext from '../../context/modal/modal';
import UserContext from '../../context/user/user';
import './Login.css';
import { login, resendVerifyEmail } from '../../action/request';
import Apple from '../sns/Apple';
import Twitter from '../sns/Twitter';
import Github from '../sns/Github';
import Google from '../sns/Google';
import Yahoo from '../sns/Yahoo';
import Facebook from '../sns/Facebook';
import { Ss, ls } from '../../constant';
import LangContext from '../../context/lang/lang';
import { loginSignupEn, loginSignupKo } from '../../context/lang/constant';
import { validateEmail } from '../../util/validate';
import { useLocation, useNavigate } from 'react-router';
import { gtagOnClickLogin } from '../../action/gTag';
import { ITEMS } from '../../route/constant';
import { useRecoilState } from 'recoil';
import ErrMessage from '../errmessage/ErrMessage';
import {
  preDesignerListAtom,
  designerListAtom,
  sortStateAtom,
  randomSortStateAtom,
} from '../../atom/atom';

const Login = ({ isLogIn, setIsLogIn }) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resend, setResend] = useState(false);
  const { state, action } = useContext(ModalContext);
  const { action: userAction } = useContext(UserContext);
  const { state: langState } = useContext(LangContext);
  const [, setPreDesignerList] = useRecoilState(preDesignerListAtom);
  const [, setRandomValue] = useRecoilState(randomSortStateAtom);
  const [, setDesignerList] = useRecoilState(designerListAtom);
  const [sortState, setSortState] = useRecoilState(sortStateAtom);
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;
  const isLandingPage = useLocation().pathname.includes('/landing');

  const navigate = useNavigate();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirm = async () => {
    setResend(true);
    const result = await resendVerifyEmail(email);
    if (result) {
      setResend(false);
      closeModal();
    }
  };

  const tryLogin = async () => {
    if (!email.trim().length) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_UNSET);
    // if (!validateEmail(email)) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_INVALID);
    if (!password.trim().length) return alert(loginSignupMessages.LOGIN_ERROR_PASSWORD_NEED);

    try {
      const {
        data: { data, message, errCode },
      } = await login({
        action: 'auth-login',
        params: { email: email.trim(), password: password.trim() },
      });

      // gtag 일반 로그인 추적 태그
      gtagOnClickLogin('Fab365');
      if (message) {
        if (errCode === '2') {
          openModal(true);
        } else {
          alert(message);
        }
      } else {
        setDesignerList(false);
        setPreDesignerList(false);

        setSortState({
          offset: 'page',
          size: 5,
          sortType: 'Random',
          sortDir: 'Default',
          randomSort: true,
          total: 0,
        });
        setRandomValue(true);

        const accessToekn = data.token.access_token;
        const refreshToken = data.token.refresh_token;
        localStorage.removeItem(ls.accessToken);
        localStorage.removeItem(ls.refreshToken);
        sessionStorage.removeItem(Ss.inviteToken);

        localStorage.setItem(ls.accessToken, accessToekn);
        localStorage.setItem(ls.refreshToken, refreshToken);

        userAction.setEmail(data.userInfo.email);
        userAction.setId(data.userInfo.id);
        userAction.setIsLogin(true);
        //recoil 로그인상태 저장

        userAction.setThumbnail(data.userInfo.thumbnail);
        action.setIsLogin(false);

        // 렌딩페이지에서 로그인 시 홈으로 이동
        if (isLandingPage) {
          navigate('/');
        }

        if (sessionStorage.getItem(Ss.itemNumber)) {
          navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
          sessionStorage.removeItem(Ss.itemNumber);
        }
      }
    } catch (e) {}
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      tryLogin();
    }
  };

  return (
    <>
      <div className={'modal' + (state.isLogin ? ' active' : '')} id='modalLogIn'>
        <div className='modal-inner'>
          <div className='modal-box'>
            {modalIsOpen ? (
              <div>
                <ErrMessage onRequestClose={closeModal} onConfirm={handleConfirm} resend={resend} />
              </div>
            ) : (
              ''
            )}

            <div className='modal-box-header'>
              <h1 className='modal-title'>Log in</h1>
            </div>
            <div className='modal-box-body'>
              <form action='' name='login_form' className='signup-form m-b-md'>
                <div className='form-control'>
                  <input
                    type='text'
                    placeholder='E-Mail'
                    className='form-input'
                    name='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                </div>

                <div className='form-control'>
                  <input
                    type='password'
                    placeholder='Password'
                    className='form-input'
                    name='password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <button
                  type='button'
                  className='btn btn-full btn-fill btn-green login'
                  onClick={tryLogin}
                >
                  Login
                </button>
              </form>
              <div className='clearfix'>
                <div className='pull-right'>
                  <p>
                    Forgot{' '}
                    <a
                      onClick={() => {
                        action.setIsLogin(false);
                        action.setIsForgotPassword(true);
                      }}
                      className='txt-green remodal-forgotPassword'
                    >
                      Password
                    </a>
                    ?
                  </p>
                </div>
              </div>
              <hr className='or' />
              <Google />
              <Facebook />
              <Yahoo />
              <Apple />
              <Twitter />
              <Github />
              <div className='signup-agree m-b-md'>
                <p>
                  New to FAB365?{' '}
                  <a
                    onClick={() => {
                      action.setIsLogin(false);
                      action.setIsSignup(true);
                    }}
                    className='txt-green remodal-signup'
                  >
                    Sign up now
                  </a>
                </p>
              </div>
            </div>
            <a
              onClick={() => action.setIsLogin(false)}
              data-hide-modal='true'
              className='btn-close'
            >
              Close
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
