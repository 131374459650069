import { useContext, useEffect, useState } from 'react';
import {
  getMainBanner,
  getMainSlide,
  getNewItems,
  getNotice,
  getPopularItems,
  getRandomReviews,
  getSideSlide,
  report,
} from '../../action/request';
import Layout from '../../component/layout/Layout';
import NewModelSlider from '../../component/slider/NewModelSlider';
import ReviewModelSlider from '../../component/slider/ReviewModelSlider';
import MainBanner from './component/MainBanner';
import MainPopularModel from './component/MainPopularModel';
import MainSlide from './component/MainSlide';
import MobileMainSlide from './component/MobileMainSlide';
import MobileSideSlide from './component/MobileSideSlide';
import Notice from './component/Notice';
import Partners from './component/Partners';
import SideSlide from './component/SideSlide';
import './Main.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Ss, ls } from '../../constant';
import ModalContext from '../../context/modal/modal';
import UserContext from '../../context/user/user';
import { ITEMS } from '../../route/constant';
import NoticePopup from '../../component/popup/NoticePopup';

function Main() {
  const [mainSlide, setMainSlide] = useState([]);
  const [sideSlide, setSideSlide] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [banner, setBanner] = useState([]);
  const [newModel, setNewModel] = useState([]);
  const [popularModel, setPopularModel] = useState([]);
  const [notice, setNotice] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  // const [showPopup, setShowPopup] = useState(true); // 공지팝업

  const { state, action } = useContext(ModalContext);
  const { state: userState } = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigate();

  const isLogin =
    localStorage.getItem(ls.accessToken) !== undefined &&
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== null;

  const resize = () => {
    setWidth(window.innerWidth);
  };

  // 랜딩페이지 통해서 왔을 경우 url에 있는 초대 토큰 세션스토리지 저장
  const getInviteToken = () => {
    const urlParams = new URLSearchParams(location.search);
    const inviteToken = urlParams.get('t');

    if (!inviteToken) return;

    if (inviteToken !== undefined) {
      sessionStorage.setItem(Ss.inviteToken, inviteToken);
    }
  };

  // 랜딩페이지 통해서 왔을 경우 url에 있는 아이템 넘버 세션스토리지 저장
  const getItemNumber = () => {
    const urlParams = new URLSearchParams(location.search);
    const itemNumber = urlParams.get('item');

    if (!itemNumber) return;

    if (itemNumber !== undefined) {
      sessionStorage.setItem(Ss.itemNumber, itemNumber);
    }
  };

  useEffect(() => {
    getInviteToken();
    getItemNumber();
  }, []);

  useEffect(() => {
    // 홈 Url에 초대토큰이 있고 로그아웃 상태일 경우 회원가입 모달창 표시
    if (sessionStorage.getItem(Ss.inviteToken) && !isLogin) {
      action.setIsSignup(true);
    }
  }, [sessionStorage.getItem(Ss.inviteToken), localStorage.getItem(ls.accessToken)]);

  useEffect(() => {
    // 렌딩페이지에서 아이템 다운로드 클릭 시 + 로그인 되어 있을 때 아이템 페이지로 바로 이동
    if (sessionStorage.getItem(Ss.itemNumber) && isLogin) {
      navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
      sessionStorage.removeItem(Ss.itemNumber);
    }
  }, [sessionStorage.getItem(Ss.itemNumber), localStorage.getItem(ls.accessToken)]);

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  useEffect(() => {
    if (state.isLoginForInvite && !isLogin) {
      action.setIsLogin(true);
      action.setIsLoginForInvite(false);
    }
  }, [state.isLoginForInvite]);

  const limitMock = {
    isLogin: false,
    isPaid: null,
    hasDownloadHistory: false,
    expireTime: null,
  };

  useEffect(() => {
    try {
      fetchDatas();
    } catch (e) {}
  }, []);

  const fetchDatas = async () => {
    try {
      const { data: main } = await getMainSlide();
      setMainSlide(main.data);

      const { data: banner } = await getMainBanner();
      setBanner(banner.data);

      const { data: side } = await getSideSlide();
      setSideSlide(side.data);

      const { data: resReviews } = await getRandomReviews();
      setReviews(resReviews.data);

      const { data: resNewModel } = await getNewItems();
      setNewModel(resNewModel.data);

      const { data: resPopularModel } = await getPopularItems();
      setPopularModel(resPopularModel.items);

      const { data: resNotice } = await getNotice();
      setNotice(resNotice.data);
    } catch (e) {
      // TODO: 주석 풀기
      // report(e);
    }
  };

  // 공지 팝업 1일동안 다시보지 않기
  // useEffect(() => {
  //   const lastPopupViewed = localStorage.getItem('lastPopupViewed');
  //   if (lastPopupViewed) {
  //     const lastViewedDate = new Date(lastPopupViewed);
  //     const currentDate = new Date();
  //     const differenceInDays = Math.floor((currentDate - lastViewedDate) / (1000 * 60 * 60 * 24));
  //     if (differenceInDays < 1) {
  //       setShowPopup(false);
  //     }
  //   }
  // }, []);

  return (
    <>
      <Layout>
        {/* 공지 팝업 */}
        {/* {showPopup && <NoticePopup setShowPopup={setShowPopup} />} */}
        <div id='main'>
          <div className='container container-jumbotron'>
            <MainSlide slide={mainSlide} notice={notice} />
            <SideSlide slide={sideSlide} />
            {width <= 640 && (
              <>
                <MobileMainSlide slide={mainSlide} notice={notice} />
                <MobileSideSlide slide={sideSlide} />
              </>
            )}
          </div>
          <MainBanner banner={banner} />
          <section className='home-featured-product'>
            <div className='container'>
              <NewModelSlider data={newModel} limit={limitMock} title='New' />
              <hr className='thin' />
              <MainPopularModel data={popularModel} limit={limitMock} />
            </div>
          </section>
          <hr className='thin'></hr>
          <section className='home-featured-post'>
            <div className='container'>
              <ReviewModelSlider data={reviews} />
            </div>
          </section>
          <Partners />
        </div>
      </Layout>
    </>
  );
}

export default Main;
