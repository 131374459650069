/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import { CHECKOUT_FREE } from '../../../route/constant';
import { createCheckoutFree } from '../../../action/request';
import { useNavigate } from 'react-router-dom';
import CartContext from '../../../context/cart.js/carts';

const RewardBuyButton = ({ isLogin, freeTicketCount, id, isLoading, qty, secretProduct }) => {
  const { action: modalAction } = useContext(ModalContext);
  const { action: cartAction } = useContext(CartContext);

  const navigate = useNavigate();

  const checkoutFree = async itemID => {
    try {
      const { data: res } = await createCheckoutFree(itemID);
      cartAction.checkoutFreeProduct(res.data);

      // console.log(res);
      navigate(CHECKOUT_FREE);
    } catch (error) {
      // console.log(error);
    }
  };

  // 이제부터는 free 만 해당함 (비로그인)
  if (!isLogin) {
    return (
      <>
        {secretProduct === 'secret' ? (
          <button
            onClick={() => checkoutFree(id)}
            className='btn btn-gray btn-fill btn-full btn-buy'
            disabled
          >
            {!isLoading && 'No longer for sale'}
          </button>
        ) : (
          <button
            className={'notranslate btn btn-green btn-fill btn-full btn-buy cart-buynow need-login'}
            onClick={() => modalAction.setIsLogin(true)}
          >
            Get Model Now
          </button>
        )}
      </>
    );
  }

  // TODO: 소지티켓이 필요티켓보다 적은 경우 (API 연결 후)
  if (freeTicketCount - qty < 0) {
    return (
      <>
        <button
          className='notranslate btn btn-fill btn-full'
          disabled
          style={{ backgroundColor: '#9e9e9e', color: '#9e9e9e !important' }}
        >
          Get Model Now
        </button>
      </>
    );
  }

  return (
    <>
      {/* 로그인 && 티켓 충분 상태 */}
      {secretProduct === 'secret' ? (
        <button
          onClick={() => checkoutFree(id)}
          className='btn btn-gray btn-fill btn-full btn-buy'
          disabled
        >
          {!isLoading && 'No longer for sale'}
        </button>
      ) : (
        <button
          onClick={() => checkoutFree(id)}
          className='btn btn-green btn-fill btn-full btn-buy'
        >
          {!isLoading && 'Get Model Now'}
        </button>
      )}
    </>
  );
};

export default RewardBuyButton;
