import React from 'react';
import QuillEditor from './QuillEditor';
import Layout from '../layout/Layout';

const Demo = () => {
  return (
    <Layout>
      <div style={{ width: '70%', margin: 50, paddingTop: 30, paddingBottom: 30 }}>
        <QuillEditor />
      </div>
    </Layout>
  );
};

export default Demo;
