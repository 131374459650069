import React, { useContext, useEffect, useState } from 'react';
import AdTypeA from '../../../component/adv/AdTypeA';

import AdTypeB from '../../../component/adv/AdTypeB';
import SectionA from '../../../component/section/SectionA';
import SectionC from '../../../component/section/SectionC';
import SectionB from '../../../component/section/SectionB';
import SectionBReview from '../../../component/section/SectionBReview';
import AdSponsor from '../../../component/adv/AdSponsor';
import SectionD from '../../../component/section/SectionD';
import Influencers from '../../../component/section/Influencers';
import homeInfoList from './HomeInfoData.json';

import UserContext from '../../../context/user/user';
import { useRecoilValue } from 'recoil';
import { isWelcomeModal } from '../../../atom/atom';
import WelcomeModal from '../../../component/modal/WelcomeModal';
import {
  getMainBannerAd1,
  getMainBannerAd2,
  getMainBannerAd3,
  getMainBannerAd4,
  getMainBannerAd5,
  getMainVideos,
  getSectionBanner1,
  getSectionBanner2,
} from '../../../action/request';

function HomeInfo({ isMobile, isLogin }) {
  const [mainBannerAd1, setMainBannerAd1] = useState();
  const [mainBannerAd2, setMainBannerAd2] = useState();
  const [mainBannerAd3, setMainBannerAd3] = useState();
  const [mainBannerAd4, setMainBannerAd4] = useState();
  const [mainBannerAd5, setMainBannerAd5] = useState();
  const [sectionBanner1, setSectionBanner1] = useState();
  const [sectionBanner2, setSectionBanner2] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [mainVideos, setMainVideos] = useState();
  const IsWelcomeModalState = useRecoilValue(isWelcomeModal);
  const NEWEXCLUSIVE = homeInfoList.NEWEXCLUSIVE;
  const NEWMODELS = homeInfoList.NEWMODELS;
  const FEATUREDMODELS = homeInfoList.FEATUREDMODELS;
  const DESIGNERS = homeInfoList.DESIGNERS;
  const AIRPLANE = homeInfoList.AIRPLANE;
  const SPACECRAFTS = homeInfoList.SPACECRAFTS;
  const MODELSETS = homeInfoList.MODELSETS;
  const CATEGORIES = homeInfoList.CATEGORIES;
  const REVIEWS = homeInfoList.REVIEWS;
  const COLLECTIONS = homeInfoList.COLLECTIONS;
  const HOWABOUT = homeInfoList.HOWABOUT;
  const SPONSOR = homeInfoList.SPONSOR;

  const { state: userState } = useContext(UserContext);

  useEffect(() => {
    async function fetchBannerAds() {
      //스크롤 위치를 정확하게 잡기위해 data 통신을 한번에 띄웁니다.
      try {
        const responses = await Promise.all([
          getMainBannerAd1(),
          getMainBannerAd2(),
          getMainBannerAd3(),
          getMainBannerAd4(),
          getMainBannerAd5(),
          getSectionBanner1(userState?.isLogin),
          getSectionBanner2(userState?.isLogin),
          getMainVideos(),
        ]);

        setMainBannerAd1(responses[0].data.data);
        setMainBannerAd2(responses[1].data.data);
        setMainBannerAd3(responses[2].data.data);
        setMainBannerAd4(responses[3].data.data);
        setMainBannerAd5(responses[4].data.data);
        setSectionBanner1(responses[5].data.data);
        setSectionBanner2(responses[6].data.data);

        const videoRandom = responses[7].data.data;
        setMainVideos(videoRandom.sort((a, b) => b.sort - a.sort));
        setTimeout(() => setIsLoading(false), 300);
      } catch (err) {
        console.error(err);
      }
    }
    fetchBannerAds();
  }, []);

  return (
    <>
      {IsWelcomeModalState && isLogin && <WelcomeModal />}

      <SectionA data={NEWEXCLUSIVE} isMobile={isMobile} />
      <AdTypeA data={mainBannerAd1} isMobile={isMobile} />
      <SectionA data={NEWMODELS} isMobile={isMobile} />
      <SectionC data={FEATUREDMODELS} isMobile={isMobile} />
      <AdTypeA data={mainBannerAd2} isMobile={isMobile} />
      <SectionD data={DESIGNERS} isMobile={isMobile} />
      <Influencers isMobile={isMobile} data={mainVideos} isSkeletonLoading={isLoading} />
      <SectionB data={sectionBanner1} locationData={AIRPLANE} isMobile={isMobile} />
      <AdTypeB data={mainBannerAd3} isMobile={isMobile} userState={userState} />
      <SectionB data={sectionBanner2} isMobile={isMobile} locationData={SPACECRAFTS} />
      <SectionA data={MODELSETS} isMobile={isMobile} />
      <AdTypeA data={mainBannerAd4} isMobile={isMobile} />
      <SectionD data={CATEGORIES} isMobile={isMobile} />
      <SectionBReview data={REVIEWS} isMobile={isMobile} />
      <AdTypeB data={mainBannerAd5} isMobile={isMobile} />
      <SectionD data={COLLECTIONS} isMobile={isMobile} />
      <SectionA data={HOWABOUT} isMobile={isMobile} />
      <AdSponsor data={SPONSOR} isMobile={isMobile} />
    </>
  );
}

export default HomeInfo;
