import React, { useContext, useState } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import { loginSignupEn, loginSignupKo } from '../../context/lang/constant';
import { resetPassword } from '../../action/request';
import { useLocation, useNavigate } from 'react-router-dom';
import { RETURN_CODE } from '../../action/constant';
import ModalContext from '../../context/modal/modal';

function ResetPassword() {
  const [isShow, setIsShow] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const { action: modalAction } = useContext(ModalContext);
  const { state: langState } = useContext(LangContext);
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;

  const navigate = useNavigate();
  const location = useLocation();
  const code = location.search.split('=')[1];

  const handleIsShow = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsShow(prev => !prev);
    navigate('/');
    modalAction.setIsLogin(true);
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPassword = e => {
    setPasswordConfirm(e.target.value);
  };

  const onResetPassword = async () => {
    if (!password.trim().length) return alert(loginSignupMessages.SIGNUP_ERROR_PASSWORD_NEED);
    if (password !== passwordConfirm)
      return alert(loginSignupMessages.LOGIN_ERROR_PASSWORD_INVALID);

    try {
      const { data } = await resetPassword(code, password, passwordConfirm);

      if (data.returnCode === RETURN_CODE.C00000) {
        navigate('/');
        modalAction.setIsLogin(true);
      }
      return alert(data.message);
    } catch (e) {
      return alert('request failed');
    }
  };

  return (
    <Layout>
      <div id='main'>
        <div className={`modal ${isShow && 'active'}`} id='modalResetPassword'>
          <div className='modal-inner'>
            <div className='modal-box'>
              <div className='modal-box-header'>
                <h1 className='modal-title'>Reset Password</h1>
              </div>
              <div className='modal-box-body'>
                <form action='' name='reset_password_form' className='signup-form m-b-md'>
                  <input type='hidden' name='code' value='<?php echo $code?>' />

                  <div className='form-control'>
                    <input
                      type='password'
                      placeholder='New Password'
                      className='form-input'
                      name='password'
                      value={password}
                      onChange={onChangePassword}
                      maxLength={36}
                    />
                  </div>

                  <div className='form-control'>
                    <input
                      type='password'
                      placeholder='Confirm New Password'
                      className='form-input'
                      name='passwordConfirm'
                      value={passwordConfirm}
                      onChange={onChangeConfirmPassword}
                      maxLength={36}
                    />
                  </div>

                  <button
                    type='button'
                    className='btn btn-full btn-fill btn-green'
                    onClick={onResetPassword}
                  >
                    Reset Password
                  </button>
                </form>
              </div>
              <a href='#' data-hide-modal='true' className='btn-close' onClick={handleIsShow}>
                Close
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ResetPassword;
