import { useContext } from "react";
import Layout from "../../component/layout/Layout";
import LangContext from "../../context/lang/lang";
import CopyrightEn from "./component/CopyrightEn";
import CopyrightKo from "./component/CopyrightKo";

const Copyright = () => {
  const { action } = useContext(LangContext);
  return (
    <Layout>
      <div id="main">{action.isKo() ? <CopyrightKo /> : <CopyrightEn />}</div>
    </Layout>
  );
};

export default Copyright;
