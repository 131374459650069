const FinalPrice = item => {
  // if (!item.isBuyable) return "No purchases";

  if (item.finalPrice <= 0) return 'REWARD';

  if (item.isDiscount)
    return (
      <>
        <strong>$&nbsp;{item.finalPrice}</strong> <s>$&nbsp;{item.originPrice}</s>
      </>
    );

  return <>$&nbsp;{item.finalPrice}</>;
};

const Price = (item, limit) => {
  // console.log('item', item.isBuyable);
  // console.log('limit', limit);
  if (item.finalPrice > 0) {
    return <span className='price'>{FinalPrice(item)}</span>;
  }

  if (!limit.isLogin) {
    return <span className='price'>{FinalPrice(item)}</span>;
  }

  // 로그인상태 > 무료티켓 있을 경우(자물쇠 열림 아이콘)
  if (limit.isLogin && limit.ticketCount) {
    return (
      <span className='price'>
        {FinalPrice(item)}
        <span className='icon icon-unlock-up id-13fa'></span>
      </span>
    );
  }

  // 로그인상태 > 무료티켓 없을 경우(자물쇠 잠김 아이콘)
  if (limit.isLogin && !limit.ticketCount) {
    return (
      <span className='price'>
        {FinalPrice(item)}
        <span className='icon icon-lock id-13fa'></span>
      </span>
    );
  }

  // 아래 부터는 무료회원 제약하기
  if (limit.expireTime) {
    return (
      <span className='price'>
        {FinalPrice(item)}
        <span className='icon icon-lock id-13fa'></span>{' '}
        <span className='free-limit-104f'>{limit.expireTime}</span>
      </span>
    );
  }

  if (!limit.hasDownloadHistory) {
    return <span className='price'>{FinalPrice(item)}</span>;
  }

  if (!limit.expireTime) {
    return (
      <span className='price'>
        {FinalPrice(item)}' <span className='icon icon-unlock id-13fa'></span>
      </span>
    );
  }
};

export function priceStr(item) {
  if (item.selling !== 'y') return 'No purchases';

  let price = item.isFree === 'y' ? 0 : item.price;
  if (price <= 0) return 'REWARD';

  if (item.isDiscount) {
    return `<strong>$ ${price}</strong> <s>$ ${item.originPrice}</s>`;
  }

  return `$ ${price}`;
}

export default Price;
