import { useContext } from "react";
import Layout from "../../component/layout/Layout";
import LangContext from "../../context/lang/lang";
import TermEn from "./component/TermEn";
import TermKo from "./component/TermKo";

const Term = () => {
  const { action } = useContext(LangContext);
  return (
    <>
      <Layout>
        <div id="main">{action.isKo() ? <TermKo /> : <TermEn />}</div>
      </Layout>
    </>
  );
};
export default Term;
