import React, { useContext, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeForm } from '../styles';
import {
  DEV2_HOSTNAME,
  DEV_HOSTNAME,
  HOSTNAME,
  LOCALHOST,
  createCheckoutPayment,
  createCompleteStripeInPoint,
  createStripeInPoint,
  getConfirm,
} from '../../../../action/request';
import { CREATING_PAYMENT } from './constant';
import { useNavigate } from 'react-router';
import { gtagException, gtagPurchase } from '../../../../action/gTag';
import { throttle } from 'lodash';
import UserContext from '../../../../context/user/user';
import { decrypt, encrypt, generateRandomString } from '../../../../util/crypto';

function Stripe({
  checkReadyForPay,
  chargePoint = null,
  onRefresh = () => {},
  setIsLoading = () => {},
  setCheckoutMessage = () => {},
  requestId = null,
  totalAmount = null,
  isLoading = false,
  checkoutMessage = '',
}) {
  const { state: userState } = useContext(UserContext);

  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    // 중복 클릭 방지 / 결제 진행중일 때
    if (isLoading || checkoutMessage === CREATING_PAYMENT) return;

    if (checkReadyForPay()) {
      throttleSubmit();
    } else return;
  };

  // 중복 클릭 방지 / 1분간
  const throttleSubmit = throttle(async () => {
    const data = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (data.error) {
      setErrorMessage(data.error.message);
      // gtag 구매 과정 에러 추적 태그
      gtagException(data.error.message);
      if (chargePoint) setIsLoading(false);
      else setCheckoutMessage('');
      return;
    } else {
      if (chargePoint) setIsLoading(true);
      else setCheckoutMessage(CREATING_PAYMENT);
      setErrorMessage('');

      if (chargePoint) {
        // my point 페이지
        const step1Data = await createOrder1InPoint();
        const step2Data = await createOrder2InPoint(step1Data.request_id, step1Data.user_id);

        // 스트라이프 실결제 skip 기능 시작
        // TODO: 현재는 개발기에서만 동작되게 되어있지만 상용에서도 동작되게 서버 제한을 풀어야 한다. 서버 api 호출 후 timeout과 에러 응답 처리를 해야 한다.
        // 개발기 테스트 결제용 (개발 도메인일 때 실행)

        const confirmId = await createDevelopProcess();

        // 스트라이프 실결제 skip 기능 끝

        // 실결제 성공 여부
        let isSuccess;
        if (confirmId !== userState.id) {
          // 개발기 결제 테스트용이 아니므로 실결제 한다.
          const data = await confirmOrderInPoint(step2Data.pgBag.client_secret);

          // 카드 확인 에러
          if (data.error) {
            // 실결제 실패
            isSuccess = false;
            setErrorMessage(data.error.message);
            // gtag 구매 과정 에러 추적 태그
            gtagException(data.error.message);
            if (chargePoint) setIsLoading(false);
            else setCheckoutMessage('');
            return;
          } else {
            // 실결제 성공
            isSuccess = true;
          }
        }

        // 암호화
        let token = encrypt(step1Data.request_id, step1Data.user_id);
        // 결제 실패했을 때 랜덤값을 암호화 한다.
        if (isSuccess === false) {
          token = encrypt(generateRandomString(step1Data.request_id.length), step1Data.user_id);
        }
        // console.log('isSuccess: ', isSuccess);
        // console.log('confirmId: ', confirmId);
        // console.log('userState.id: ', userState.id);
        // console.log('request_id: ', step1Data.request_id);
        // console.log('IV 키: ', process.env.REACT_APP_AES_IV);
        // console.log('암호화 된 token: ', token);
        // console.log('token 복호화: ', decrypt(token, step1Data.user_id));

        await completeOrderInPoint(token);
        setIsLoading(false);
        onRefresh();
      } else {
        // checkout 페이지
        const step1Data = await createOrder1InCheckout();

        // 스트라이프 실결제 skip 기능 시작
        // TODO: 현재는 개발기에서만 동작되게 되어있지만 상용에서도 동작되게 서버 제한을 풀어야 한다. 서버 api 호출 후 timeout과 에러 응답 처리를 해야 한다.
        // 개발기 테스트 결제용 (개발 도메인일 때 실행)

        const confirmId = await createDevelopProcess();

        // 스트라이프 실결제 skip 기능 끝

        if (confirmId !== userState.id) {
          // 개발기 결제 테스트용이 아니므로 실결제 한다.
          const data = await confirmOrderInCheckout(step1Data.pgBag.client_secret);

          // 카드 확인 에러
          if (data.error) {
            setErrorMessage(data.error.message);
            // gtag 구매 과정 에러 추적 태그
            gtagException(data.error.message);
            if (chargePoint) setIsLoading(false);
            else setCheckoutMessage('');
            return;
          } else {
          }
        }

        const step3Data = await completeOrderInCheckout(step1Data.pgData);
        // 구글 태그
        gtagPurchase({ value: totalAmount, orderno: step3Data.orderno });

        setCheckoutMessage('');
        navigate(`/checkout/${step3Data.orderno}`);
      }
    }
  }, 60000);

  // 결제 생성 > my point
  const createOrder1InPoint = async () => {
    try {
      const { data } = await createStripeInPoint('checkoutRequestPoint-createWithSave', {
        pg: 'stripe',
        amount: chargePoint,
      });
      return data.data;
    } catch (e) {
      setIsLoading(false);
      if (e.message) {
        // gtag 구매 과정 에러 추적 태그
        gtagException(e.message);
        return alert(e.message);
      }
    }
  };

  // 결제 생성2 > my point
  const createOrder2InPoint = async (requestId, userId) => {
    try {
      const { data } = await createStripeInPoint('checkoutRequestPoint-createWithPg', {
        pg: 'stripe',
        request_id: requestId,
        user_id: userId,
      });
      return data.data;
    } catch (e) {
      setIsLoading(false);
      if (e.message) {
        // gtag 구매 과정 에러 추적 태그
        gtagException(e.message);
        return alert(e.message);
      }
    }
  };

  // 결제 > my point
  const confirmOrderInPoint = async clientSecret => {
    try {
      const data = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      return data;
    } catch (e) {
      setIsLoading(false);
      if (e.message) {
        // gtag 구매 과정 에러 추적 태그
        gtagException(e.message);
        return alert(e.message);
      }
    }
  };

  // 결제 완료 > my point
  const completeOrderInPoint = async token => {
    const params = {
      token,
    };
    try {
      const { data } = await createCompleteStripeInPoint(params);
      return data;
    } catch (e) {
      setIsLoading(false);
      if (e.message) {
        // gtag 구매 과정 에러 추적 태그
        gtagException(e.message);
        return alert(e.message);
      }
    }
  };

  // 결제 생성 > checkout-step2
  const createOrder1InCheckout = async () => {
    try {
      const { data } = await createCheckoutPayment('checkoutRequest-create', {
        method: 'stripe',
        requestId,
      });
      return data.data;
    } catch (e) {
      setCheckoutMessage('');
      if (e.message) {
        // gtag 구매 과정 에러 추적 태그
        gtagException(e.message);
        return alert(e.message);
      }
    }
  };

  // 결제 > checkout-step2
  const confirmOrderInCheckout = async clientSecret => {
    try {
      const data = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
      return data;
    } catch (e) {
      setCheckoutMessage('');
      if (e.message) {
        // gtag 구매 과정 에러 추적 태그
        gtagException(e.message);
        return alert(e.message);
      }
    }
  };

  // 결제 완료 > checkout-step2
  const completeOrderInCheckout = async pgData => {
    try {
      const { data } = await createCheckoutPayment('checkoutRequest-process', {
        pgData,
        pgBag: null,
      });
      return data.data;
    } catch (e) {
      setCheckoutMessage('');
      if (e.message) {
        // gtag 구매 과정 에러 추적 태그
        gtagException(e.message);
        return alert(e.message);
      }
    }
  };

  // 개발 결제 테스트
  const createDevelopProcess = async () => {
    try {
      const { data } = await getConfirm();
      return data.result;
    } catch (e) {
      return e;
    }
  };

  return (
    <>
      <StripeForm onSubmit={handleSubmit}>
        <CardElement />
        <button type='submit' disabled={!stripe}>
          Pay With Card
        </button>
      </StripeForm>
      <p className='stripe-error'>{errorMessage}</p>
    </>
  );
}

export default Stripe;
