import React, { useState, useRef, useMemo, useContext, useEffect } from 'react';
import ReactQuill from 'react-quill';
import EditorToolbar, { formats } from './EditorToolbar';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

import Quill from 'quill';

import ImageResize from 'quill-image-resize';
import AutoLinks from 'quill-auto-links';
import { fontSize } from './EditorToolbar';
import { ReviewUploadItemImage } from '../../action/request';
import UserContext from '../../context/user/user';
import LinkModal from './LinkModal';

Quill.register('modules/ImageResize', ImageResize);
Quill.register('modules/autoLinks', AutoLinks);

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSize;
Quill.register(Size, true);

export const Editor = () => {
  const { state: userState } = useContext(UserContext);
  const [value, setValue] = useState('');
  const [linkModal, setLinkModal] = useState(false);

  const quillRef = useRef(null);
  const quill = quillRef?.current?.getEditor();
  const range = quill?.getSelection();

  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.addEventListener('change', async () => {
      const file = input.files?.[0];

      try {
        const { data: res } = await ReviewUploadItemImage({
          file: file,
          idUser: userState.id,
        });

        if (res.returnCode === 'C00008') {
          alert(res?.message);
        } else {
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();

          editor.insertEmbed(range?.index, 'image', res.img.url);
          editor.setSelection(range?.index + 1);
        }
      } catch (error) {
        console.info(error);
      }
    });
  };

  function checkShortUrl(url) {
    const videoShortId = url.split('shorts/');
    if (url.includes('shared')) {
      const splitShortId = videoShortId[1]?.split('?');
      return splitShortId[0];
    } else {
      return videoShortId[1];
    }
  }

  function checkYoutubeUrl(url) {
    let youtubeUrlId = '';

    if (url.includes('shorts')) {
      youtubeUrlId = checkShortUrl(url);
    } else if (url.includes('v=')) {
      const splitVideo = url.split('v=')[1];
      youtubeUrlId = splitVideo;
    } else {
      const splitVideoShared = url.split('be/');
      const splitVideoId = splitVideoShared[1]?.split('?');
      youtubeUrlId = splitVideoId[0];
    }

    return youtubeUrlId;
  }

  const insertVideo = () => {
    const quillEditor = quillRef.current.getEditor();
    const url = prompt('Enter YouTube URL');
    if (url) {
      let videoId = '';

      if (url.includes('youtu' || 'youtube')) {
        if (url.includes('&t')) {
          const settingUrl = url.split('&t');
          videoId = checkYoutubeUrl(settingUrl[0]);
        } else {
          videoId = checkYoutubeUrl(url);
        }
      }
      const range = quillEditor.getSelection();
      const embedUrl = url?.includes('youtu' || 'youtube')
        ? `https://www.youtube.com/embed/${videoId}`
        : url;
      quillEditor.insertEmbed(range.index, 'video', embedUrl);
    }
  };

  const linkHandler = () => {
    setLinkModal(true);
  };

  const saveLink = (text, url) => {
    const title = text ? text : url;
    if (!range) {
      quill.insertText(1, title, 'link', url);
    } else {
      quill.insertText(range.index, title, 'link', url);
    }
    setLinkModal(false);
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: '#toolbar',
        handlers: {
          video: insertVideo,
          // // image: imageHandler,
          link: linkHandler,
        },
      },
      ImageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize'],
        displaySize: true,
      },
      autoLinks: true,
    };
  }, []);

  useEffect(() => {
    if (!useState.isLogin) {
      setValue('');
    }
  }, [userState]);

  return (
    <>
      <EditorWrap>
        <EditorQuill className='text-editor'>
          <div className='quill'>
            <EditorToolbar />
            {linkModal ? <LinkModal saveLink={saveLink} quillRef={quillRef} /> : ''}

            <ReactQuill
              theme='snow'
              ref={quillRef}
              value={value}
              onChange={setValue}
              placeholder={'Type your message here…'}
              modules={modules}
              formats={formats}
            />
          </div>
        </EditorQuill>
      </EditorWrap>
    </>
  );
};

export default Editor;

const EditorWrap = styled.div`
  margin-top: 3rem;
`;

const EditorQuill = styled.div`
  width: 100%;

  .quill {
    margin-bottom: 30px;
  }

  .video-size {
    margin-bottom: 10px;
  }

  .input-box {
    margin-bottom: 10px;
    h3 {
      margin-bottom: 10px;
      line-height: 20px;
    }
    input {
      width: 100px;
      height: 30px;
      margin-right: 10px;
      padding: 5px 10px;
      box-sizing: border-box;
    }

    button {
      height: 30px;
    }
  }

  em {
    font-style: italic;
  }

  .ql-editor {
    min-height: 400px;
    max-height: 800px;
    overflow-y: scroll;
    padding: 10px;
  }

  .ql-container {
    resize: vertical;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-thumb {
      background: #aaa;
    }
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #aaa !important;
  }
  .ql-container.ql-snow {
    border-left: 1px solid #aaa !important;
    border-right: 1px solid #aaa !important;
    border-bottom: 1px solid #aaa !important;
    border-top: none;
  }

  .ql-video {
    width: 800px;
    height: 450px;
  }

  .add-file {
    margin-top: 80px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .file-list {
    display: flex;
    flex-direction: column;
    div {
      margin: 10px 0px;
    }
    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }
  }

  .ql-editor ol {
    padding-left: 1rem;

    li {
      padding-left: 1.5rem;
      ::before {
        width: 1em;
        text-align: justify;
      }
    }
  }
  .ql-editor ul {
    padding-left: 1rem;
    li {
      padding-left: 1.5rem;
      ::before {
        width: 1em;
        text-align: justify;
      }
    }
  }

  .ql-editor .ql-indent-1 {
    padding-left: 2.5rem !important;
  }
  .ql-editor .ql-indent-2 {
    padding-left: 3.5rem !important;
  }
  .ql-editor .ql-indent-3 {
    padding-left: 4.5rem !important;
  }
  .ql-editor .ql-indent-4 {
    padding-left: 5.5rem !important;
  }
  .ql-editor .ql-indent-5 {
    padding-left: 6.5rem !important;
  }
  .ql-editor .ql-indent-6 {
    padding-left: 7.5rem !important;
  }
  .ql-editor .ql-indent-7 {
    padding-left: 8rem !important;
  }
  .ql-editor .ql-indent-8 {
    padding-left: 9.5rem !important;
  }
  .ql-editor .ql-indent-9 {
    padding-left: 10.5rem !important;
  }

  ul {
    list-style-type: disc !important;
    line-height: 25px;
  }

  ol {
    list-style-type: decimal !important;
    line-height: 25px;
  }

  p {
    font-size: 16px;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  li {
    font-size: 16px;
  }

  img {
    max-width: 800px;
  }

  a {
    color: #06c;
    text-decoration: underline;
  }

  em {
    font-style: italic;
  }
`;
