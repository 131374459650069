import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
const DesignersNav = ({ selectedTab, onClickTab, userState, designerURL }) => {
  const isDesigner = userState.isDesigner && Number(userState.idStudio) === Number(designerURL);
  const data = [
    { title: 'Models', api: '', value: 0 },
    { title: 'Followers', api: '', value: 1 },
    { title: 'Comments', api: '', value: 2 },
    { title: 'Reviews', api: '', value: 3 },
  ];

  const options = {
    slidesPerView: 'auto',
    centeredSlides: false,
    spaceBetween: 0,
    loop: false,
  };

  return (
    <Nav {...options}>
      {data.map((nav, key) => {
        return (
          <Tab
            key={key}
            onClick={() => {
              onClickTab(nav);
            }}
            border={selectedTab === nav.value ? 3 : 0}
          >
            <TabTitle color={selectedTab === nav.value ? '#000' : '#9A9A9A'}>{nav.title}</TabTitle>
          </Tab>
        );
      })}
      {isDesigner ? (
        <MyTab
          onClick={() => {
            onClickTab('my');
          }}
          border={selectedTab === 'my' ? 3 : 0}
          className='my'
        >
          <TabTitle color={selectedTab === 'my' ? '#000' : '#9A9A9A'}>My Sales</TabTitle>
        </MyTab>
      ) : (
        ''
      )}
    </Nav>
  );
};

export default DesignersNav;

const Nav = styled(Swiper)`
  display: flex;
  margin-top: 50px;
  border-bottom: 1px solid #cdcfd3;
  margin-bottom: 20px;

  @media only screen and (max-width: 650px) {
    .my {
      position: relative;
      right: 0px;
      width: 120px;
      height: 40px;
    }
  }
`;

const Tab = styled(SwiperSlide)`
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  border-bottom: ${props => props.border}px solid #000 !important;
  padding: 0px 10px;

  &:hover {
    cursor: pointer;
  }
`;

const MyTab = styled(Tab)`
  position: absolute;
  right: 0px;
`;

const TabTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.color};
`;
