export const NO_EXPIRE = 2500000000;
export const TYPE_BUY = '2';
export const TYPE_USE_CHECKOUT = '1001';
export const NOT_AGREED_MESSAGE = 'You should check License Agreement';
export const NOT_CHOSEN_POINT = 'You should choose points';
export const CREATING_PAYMENT = 'Creating payment..';
export const PROCESSING_PAYMENT = 'Processing payment..';

export const SANDBOX_CLIENT_ID =
  'AQ-EDKftAh0jXiix4DgVXuhf68hFnI0C8mAOiUFXPuLVSUNhpcxoIC9Smf-XRasr5cqVrP7spp4KteNd';
export const PRODUCTION_CLIENT_ID =
  'AU1ctz2g8PSaFH4-UEh5CpeAdT-FpsXTN3jJwAAKUbCW7OCNfK5PM3T5bbExWYXVX80DDoQHbd4uU9xU';
export const PAYPAL_CLIENT_ID = PRODUCTION_CLIENT_ID;
// console.log('*** paypal id : ', PAYPAL_CLIENT_ID);

// stripe
export const STRIPE_PUBLISHABLE_API_KEY =
  'pk_live_51IzHEDAk6QgDHuhgZNBsVf7AItdvf4HmkMPG1YFJDrfvzRPJxJB6j3znAnQjH5uy0fE3zIeIlJy4P8daNfvBGL3x00EM0vXwpe';
