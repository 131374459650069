import { useNavigate } from 'react-router-dom';
import { FOOTER_PRIVACY, FOOTER_TERMS_POINT } from '../../../route/constant';

const TermKo = () => {
  const navigate = useNavigate();

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  return (
    <>
      <div className='container'>
        <div className='terms'>
          <div className='terms-header'>
            <h1 className='terms-title'>서비스 이용약관 (일반회원)</h1>
          </div>
          <div className='terms-body'>
            <h2>제1장. 총칙</h2>

            <div className='term-block'>
              <h3>제1조. 목적</h3>
              <p>
                본 서비스 약관은 팹삼육오(Fab365, Inc., 이하 ‘회사’)에서 제공하는 3D 프린팅용
                e-Product 마켓 플레이스인 Fab365에서 제공하는 콘텐츠 등 구매 서비스(이하 ‘서비스’
                또는 ‘구매 서비스’)의 이용에 관련하여 구매자와 회사 간의 권리, 의무, 책임 및 기타
                필요한 사항을 규정함을 목적으로 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제2조. 약관의 명시, 효력과 개정</h3>
              <p>
                (1) 회사는 이 약관의 내용을 회사의 상호, 영업소 소재지, 대표자의 성명,
                사업자등록번호, 연락처(전화, 팩스, 전자우편주소 등)등과 함께 회원이 확인할 수 있도록
                서비스 초기 화면 또는 연결화면에 게시합니다.
              </p>
              <p>
                (2) 회사는 「약관의 규제에 관한 법률」, 「전자 문서 및 전자 거래 기본법」, 「전자
                서명법」, 「정보 통신망 이용 촉진 및 정보 보호 등에 관한 법률」, 「전자상거래
                등에서의 소비자보호에 관한 법률」, 「전자 금융 거래법」 등 관련법을 위배하지 않는
                범위에서 본 약관을 개정할 수 있습니다.
              </p>

              <p>
                (3) 회사가 이 약관을 개정하는 경우에는 개정된 약관의 적용일자 및 개정사유를 명시하여
                현행 약관과 함께 그 적용일자 7일 이전부터 적용일자 전일까지 위 제1항의 방법으로
                공지합니다. 다만, 회원에게 불리한 약관의 개정은 제1항 외에 서비스 내 전자우편,
                로그인 시 동의 요구 팝업창 등의 전자적 수단을 통하여 따로 명확히 통지하도록 합니다.
              </p>

              <p>
                (4) 회사가 전항에 따라 개정 약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사
                표시를 하지 않으면 의사 표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는
                통지하였음에도 회원이 명시적으로 거부 의사 표시를 하지 아니한 경우 회원이 개정
                약관에 동의한 것으로 봅니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제3조. 용어의 정의</h3>
              <p>(1) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
              <p>
                1. 회원(‘구매자’, 또는 ‘일반회원’): 회사에 개인정보를 제공하여 회원등록을 한 개인
                또는 법인으로서, 회사에서 제공하는 콘텐츠 등 구매 서비스를 이용할 수 있는 14세
                이상의 개인이나 법인을 의미합니다. <br />
                2. 아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여
                등록된 문자와 숫자의 조합을 말합니다. <br />
                3. 비밀번호: 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가
                설정하여 회사에 등록한 영문과 숫자의 조합을 말합니다. <br />
                4. 운영자: 회사가 제공하는 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서
                선정한 자를 말합니다. <br />
                5. 콘텐츠: 회사가 제공하는 서비스를 통하여 판매자와 구매자 사이에, 또는 회사와
                구매자 사이에 거래되는 3D 프린팅 모델 데이터 및 그와 관련된 콘텐츠
              </p>
              <br />
              <p>
                (2) 제(1)항 또는 용어가 사용되는 조항에서 별도로 정의되지 않은 이 약관상 용어의
                의미는 일반적인 거래 관행에 의합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제4조. 서비스의 종류</h3>
              <p>
                (1) 회사가 제공하는 서비스는 콘텐츠 또는 관련 재화 등(이하 ‘콘텐츠 등’)을 거래할 수
                있도록 사이버몰의 이용을 허락하거나, 통신판매를 알선하는 것을 목적으로 하는
                것으로서, 회사는 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 개별
                판매자가 서비스에 판매등록한 콘텐츠 등과 관련해서는 일체의 책임을 지지 않습니다.
                또한 회사는 통신판매중개자라는 점을 콘텐츠 등의 거래 화면 등을 통하여 구매자에게
                명확히 표시합니다.
              </p>
              <p>
                (2) 다만, 회사는 서비스를 통하여 직접 콘텐츠 등을 판매할 수 있습니다. 이 경우
                판매되는 콘텐츠 등의 소개화면 또는 주문화면에서 회사가 판매하는 콘텐츠 등이라는 점을
                표시하며, 회사는 해당 거래에 대해서 통신판매업자로서 책임을 질 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제5조. 대리행위의 부인</h3>
              <p>
                회사는 회사가 직접 판매하지 않는 콘텐츠 등에 대해서는, 통신판매중개자로서 효율적인
                서비스를 위한 시스템 운영 및 관리 책임만을 부담하며, 콘텐츠 등의 거래와 관련하여
                구매자 또는 판매자를 대리하지 아니하고, 회원 사이에 성립된 거래 및 회원이 제공하고
                등록한 정보에 대해서는 해당 회원이 그에 대한 직접적인 책임을 부담하여야 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제6조. 보증의 부인</h3>
              <p>
                회사는 회사가 제공하는 시스템을 통하여 이루어지는 구매자와 판매자 간의 거래와
                관련하여 판매의사 또는 구매의사의 존부 및 진정성, 판매자가 판매하는 콘텐츠 등의
                품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 구매자 또는 판매자가
                입력하는 정보의 진실성 또는 적법성 등 일체에 대하여 보증하지 아니하며, 이와 관련한
                일체의 위험과 책임은 해당 회원이 전적으로 부담합니다.
              </p>
            </div>
            <h2>제2장. 이용계약 및 정보보호</h2>
            <div className='term-block'>
              <h3>제7조. 서비스 이용계약의 성립</h3>
              <p>
                (1) 서비스 이용계약(이하 ‘이용계약’)은 회사가 제공하는 서비스(콘텐츠 구매 중개 등)를
                이용하고자 하는 자의 이용신청에 대하여 회사가 이를 승낙함으로써 성립합니다. 회사는
                이용승낙의 의사표시를 해당 서비스화면에 게시하거나 전자우편 또는 기타 방법으로
                통지합니다.
              </p>
              <p>
                (2) 서비스를 이용하고자 하는 자는 본 약관에 동의하고, 회사가 정하는 회원 가입 신청
                양식에 따라 필요한 사항을 기입합니다.
              </p>
              <p>
                (3) 회원가입은 만 14세 이상의 개인 또는 사업자(개인사업자 및 법인사업자)가 할 수
                있으며, 이용신청자가 타인의 정보를 도용하여 이용신청을 하는 경우 서비스이용이
                제한되거나 관련 법령에 의거 처벌받을 수 있습니다.
              </p>
              <p>
                (4) 이용신청의 처리는 신청순서에 의하며, 회원가입의 성립시기는 회사의 승낙이
                회원에게 도달한 시점으로 합니다.
              </p>
              <p>
                (5) 회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할
                수 있습니다.
              </p>
              <p>
                1. 타인의 정보를 도용하여 이용신청을 하는 경우
                <br />
                2. 이용계약이 해지된 날로부터 2개월 이내에 재이용신청을 하는 경우
                <br />
                3. 회사로부터 회원자격 정지 조치, 서비스 제공 중단 등을 받은 회원이 그 조치기간 중에
                이용계약을 임의 해지하고 재이용신청을 하는 경우
                <br />
                4. 설비에 여유가 없거나 기술상 지장이 있는 경우
                <br />
                5. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가
                합리적인 판단에 의하여 필요하다고 인정하는 경우
              </p>
              <h3>제8조. 이용신청 및 개인정보</h3>
              <p>
                (1) 회원은 이용신청 시 허위의 정보를 제공하여서는 아니 되며, 기재한 사항이
                변경되었을 경우에는 즉시 변경사항을 최신의 정보로 수정하여야 합니다.
              </p>
              <p>
                (2) 회사의 회원에 대한 일체의 통지는 회원이 제공한 전자메일에 도달함으로써 통지된
                것으로 보며, 수정하지 않은 정보로 인하여 발생하는 손해는 당해 회원이 전적으로
                부담하며, 회사는 이에 대하여 아무런 책임을 지지 않습니다.
              </p>
              <p>
                (3) 회원의 개인정보의 수집, 이용, 처리, 제3자 제공 등에 대해서는 회사가 서비스 초기
                화면 또는 연결화면에 게시하는{' '}
                <a href='' onClick={e => goTo(e, FOOTER_PRIVACY)} style={{ color: '#835fec' }}>
                  [개인정보처리방침]
                </a>
                에 의합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제9조. 아이디 및 비밀번호의 관리</h3>
              <p>
                (1) 아이디(ID) 및 비밀번호에 대한 관리책임은 회원에게 있으며, 회원은 어떠한 경우에도
                본인의 아이디(ID) 또는 비밀번호를 타인에게 양도하거나 대여할 수 없습니다.
              </p>
              <p>
                (2) 회사의 귀책사유 없이 아이디(ID) 또는 비밀번호의 유출, 양도, 대여로 인하여
                발생하는 손실이나 손해에 대하여는 회원 및 이용자 본인이 그에 대한 책임을 부담합니다.
              </p>
              <p>
                (3) 회원은 아이디(ID) 또는 비밀번호를 도난당하거나 제3자가 무단으로 이를 사용하고
                있음을 인지한 경우, 이를 즉시 회사에 통보하여야 하고 회사는 이에 대한 신속한 처리를
                위하여 최선의 노력을 다하여야 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제10조. 이용계약의 종료</h3>
              <p>
                <strong>회원의 해지</strong>
              </p>
              <p>
                (1) 회원은 언제든지 해당 서비스화면을 통하여 회사에게 해지의사를 통지함으로써
                이용계약을 해지할 수 있습니다. 다만, 회원은 해지의사를 통지하기 최소한 7일 전에 모든
                거래를 완결시키는데 필요한 조치를 취하여야만 합니다.
              </p>
              <p>
                (2) 전항의 기간 내에 회원이 발한 의사표시로 인해 발생한 불이익에 대한 책임은 회원
                본인이 부담하여야 하며, 이용계약이 종료되면 회사는 회원에게 무상으로 제공한 각종
                혜택을 회수할 수 있습니다.
              </p>
              <br />
              <p>
                <strong>회사의 해지</strong>
              </p>
              <p>
                (3) 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다
              </p>
              <p>
                1. 다른 회원 또는 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국
                법령 또는 공서양속에 위배되는 행위를 한 경우 <br />
                2. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우 <br />
                3. 제7조 제(5)항의 승낙거부사유가 있음이 확인된 경우 <br />
                4. 본 약관에 규정된 회원의 의무, 진술 및 보증사항, 선행조건 등을 위반한 경우 <br />
                5. 기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할
                경우
              </p>
              <p>
                (4) 회사가 해지를 하는 경우 회사는 회원에게 전자우편, 전화, 기타의 방법을 통하여
                해지사유를 밝혀 해지의사를 통지합니다. 이용계약은 회사의 해지의사를 회원에게 통지한
                시점에 종료됩니다.
              </p>
              <p>
                (5) 본 조에 의하여 회사가 이용계약을 해지하더라도, 해지 이전에 이미 체결된
                매매계약의 완결과 관련해서는 이 약관이 계속 적용됩니다.
              </p>
              <p>
                (6) 본 조에서 정한 바에 따라 이용계약이 종료될 시에는 회사는 회원에게 무상으로
                제공한 각종 혜택을 회수할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제11조. 서비스 기간과 중단</h3>
              <p>
                (1) 본 약관에 따른 서비스 기간은 이용신청에 대한 회사의 승낙이 회원에게 도달한
                시점부터 이용계약의 해지 시까지 입니다.
              </p>
              <p>
                (2) 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의
                사유가 발생한 경우에는 서비스의 제공을 점검 및 복구를 위하여 일시적으로 중단할 수
                있습니다.
              </p>
              <p>
                (3) 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
                경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.
              </p>
              <p>
                (4) 회사는 무료로 제공되는 서비스의 전부 또는 일부를 회사의 정책 및 운영의 필요에
                따라 수정, 중단, 변경할 수 있으며, 관계법령에 특별한 규정이 없는 한 회원에게 별도로
                보상하지 않습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제12조. 정보의 제공 및 광고의 게재</h3>
              <p>
                (1) 회사는 서비스 관련 각종 정보 및 광고를 서비스 화면에 게재하거나 전자우편 등의
                방법으로 회원에게 제공할 수 있습니다.
              </p>
              <p>
                (2) 회원이 서비스 상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의
                판촉활동에 참여하는 등의 방법으로 광고주와 거래를 할 수 있습니다. 이러한 거래
                과정에서 회원과 광고주 간의 분쟁, 기타 문제가 발생하는 경우, 회원과 광고주가 직접
                해결하여야 하며, 이와 관련하여 회사는 책임을 지지 않습니다.
              </p>
            </div>

            <h2>제3장. 서비스의 이용</h2>
            <div className='term-block'>
              <h3>제13조. 회원의 서비스 이용</h3>
              <p>
                회사는 회원에게 본 서비스에 포함된 모든 종류의 디지털 콘텐츠에 대하여, 개인적,
                비상업적 용도로 서비스를 이용할 수 있는, 양도불가능하며, 비독점적이고, 전세계적인
                라이선스를 부여합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제14조. 서비스 이용료</h3>
              <p>
                회사는 회원 간의 자유로운 전자상거래에 있어서 필요로 하는 각종 서비스를 제공하고
                회사의 내부 정책에 따라 그에 대한 이용료(서비스 이용료)를 부과할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제15조. 매매계약의 체결 및 대금 결제</h3>
              <p>
                (1) 콘텐츠 등의 매매계약은 구매자가 판매자가 제시한 콘텐츠 등의 판매 조건에 응하여
                청약의 의사표시를 하고 이에 대하여 판매자가 승낙의 의사표시를 함으로써 체결됩니다.
              </p>
              <p>
                (2) 회사는 구매자가 신용카드 결제 또는 그외 회사가 정하는 방법으로 매매 대금을
                결제할 수 있는 방법을 제공합니다.
              </p>
              <p>
                (3) 매매 대금의 결제와 관련하여 구매자가 입력한 정보 및 그 정보와 관련하여 발생한
                책임과 불이익은 전적으로 구매자가 부담하여야 합니다.
              </p>
              <p>
                (4) 콘텐츠 등을 주문한 후 일정 기간 내에 매매대금을 결제하지 않을 경우 회사는 당해
                주문을 구매자의 동의 없이 취소할 수 있습니다.
              </p>
              <p>
                (5) 회사는 구매자의 매매계약 체결 내용을 나의 쇼핑 내역 등 서비스 내 메뉴를 통해
                확인할 수 있도록 조치하며, 매매계약의 취소 방법 및 절차를 안내합니다.
              </p>
              <p>
                (6) 회사는 구매자가 매매대금 결제 시 사용한 결제수단에 대해 정당한 사용권한을 가지고
                있는지의 여부를 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래진행을
                중지하거나, 확인이 불가한 해당거래를 취소할 수 있습니다.
              </p>
              <p>
                (7) 구매자가 실제로 결제하는 금액은 판매자가 정한 공급원가, 기본이용료, 적용된 할인,
                배송비, 옵션상품의 옵션내역 등이 적용된 금액(실구매액)이며 구매자에게 발행되는
                구매증빙서(현금영수증, 세금계산서, 신용카드매출전표 등)는 실구매액으로 발행됩니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제16조. 다운로드</h3>
              <p>
                구매자가 콘텐츠 다운로드의 형태로 전달받을 수 있는 콘텐츠 등을 구매하는 경우,
                구매자는 매매대금 결제 후 구매한 콘텐츠를 다운로드받을 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제17조. 청약철회</h3>
              <p>
                (1) 청약철회(환불)와 관련하여, 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」
                및 「콘텐츠 산업 진흥법」의 관련 규정 및 본 조의 사항을 준수합니다.
              </p>
              <p>
                (2) 회사가 직접 「전자상거래 등에서의 소비자보호에 관한 법률」상 통신판매업자로서
                콘텐츠 등을 판매하지 않는 경우, 회사는 그러한 사실을 상품 설명 화면 등에 명시적으로
                표시합니다. 또한 콘텐츠 등을 판매하는 판매자의 성명, 주소, 전화번호 등 관련 법령에서
                정하는 사항을 청약이 이루어지기 전 까지 구매자에게 제공합니다.
              </p>
              <p>
                (3) 구매자는 판매자가 「전자상거래 등에서의 소비자보호에 관한 법률」상
                통신판매업자인 경우, 본 조 제(4)항 이하의 규정에 따라 청약철회(환불)할 수 있습니다.
              </p>
              <p>
                (4) 구매자는 콘텐츠 등의 구매일 또는 이용가능일로부터 7일 이내에는 청약의 철회를 할
                수 있습니다.
              </p>
              <p>
                (5) 전항의 내용에도 불구하고, 구매자는 다음 각 호에의 어느 하나에 해당하는 경우에는
                판매자의 의사에 반하여 전항에 따른 청약철회등을 할 수 없습니다.
              </p>
              <p>
                1. 소비자에게 책임이 있는 사유로 재화등이 멸실되거나 훼손된 경우. 다만, 재화등의
                내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외합니다.
                <br />
                2. 소비자의 사용 또는 일부 소비로 재화등의 가치가 현저히 감소한 경우
                <br />
                3. 시간이 지나 다시 판매하기 곤란할 정도로 재화등의 가치가 현저히 감소한 경우
                <br />
                4. 복제가 가능한 재화등의 포장을 훼손한 경우
                <br />
                5. 용역 또는 「문화산업진흥 기본법」 제2조 제5호의 디지털콘텐츠의 제공이 개시(본
                서비스에서는 콘텐츠 등의 다운로드가 완료된 것을 제공의 개시로 봅니다)된 경우. 다만,
                가분적 용역 또는 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한
                부분에 대하여는 그러하지 아니합니다.
                <br />
                6. 그 밖에 거래의 안전을 위하여 대한민국 대통령령으로 정하는 경우
              </p>
              <p>
                (6) 판매자는 전항 제2호부터 제5호까지의 규정에 따라 청약철회등이 불가능한 재화등의
                경우에는 그 사실을 재화등의 포장이나 그 밖에 소비자가 쉽게 알 수 있는 곳에 명확하게
                표시하거나 시험 사용 상품을 제공하는 등의 방법으로 청약철회등의 권리 행사가 방해받지
                아니하도록 조치하여야 합니다. 다만, 전항 제5호 중 디지털콘텐츠에 대하여 소비자가
                청약철회등을 할 수 없는 경우에는 청약철회등이 불가능하다는 사실의 표시와 함께
                대한민국 대통령령으로 정하는 바에 따라, 다음 각 호의 구분에 따른 방법 중 하나 이상의
                방법으로 청약철회등의 권리 행사가 방해받지 아니하도록 하여야 합니다.
              </p>
              <p>
                1. 일부 이용의 허용: 디지털콘텐츠의 일부를 미리보기, 미리듣기 등으로 제공 <br />
                2. 한시적 이용의 허용: 일정 사용기간을 설정하여 디지털콘텐츠 제공 <br />
                3. 체험용 디지털콘텐츠 제공: 일부 제한된 기능만을 사용할 수 있는 디지털콘텐츠 제공{' '}
                <br />
                4. 제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기 곤란한 경우:
                디지털콘텐츠에 관한 정보 제공
              </p>
              <p>
                (7) 본 조의 내용은 회사가 콘텐츠 등을 판매하는 경우, 회사를 판매자로 보아 회사에게도
                적용됩니다.
              </p>
              <p>
                (8) 청약철회에 따른 환불은 카드결제의 경우 취소절차가 모두 완료된 즉시 결제가 취소
                됩니다.
              </p>
            </div>
            <h2>제4장. 부가서비스의 이용</h2>
            <div className='term-block'>
              <h3>제18조. 크레딧 서비스</h3>
              <p>
                (1) 회사는 서비스 내에서 여러 방법을 통해 구매자에게 ‘크레딧’을 부여할 수 있으며,
                구매자는 이를 콘텐츠 등 구매시 대금의 결제수단으로 사용할 수 있습니다.
              </p>
              <p>
                (2) 크레딧의 종류, 충전, 청약철회, 환불 등에 대해서는 회사가 서비스 초기 화면 또는
                연결화면에 게시하는{' '}
                <a href='' onClick={e => goTo(e, FOOTER_TERMS_POINT)} style={{ color: '#835fec' }}>
                  [크레딧이용약관]
                </a>
                에 의합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제19조. 유료 상품권</h3>
              <p>
                (1) 회사는 서비스 내에서 판매되는 콘텐츠 등을 구매할 수 있는 유료 상품권을 판매할 수
                있습니다. 구매자는 유료 상품권을 구매하여 콘텐츠 등 구매시 대금의 결제수단으로
                사용할 수 있습니다.
              </p>
              <p>
                (2) 구매자가 구매한 유료 상품권의 환불은 제17조의 규정에 의합니다. 다만, 구매자에게
                환불되는 금액은 구매자의 전체 상품권 구매금액에서, 상품권의 권면금액 중 이미
                사용하지 않은 금액의 비율에 한합니다.
              </p>
              <p>
                (3) 유료 상품권이 서비스 내에서 사용할 수 있는 ‘크레딧’으로 전환되는 경우에도, 해당
                ‘크레딧’은{' '}
                <a href='' onClick={e => goTo(e, FOOTER_TERMS_POINT)} style={{ color: '#835fec' }}>
                  [크레딧이용약관]
                </a>{' '}
                제6조와 본 약관 제17조 및 전항의 규정에 따라 환불될 수 있습니다.
              </p>
            </div>
            <h2>제5장. 이용자 관리 및 보호</h2>
            <div className='term-block'>
              <h3>제20조. 이용자 관리</h3>
              <p>
                (1) 회사는 본 약관의 내용과 관련 법령을 위반한 회원에 대하여 다음과 같은 조치를 할
                수 있습니다.
              </p>
              <p>
                1. 회사가 부가적으로 제공한 혜택의 일부 또는 전부의 회수
                <br />
                2. 특정서비스 이용제한
                <br />
                3. 이용계약의 해지
                <br />
                4. 손해배상의 청구
              </p>

              <p>
                (2) 회사가 전항 각 호에 정한 조치를 할 경우 회사는 사전에 회원에게 유선 또는
                전자우편으로 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한
                경우 선조치 후 사후 통보할 수 있습니다.
              </p>
              <p>
                (3) 회원은 본 조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우 이에 대하여
                항변을 할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제21조. 회원의 게시물 및 저작권</h3>
              <p>
                (1) 회원이 회사가 제공하는 서비스를 이용하면서 서비스에서 판매되는 콘텐츠 등에 대한
                평가 및 의견, Q&A 등 콘텐츠 등 이외의 각종 게시물 또는 저작물(이하 ‘게시물’)의
                저작권은 이를 작성한 회원 본인에게 있으며, 해당 게시물이 타인의 저작권을 침해하는
                경우 그에 대한 책임은 회원 본인이 부담합니다.
              </p>
              <p>
                (2) 회사는 게시물이 다음 각 호에 해당하는 경우 사전 통보 없이 해당 게시물을
                삭제하거나 게시자에 대하여 특정서비스의 이용제한을 할 수 있으며, 다음 각 호에
                해당하는 게시물을 2회 이상 게시하는 경우 이용계약을 해지 할 수 있습니다. 단,
                게시물이 다음 각 호 중 하나에 해당하지 않는 한, 회사는 게시물에 구매한 콘텐츠 등에
                대한 단순불만 등 회사나 판매자에게 불리한 내용이 포함되었다는 이유만으로 게시물을
                삭제하지 않습니다.
              </p>
              <p>
                1. 대한민국의 법령을 위반하는 내용을 포함하는 경우
                <br />
                2. 관계법령에 의거 판매가 금지된 콘텐츠 등 또는 음란물을 게시, 광고하는 경우
                <br />
                3. 허위 또는 과대광고의 내용을 포함하는 경우
                <br />
                4. 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 경우
                <br />
                5. 직거래 유도 또는 타 사이트의 링크를 게시하는 경우
                <br />
                6. 정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함하는 경우
                <br />
                7. 사회 공공질서나 미풍양속에 위배되는 경우
                <br />
                8. 회사가 제공하는 서비스의 원활한 진행을 방해하는 것으로 판단되는 경우
                <br />
                9. 범죄행위와 관련된 내용을 포함하는 경우
                <br />
                10. 정치, 경제적 분쟁을 야기하는 내용을 포함하는 경우
              </p>
              <p>
                (3) 회사는 회원이 작성한 게시물을 서비스에서 거래되는 콘텐츠 등을 판촉, 홍보하기
                위한 목적으로, 복제, 배포, 전송, 전시할 수 있으며, 본질적인 내용에 변경을 가하지
                않는 범위 내에서 수정, 편집할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제22조. 금지행위</h3>
              <p>
                <strong>직거래</strong>
              </p>
              <p>
                (1) 회사가 제공하는 서비스를 이용하지 않고 판매자와 구매자간에 직접적으로 거래하는
                직거래를 통하여 발생한 제반 문제에 대한 책임은 거래 당사자에게 있으며 회사는 이로
                인하여 발생된 문제에 대하여 어떠한 책임도 지지 않습니다.
              </p>
              <p>
                (2) 직거래를 하거나 이를 유도한 것으로 확인된 판매자는 판매서비스 이용계약이 해지될
                수 있으며, 회원은 직거래를 하거나 유도한 판매자를 회사에 신고할 수 있습니다.
              </p>
              <br />
              <p>
                <strong>시스템 부정행위</strong>
              </p>
              <p>
                (3) 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를
                이용하거나 시스템에 접근하는 행위는 금지됩니다.
              </p>
              <p>
                (4) 회사는 시스템 부정행위가 확인된 경우 해당 회원에 대하여 회사가 부가적으로 제공한
                혜택의 일부 또는 전부를 회수 할 수 있습니다.
              </p>
              <br />
              <p>
                <strong>결제 부정행위</strong>
              </p>
              <p>
                (5) 타인의 명의나 카드정보 등을 도용하여 회사가 제공하는 구매서비스를 이용하는
                행위는 금지됩니다.
              </p>
              <p>
                (6) 물품판매 또는 용역제공을 가장하여 자금을 융통하는 것과 같이 여신전문금융업법 등
                법령에 의하여 금지된 방법으로 비정상적인 결제를 하는 것은 금지되며, 해당 내용이
                확인된 경우 회사는 이용계약을 해지하거나 회원의 거래를 중지시키고 관련기관에 통보할
                수 있습니다.
              </p>
              <p>
                (7) 실제 콘텐츠 등이 존재하지 않거나 형식적으로만 거래되는 등 콘텐츠 등의 실질적인
                구매의사가 없이 구매행위를 하는 것은 금지됩니다.
              </p>
              <br />
              <p>
                <strong>기타 금지행위</strong>
              </p>
              <p>
                (8) 회원은 회사가 제공하는 할인율 등을 이용하여 비정상적인 다수의 거래를
                체결시킴으로써 회사에 손실을 발생시키고 공정한 시장 환경 조성에 방해가 되는 행위
                등을 하여서는 아니 됩니다.
              </p>
              <br />
              <p>
                <strong>제재</strong>
              </p>
              <p>
                (9) 본 조의 행위를 한 것으로 확인된 구매자에 대해서는 회사가 해당 거래를 중지 또는
                취소시킬 수 있으며, 본 조의 행위를 2회 이상 한 것으로 확인될 경우 회사는 구매자와의
                이용계약을 해지하고, 그러한 행위로 인하여 발생한 손해가 있을 시 손해배상을 청구할 수
                있습니다.
              </p>
            </div>
            <h2>제6장. 기타</h2>
            <div className='term-block'>
              <h3>제23조. 관련 법령과의 관계</h3>
              <p>
                (1) 이 약관에 명시되지 않은 사항은 「전자상거래 등에서의 소비자보호에 관한 법률」 등
                관련 법령의 규정과 일반 상관습에 의합니다.
              </p>
              <p>
                (2) 서비스를 통하여 거래가 이루어지는 경우, 「전자상거래 등에서의 소비자보호에 관한
                법률」 등 관련 법령이 해당 거래 당사자에게 우선적으로 적용되고, 거래 당사자는 이
                약관의 규정을 들어 거래 상대방에 대하여 면책을 주장할 수 없습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제24조. 손해배상</h3>
              <p>
                (1) 회사 또는 회원이 본 약관상 의무와 진술 및 보증을 위반하는 경우, 상대방에 대하여
                그러한 의무 위반과 상당인과관계에 있는 손해를 배상해야 합니다.
              </p>
              <p>
                (2) 회원이 본 약관상 회원의 의무를 위반하거나 타인의 저작권 등 기타 권리를 침해하여
                회사가 제3자로부터 손해배상청구 등의 이의 제기를 받은 경우, 회원은 회사를 면책하여야
                하며 회사에 발생한 모든 손해를 배상해야 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제25조. 회사의 면책</h3>
              <p>
                (1) 제11조 제(2)항의 사유로 인하여 서비스를 일시적으로 중단하는 경우 회사는 이로
                인하여 회원 또는 제3자가 입은 특별손해에 대하여 책임지지 않습니다.
              </p>
              <p>
                (2) 제11조 제(3)항의 사유로 인하여 서비스를 제한하거나 중단하는 경우 회사는 그
                책임을 면합니다.
              </p>
              <p>
                (3) 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
              </p>
              <p>
                (4) 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서
                회사는 일체의 책임을 지지 않습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제26조. 준거법 및 관할법원</h3>
              <p>(1) 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.</p>
              <p>
                (2) 회사와 회원간 발생한 분쟁에 관한 소송은 제소 당시의 회원의 주소에 의하고, 주소가
                없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 회원의 주소
                또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.
              </p>
              <p>
                (3) 해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한 분쟁에 관한 소송은
                전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제27조. 기타조항</h3>
              <p>
                (1) 회사와 회원은 상대방의 사전 서면 동의 없이 이 약관상의 권리와 의무를 제3자에게
                양도 할 수 없습니다.
              </p>
              <p>
                (2) 이 약관과 관련하여 당사자 간의 합의에 의하여 추가로 작성된 계약서, 협정서,
                통보서 등과 회사의 정책변경, 법령의 제정·개정 또는 공공기관의 고시·지침 등에 의하여
                회사가 서비스를 통해 회원에게 공지하는 내용도 본 약관의 일부를 구성합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>부칙</h3>
              <p>1. 적용일자</p>
              <p>(1) 이 약관은 2024년 02월 23일부터 시행합니다.</p>
              <p>(2) 2016년 12월 26일부터 시행되던 종전의 약관은 본 약관으로 대체합니다.</p>
            </div>
          </div>

          <div className='terms-header'>
            <h1 className='terms-title'>서비스 이용약관 (디자이너회원)</h1>
          </div>

          <div className='terms-body'>
            <h2>제1장. 총칙</h2>
            <div className='term-block'>
              <h3>제1조. 목적</h3>
              <p>
                본 서비스 약관은 팹삼육오(Fab365, Inc., 이하 ‘회사’)에서 제공하는 3D 프린팅용
                e-Product 마켓 플레이스인 Fab365의 콘텐츠 등 판매 서비스(이하 ‘서비스’ 또는 ‘판매
                서비스’)의 이용에 관련하여 판매자와 회사 간의 권리, 의무, 책임 및 기타 필요한 사항을
                규정함을 목적으로 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제2조. 약관의 명시, 효력과 개정</h3>
              <p>
                (1) 회사는 이 약관의 내용을 회사의 상호, 영업소 소재지, 대표자의 성명,
                사업자등록번호, 연락처(전화, 팩스, 전자우편주소 등)등과 함께 회원이 확인할 수 있도록
                서비스 초기 화면 또는 연결화면에 게시합니다.
              </p>
              <p>
                (2) 회사는 「약관의 규제에 관한 법률」, 「전자 문서 및 전자 거래 기본법」, 「전자
                서명법」, 「정보 통신망 이용 촉진 및 정보 보호 등에 관한 법률」, 「전자상거래
                등에서의 소비자보호에 관한 법률」, 「전자 금융 거래법」 등 관련법을 위배하지 않는
                범위에서 본 약관을 개정할 수 있습니다.
              </p>
              <p>
                (3) 회사가 이 약관을 개정하는 경우에는 개정된 약관의 적용일자 및 개정사유를 명시하여
                현행 약관과 함께 그 적용일자 7일 이전부터 적용일자 전일까지 위 제1항의 방법으로
                공지합니다. 다만, 회원에게 불리한 약관의 개정은 제1항 외에 서비스 내 전자우편,
                로그인 시 동의 요구 팝업창 등의 전자적 수단을 통하여 따로 명확히 통지하도록 합니다.
              </p>
              <p>
                (4) 회사가 전항에 따라 개정 약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사
                표시를 하지 않으면 의사 표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는
                통지하였음에도 회원이 명시적으로 거부 의사 표시를 하지 아니한 경우 회원이 개정
                약관에 동의한 것으로 봅니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제3조. 용어의 정의</h3>
              <p>(1) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
              <p>
                1. 회원(‘판매자’, 또는 ‘디자이너회원’): 회사에 개인정보를 제공하여 회원등록을 한
                개인 또는 법인 중, 회사에서 제공하는 구매 서비스 및 판매 서비스를 이용할 수 있는
                14세 이상의 개인이나 법인을 의미합니다.
                <br />
                2. 아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여
                등록된 문자와 숫자의 조합을 말합니다.
                <br />
                3. 비밀번호: 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가
                설정하여 회사에 등록한 영문과 숫자의 조합을 말합니다.
                <br />
                4. 운영자: 회사가 제공하는 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서
                선정한 자를 말합니다.
                <br />
                5. 콘텐츠: 회사가 제공하는 서비스를 통하여 판매자와 구매자 사이에, 또는 회사와
                구매자 사이에 거래되는 3D 프린팅 모델 데이터 및 그와 관련된 콘텐츠
              </p>
              <p>
                (2) 제(1)항 또는 용어가 사용되는 조항에서 별도로 정의되지 않은 이 약관상의 용어의
                의미는 일반적인 거래관행에 의합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제4조. 서비스의 종류</h3>
              <p>
                (1) 회사가 제공하는 서비스는 콘텐츠 또는 관련 재화 등(이하 ‘콘텐츠 등’)을 거래할 수
                있도록 사이버몰의 이용을 허락하거나, 통신판매를 알선하는 것을 목적으로 하는
                것으로서, 회사는 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 개별
                판매자가 서비스에 판매등록한 콘텐츠 등과 관련해서는 일체의 책임을 지지 않습니다.
                또한 회사는 통신판매중개자라는 점을 콘텐츠 등의 거래 화면 등을 통하여 구매자에게
                명확히 표시합니다.
              </p>
              <p>
                (2) 다만, 회사는 서비스를 통하여 직접 콘텐츠 등을 판매할 수 있습니다. 이 경우
                판매되는 콘텐트 등의 소개화면 또는 주문화면에서 회사가 판매하는 콘텐츠 등이라는 점을
                표시하며, 회사는 해당 거래에 대해서 통신판매업자로서의 책임을 질 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제5조. 대리행위의 부인</h3>
              <p>
                회사는 회사가 직접 판매하지 않는 콘텐츠 등에 대해서는, 통신판매중개자로서 효율적인
                서비스를 위한 시스템 운영 및 관리 책임만을 부담하며, 콘텐츠 등의 거래와 관련하여
                구매자 또는 판매자를 대리하지 아니하고, 회원 사이에 성립된 거래 및 회원이 제공하고
                등록한 정보에 대해서는 해당 회원이 그에 대한 직접적인 책임을 부담하여야 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제6조. 보증의 부인</h3>
              <p>
                회사는 회사가 제공하는 시스템을 통하여 이루어지는 구매자와 판매자 간의 거래와
                관련하여 판매의사 또는 구매의사의 존부 및 진정성, 판매자가 판매하는 콘텐츠 등의
                품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, 구매자 또는 판매자가
                입력하는 정보의 진실성 또는 적법성 등 일체에 대하여 보증하지 아니하며, 이와 관련한
                일체의 위험과 책임은 해당 회원이 전적으로 부담합니다.
              </p>
            </div>
            <h2>제2장. 이용계약 및 정보보호</h2>
            <div className='term-block'>
              <h3>제7조. 서비스 이용계약의 성립</h3>
              <p>
                (1) 서비스 이용계약(이하 ‘이용계약’)은 회사가 제공하는 서비스(콘텐츠 판매 중개 등)를
                이용하고자 하는 자의 이용신청에 대하여 회사가 이를 승낙함으로써 성립합니다. 회사는
                이용승낙의 의사표시를 해당 서비스화면에 게시하거나 전자우편 또는 기타 방법으로
                통지합니다.
              </p>
              <p>
                (2) 서비스를 이용하고자 하는 자는 본 약관에 동의하고, 회사가 정하는 회원 가입 신청
                양식에 따라 필요한 사항을 기입합니다.
              </p>
              <p>
                (3) 회원가입은 만 14세 이상의 개인 또는 사업자(개인사업자 및 법인사업자)가 할 수
                있으며, 이용신청자가 타인의 정보를 도용하여 이용신청을 하는 경우 서비스이용이
                제한되거나 관련 법령에 의거 처벌받을 수 있습니다.
              </p>
              <p>
                (4) 이용신청의 처리는 신청순서에 의하며, 회원가입의 성립시기는 회사의 승낙이
                회원에게 도달한 시점으로 합니다.
              </p>
              <p>
                (5) 회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할
                수 있습니다.
              </p>
              <p>
                1. 타인의 정보를 도용하여 이용신청을 하는 경우
                <br />
                2. 이용계약이 해지된 날로부터 2개월 이내에 재이용신청을 하는 경우
                <br />
                3. 회사로부터 회원자격 정지, 서비스 제공 중단 조치 등을 받은 회원이 그 조치기간 중에
                이용계약을 임의 해지하고 재이용신청을 하는 경우
                <br />
                4. 설비에 여유가 없거나 기술상 지장이 있는 경우
                <br />
                5. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가
                합리적인 판단에 의하여 필요하다고 인정하는 경우
              </p>
              <p>
                (6) 회사는 판매자가 이용신청 시 제공한 정보가 정확한지 확인하기 위하여 관련법령이
                허용하는 범위 내에서 증빙자료의 제공을 요청할 수 있으며, 판매자가 합리적 이유없이
                이를 거부할 경우 회사는 이용신청에 대한 승낙을 거부하거나 유보할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제8조. 이용신청 및 개인정보</h3>
              <p>
                (1) 회원은 이용신청 시 허위의 정보를 제공하여서는 아니 되며, 기재한 사항이
                변경되었을 경우에는 즉시 변경사항을 최신의 정보로 수정하여야 합니다.
              </p>
              <p>
                (2) 회사의 회원에 대한 일체의 통지는 회원이 제공한 전자메일에 도달함으로써 통지된
                것으로 보며, 수정하지 않은 정보로 인하여 발생하는 손해는 당해 회원이 전적으로
                부담하며, 회사는 이에 대하여 아무런 책임을 지지 않습니다.
              </p>
              <p>
                (3) 회원의 개인정보의 수집, 이용, 처리, 제3자 제공 등에 대해서는 회사가 서비스 초기
                화면 또는 연결화면에 게시하는{' '}
                <a href='' onClick={e => goTo(e, FOOTER_PRIVACY)} style={{ color: '#835fec' }}>
                  [개인정보처리방침]
                </a>
                에 의합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제9조. 아이디 및 비밀번호의 관리</h3>
              <p>
                (1) 아이디(ID) 및 비밀번호에 대한 관리책임은 회원에게 있으며, 회원은 어떠한 경우에도
                본인의 아이디(ID) 또는 비밀번호를 타인에게 양도하거나 대여할 수 없습니다.
              </p>
              <p>
                (2) 회사의 귀책사유 없이 아이디(ID) 또는 비밀번호의 유출, 양도, 대여로 인하여
                발생하는 손실이나 손해에 대하여는 회원 및 이용자 본인이 그에 대한 책임을 부담합니다.
              </p>
              <p>
                (3) 회원은 아이디(ID) 또는 비밀번호를 도난당하거나 제3자가 무단으로 이를 사용하고
                있음을 인지한 경우, 이를 즉시 회사에 통보하여야 하고 회사는 이에 대한 신속한 처리를
                위하여 최선의 노력을 다하여야 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제10조. 이용계약의 종료</h3>
              <p>
                <strong>회원의 해지</strong>
              </p>
              <p>
                (1) 회원은 언제든지 해당 서비스화면을 통하여 회사에게 해지의사를 통지함으로써
                이용계약을 해지할 수 있습니다. 다만, 회원은 해지의사를 통지하기 최소한 7일 전에 모든
                거래를 완결시키는데 필요한 조치를 취하여야만 합니다.
              </p>
              <p>
                (2) 전항의 기간 내에 회원이 발한 의사표시로 인해 발생한 불이익에 대한 책임은 회원
                본인이 부담하여야 하며, 이용계약이 종료되면 회사는 회원에게 무상으로 제공한 각종
                혜택을 회수할 수 있습니다.
              </p>
              <br />
              <p>
                <strong>회사의 해지</strong>
              </p>
              <p>
                (3) 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다
              </p>
              <p>
                1. 다른 회원 또는 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국
                법령 또는 공서양속에 위배되는 행위를 한 경우
                <br />
                2. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
                <br />
                3. 제7조 제(5)항의 승낙거부사유가 있음이 확인된 경우
                <br />
                4. 본 약관에 규정된 회원의 의무, 진술 및 보증사항, 선행조건 등을 위반한 경우
                <br />
                5. 기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할
                경우
              </p>
              <p>
                (4) 당사자 일방에게 아래 각 호의 사유가 발생한 경우, 그 상대방은 별도의 최고 없이
                해지의 통지를 함으로써 이용계약을 해지할 수 있습니다.
              </p>
              <p>
                1. 본 약관상의 의무를 위반하여 상대방으로부터 그 시정을 요구 받은 후 7일 이내에 이를
                시정하지 아니한 경우
                <br />
                2. 부도 등 금융기관의 거래정지, 회생 및 파산절차의 개시, 영업정지 및 취소 등의
                행정처분, 주요 자산에 대한 보전처분, 영업양도 및 합병 등으로 이용계약의 이행이
                불가능한 경우
              </p>
              <p>
                (5) 회사가 해지를 하는 경우 회사는 회원에게 전자우편, 전화, 기타의 방법을 통하여
                해지사유를 밝혀 해지의사를 통지합니다. 이용계약은 회사의 해지의사를 회원에게 통지한
                시점에 종료됩니다.
              </p>
              <p>
                (6) 본 조에 의하여 회사가 이용계약을 해지하더라도, 해지 이전에 이미 체결된
                매매계약의 완결과 관련해서는 이 약관이 계속 적용됩니다.
              </p>
              <p>
                (7) 본 조에서 정한 바에 따라 이용계약이 종료될 시에는 회사는 회원에게 무상으로
                제공한 각종 혜택을 회수할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제11조. 서비스 기간과 중단</h3>
              <p>
                (1) 본 약관에 따른 서비스 기간은 이용신청에 대한 회사의 승낙이 회원에게 도달한
                시점부터 이용계약의 해지 시까지 입니다.
              </p>
              <p>
                (2) 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의
                사유가 발생한 경우에는 서비스의 제공을 점검 및 복구를 위하여 일시적으로 중단할 수
                있습니다.
              </p>
              <p>
                (3) 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는
                경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.
              </p>
              <p>
                (4) 회사는 무료로 제공되는 서비스의 전부 또는 일부를 회사의 정책 및 운영의 필요에
                따라 수정, 중단, 변경할 수 있으며, 관계법령에 특별한 규정이 없는 한 회원에게 별도로
                보상하지 않습니다.
              </p>
            </div>
            <h2>제3장. 서비스의 이용</h2>
            <div className='term-block'>
              <h3>제12조. 회원의 서비스 이용</h3>
              <p>
                회사는 회원에게 본 서비스에 포함된 모든 종류의 디지털 콘텐츠에 대하여, 개인적,
                비상업적 용도로 서비스를 이용할 수 있는, 양도불가능하며, 비독점적이고, 전세계적인
                라이선스를 부여합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제13조. 서비스 이용료</h3>
              <p>
                (1) 서비스 이용료란 판매자가 서비스를 이용하여 콘텐츠 등을 판매함에 따라 판매자가
                회사에 지불하여야 하는 금액을 의미하며, 회사는 서비스제공 비용, 시장상황, 콘텐츠
                등의 분류와 종류, 거래방식, 판매자의 판매 실적 및 구매자 만족도 등을 고려하여 각
                판매자에 대한 서비스 이용료를 정합니다. 서비스 이용료는 고정된 금액, 판매자의
                서비스를 통한 판매금액 총액의 일정 비율 등 다양하게 산정될 수 있습니다.
              </p>
              <p>
                (2) 회사는 서비스 이용료를 서비스에 판매자로 로그인 할 때 초기화면을 통하여
                고지합니다. 회사는 필요한 경우 서비스이용료를 신설, 변경할 수 있으며, 그러한 신설
                또는 변경사항은 회사가 제공하는 서비스화면을 통하여 공지합니다.
              </p>
              <p>
                (3) 회사는 판매 활성화 등을 위하여 서비스 이용료 범위 내에서 특정 콘텐츠에 대한
                서비스 이용료를 할인할 수 있습니다.
              </p>
              <p>
                (4) 서비스이용료는 판매대금에서의 공제, 판매예치금의 사용 등으로 결제할 수 있으며,
                회사와 판매자간의 협의 또는 회사의 내부 사정에 따라 요율, 결제방법 등이 변경될 수
                있습니다.
              </p>
              <p>
                (5) 회사는 판매자의 요청이 있을 경우 서비스 이용료에 대한 세금계산서를 발행합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제14조. 판매자의 의무</h3>
              <p>
                (1) 판매자는 판매할 콘텐츠 등을 서비스에 업로드하고, 가격, 설명, 미리보기 등 판매에
                필요한 정보를 직접 작성하여 등록 및 관리해야 합니다.
              </p>
              <p>
                (2) 판매자는 등록 상품에 특별한 거래조건이 있거나 추가되는 비용이 있는 경우 구매자가
                이를 알 수 있도록 명확하게 기재하여야 합니다.
              </p>
              <p>
                (3) 판매자는 상품 등록 시 다음 각 호에 해당하는 정보를 입력하여야 하고, 상품 등록 후
                상품정보제공고시가 변경되는 경우 그에 맞추어 관련 정보를 수정, 보완하여야 합니다.
              </p>
              <p>
                1. 품목별 재화 등에 관한 정보
                <br />
                2. 거래조건에 관한 정보 등 「전자상거래 등에서의 상품 등의 정보제공에 관한 고시」
                (공정거래위원회 고시 제2015-17호, 2016. 4. 1. 시행, 이하 ‘상품정보제공고시’)에서
                정한 정보
              </p>
              <p>
                (4) 판매자가 작성한 판매 콘텐츠 등에 대한 설명과 실제 콘텐츠 등의 내용이 실질적으로
                달라서는 안 됩니다.
              </p>
              <p>
                (5) 판매자는 서비스를 통하지 않고 구매자에게 직접 상품을 판매하거나 유도해서는 안
                됩니다.
              </p>
              <p>
                (6) 판매자는 판매자의 성명, 전화번호, 주소 등 「전자상거래 등에서의 소비자보호에
                관한 법률」 및 관련 법령에 의하여 회사가 통신판매중재자로서 소비자에게 제공하여야
                하는 정보를 항상 최신정보로 유지해야 합니다.
              </p>
              <p>
                (7) 판매자는 지적재산권 등 타인의 권리를 침해하는 콘텐츠 등을 판매해서는 안 됩니다.
              </p>
              <p>
                (8) 판매자는 총기, 음란물 등 관련 법령에 의하여 제조, 배포, 소지, 이용 등이 금지되는
                물품을 3D 프린트할 수 있는 콘텐츠 등을 판매해서는 안 됩니다.
              </p>
              <p>
                (9) 판매자는 논리적 오류, 파일 포맷과 다른 데이터, 3D 프린터의 오동작을 유발하는
                데이터 등 정상적인 작동이 불가능한 오류 또는 결함이 포함된 콘텐츠 등을 판매 해서는
                안 됩니다.
              </p>
              <p>
                (10) 판매자는 관련 법령 위반 등 판매자의 책임 있는 사유로 인하여 회사 또는 서비스의
                평판이 하락되거나 명예를 실추 시키는 등 유무형적 손해를 발생시켜서는 안 됩니다.
              </p>
              <p>
                (11) 본 조의 판매자가 판매자의 의무를 이행하지 않을 경우 회사는 판매자에 대한 콘텐츠
                등 판매 제한, 서비스 제공 중단, 이용 계약 해지 등 필요한 조치를 취할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제15조. 청약철회</h3>
              <p>
                (1) 청약철회(환불)와 관련하여, 판매자는 「전자상거래 등에서의 소비자보호에 관한
                법률」 및 「콘텐츠 산업 진흥법」의 관련 규정 및 본 조의 사항을 준수해야 합니다.
              </p>
              <p>
                (2) 구매자는 콘텐츠 등의 구매일 또는 이용가능일로부터 7일 이내에는 청약의 철회를 할
                수 있습니다.
              </p>
              <p>
                (3) 전항의 내용에도 불구하고, 구매자는 다음 각 호에의 어느 하나에 해당하는 경우에는
                판매자의 의사에 반하여 전항에 따른 청약철회등을 할 수 없습니다.
              </p>
              <p>
                1. 소비자에게 책임이 있는 사유로 재화등이 멸실되거나 훼손된 경우. 다만, 재화등의
                내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외합니다.
                <br />
                2. 소비자의 사용 또는 일부 소비로 재화등의 가치가 현저히 감소한 경우
                <br />
                3. 시간이 지나 다시 판매하기 곤란할 정도로 재화등의 가치가 현저히 감소한 경우
                <br />
                4. 복제가 가능한 재화등의 포장을 훼손한 경우
                <br />
                5. 용역 또는 「문화산업진흥 기본법」 제2조 제5호의 디지털콘텐츠의 제공이 개시(본
                서비스에서는 콘텐츠 등의 다운로드가 완료된 것을 제공의 개시로 봅니다)된 경우. 다만,
                가분적 용역 또는 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한
                부분에 대하여는 그러하지 아니합니다.
                <br />
                6. 그 밖에 거래의 안전을 위하여 대한민국 대통령령으로 정하는 경우
              </p>
              <p>
                (4) 판매자는 전항 제2호부터 제5호까지의 규정에 따라 청약철회등이 불가능한 재화등의
                경우에는 그 사실을 재화등의 포장이나 그 밖에 소비자가 쉽게 알 수 있는 곳에 명확하게
                표시하거나 시험 사용 상품을 제공하는 등의 방법으로 청약철회등의 권리 행사가 방해받지
                아니하도록 조치하여야 합니다. 다만, 전항 제(5)호 중 디지털콘텐츠에 대하여 소비자가
                청약철회등을 할 수 없는 경우에는 청약철회등이 불가능하다는 사실의 표시와 함께
                대한민국 대통령령으로 정하는 바에 따라, 다음 각 호의 구분에 따른 방법 중 하나 이상의
                방법으로 청약철회등의 권리 행사가 방해받지 아니하도록 하여야 합니다.
              </p>
              <p>
                1. 일부 이용의 허용: 디지털콘텐츠의 일부를 미리보기, 미리듣기 등으로 제공
                <br />
                2. 한시적 이용의 허용: 일정 사용기간을 설정하여 디지털콘텐츠 제공
                <br />
                3. 체험용 디지털콘텐츠 제공: 일부 제한된 기능만을 사용할 수 있는 디지털콘텐츠 제공
                <br />
                4. 제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기 곤란한 경우:
                디지털콘텐츠에 관한 정보 제공
              </p>
              <p>
                (5) 판매자가 관련 법령 및 본 약관에서 정하는 청약철회(환불) 규정을 준수하지 않은
                경우, 회사는 판매자에 대하여 경고, 해당 콘텐츠 판매 일시 정지, 판매 서비스 중지,
                이용 계약 해지 등의 조치를 취할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제16조. 정산</h3>
              <p>
                (1) 판매자가 판매한 콘텐츠 등의 판매 대금에 대한 정산은 판매 가격에서 서비스
                이용료를 제외한 기준으로 산정되며, 판매자가 정산 화면을 통해 정산을 요청한 후 30일
                이내에 지급하는 것을 원칙으로 하되, 각 판매자와 개별적으로 지급 기한을 협의할 수
                있습니다.
              </p>
              <p>
                (2) 판매자는 서비스에 로그인하여 정산 화면에서 정산 예정 금액을 확인할 수 있습니다.
                다만 회사는 서비스의 개발 또는 유지보수, 관리 과정에서 판매자에 대하여 자동화된
                정산화면을 제공하지 못할 수 있으며, 이 경우 회사는 판매자에게 판매 내역과 그에 대한
                정산 내역을 매월 이메일로 통지할 수 있습니다.
              </p>
              <p>
                (3) 회사는 판매자가 판매하는 콘텐츠 등에 대한 청약철회(환불) 요청 또는 합리적인
                사유가 있는 구매 불만 주장(클레임)이 다수 발생하여 정산 이후 1개월 이내에 정산 예정
                금액의 50%를 초과하는 환불이 예상되는 경우, 최대 3개월까지 정산을 보류할 수
                있습니다.
              </p>
            </div>
            <h2>제4장. 부가서비스의 이용</h2>
            <div className='term-block'>
              <h3>제17조. 판매촉진 서비스</h3>
              <p>
                회사는 콘텐츠 등 판매의 촉진을 위하여 회사 운영 정책에 따라 판매자가 구매자에게
                지급할 수 있는 할인 쿠폰이나 ‘크레딧’ 등을 판매자에게 제공할 수 있습니다.
              </p>
            </div>
            <h2>제5장. 이용자 관리 및 보호</h2>
            <div className='term-block'>
              <h3>제18조. 이용자 관리</h3>
              <p>
                (1) 회사는 본 약관의 내용과 관련 법령을 위반한 회원에 대하여 다음 같은 조치를 할 수
                있습니다.
              </p>
              <p>
                1. 회사가 부가적으로 제공한 혜택의 일부 또는 전부의 회수
                <br />
                2. 특정서비스 이용제한
                <br />
                3. 이용계약의 해지
                <br />
                4. 손해배상의 청구
              </p>
              <p>
                (2) 회사가 전항 각 호에 정한 조치를 할 경우 회사는 사전에 회원에게 유선 또는
                전자우편으로 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한
                경우 선조치 후 사후 통보할 수 있습니다.
              </p>
              <p>
                (3) 회원은 본조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우 이에 대하여
                항변을 할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제19조. 저작권과 라이선스</h3>
              <p>
                <strong>콘텐츠 등</strong>
              </p>
              <p>
                (1) 판매자가 제작하여 서비스를 통해 판매하는 모든 콘텐츠 등의 저작권은 저작권의
                소유자가 그대로 보유하며, 본 약관의 어떤 조항도 저작권이 회사 또는 구매자에게
                이관되는 것으로 해석되지 않습니다.
              </p>
              <p>
                (2) 판매자는 콘텐츠 등을 서비스에 업로드하고 판매 콘텐츠로 등록하는 시점부터 판매
                콘텐츠 등록 해제를 하는 시점까지, 회사에 대하여 회사의 서비스를 운영, 광고,
                마케팅하고 회사가 구매자와 판매자 사이에 콘텐츠 등을 거래할 수 있도록 중개할
                목적으로, 그 콘텐츠 등을 복제, 배포, 전시, 공연, 파생저작물 제작이 가능한,
                취소불가능한, 비독점적인, 서브라이선스(sub-license)부여 가능한, 로열티(royalty)가
                없는, 완납된(fully-paid), 전세계적인 라이선스 및 서브라이선스 부여권한을 부여하고,
                또한 판매자가 그러한 권리를 회사에 제공할 수 있는 권리를 가지고 있음을 진술하고
                보증합니다.
              </p>
              <p>
                (3) 회사가 본 계약상 이용범위와 목적내에서 콘텐츠 등을 사용하며 본 약관과 계약과
                별도의 계약을 체결하지 않는 한, 회사는 판매자에게 별도의 로열티를 지불하지 않습니다.
              </p>
              <br />
              <p>
                <strong>게시물</strong>
              </p>
              <p>
                (4) 회원이 회사가 제공하는 서비스를 이용하면서 서비스에서 판매되는 콘텐츠 등에 대한
                설명 등의 저작물(이하 ‘게시물’)의 저작권은 이를 작성한 회원 본인에게 있으며, 해당
                게시물이 타인의 저작권을 침해하는 경우 그에 대한 책임은 회원 본인이 부담합니다.
              </p>
              <p>
                (5) 회사는 게시물이 다음 각 호에 해당하는 경우 사전 통보 없이 해당 게시물을
                삭제하거나 게시자에 대하여 특정서비스의 이용제한을 할 수 있으며, 다음 각 호에
                해당하는 게시물을 2회 이상 게시하는 경우 이용계약을 해지 할 수 있습니다.
              </p>
              <p>
                1. 대한민국의 법령을 위반하는 내용을 포함하는 경우
                <br />
                2. 관계법령에 의거 판매가 금지된 콘텐츠 등 또는 음란물을 게시, 광고하는 경우
                <br />
                3. 허위 또는 과대광고의 내용을 포함하는 경우
                <br />
                4. 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 경우
                <br />
                5. 직거래 유도 또는 타 사이트의 링크를 게시하는 경우
                <br />
                6. 정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함하는 경우
                <br />
                7. 사회 공공질서나 미풍양속에 위배되는 경우
                <br />
                8. 회사가 제공하는 서비스의 원활한 진행을 방해하는 것으로 판단되는 경우
                <br />
                9. 범죄행위와 관련된 내용을 포함하는 경우
                <br />
                10. 정치, 경제적 분쟁을 야기하는 내용을 포함하는 경우
              </p>
              <p>
                (6) 회사는 회원이 작성한 게시물을 서비스에서 거래되는 콘텐츠 등을 판촉, 홍보하기
                위한 목적으로, 복제, 배포, 전송, 전시할 수 있으며, 본질적인 내용에 변경을 가하지
                않는 범위 내에서 수정, 편집할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제20조. 금지행위</h3>
              <p>
                <strong>허위체결</strong>
              </p>
              <p>
                (1) 판매자는 자신 혹은 타 판매자가 판매하는 콘텐츠 등의 판매에 영향을 미치기 위하여
                서비스 내에서 콘텐츠 등에 대한 의견을 작성하거나 표시하는 행위를 해서는 안 됩니다.
              </p>
              <br />
              <p>
                <strong>직거래</strong>
              </p>
              <p>
                (2) 회사가 제공하는 서비스를 이용하지 않고 판매자와 구매자간에 직접적으로 거래하는
                직거래를 통하여 발생한 제반 문제에 대한 책임은 거래 당사자에게 있으며 회사는 이로
                인하여 발생된 문제에 대하여 어떠한 책임도 지지 않습니다.
              </p>
              <p>
                (3) 직거래를 하거나 이를 유도한 것으로 확인된 판매자는 판매서비스 이용계약이 해지될
                수 있으며, 회원은 직거래를 하거나 유도한 판매자를 회사에 신고할 수 있습니다.
              </p>
              <br />
              <p>
                <strong>시스템 부정행위</strong>
              </p>
              <p>
                (4) 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를
                이용하거나 시스템에 접근하는 행위는 금지됩니다.
              </p>
              <p>
                (5) 회사는 시스템 부정행위가 확인된 경우 해당 판매자에 대하여 회사가 부가적으로
                제공한 혜택의 일부 또는 전부를 회수 할 수 있습니다.
              </p>
              <br />
              <p>
                <strong>제재</strong>
              </p>
              <p>
                (6) 본 조의 행위를 한 것으로 확인된 판매자에 대해서 회사는 해당 콘텐츠 판매 중단
                조치를 취할 수 있으며, 본 조의 행위를 2회 이상 한 것으로 확인될 경우 회사는
                판매자와의 이용계약을 해지하고, 그러한 행위로 인하여 발생한 손해가 있을 시
                손해배상을 청구할 수 있습니다.
              </p>
            </div>
            <h2>제6장. 기타</h2>
            <div className='term-block'>
              <h3>제21조. 관련 법령과의 관계</h3>
              <p>
                (1) 이 약관에 명시되지 않은 사항은 「전자상거래 등에서의 소비자보호에 관한 법률」 등
                관련 법령의 규정과 일반 상관습에 의합니다.
              </p>
              <p>
                (2) 서비스를 통하여 거래가 이루어지는 경우, 「전자상거래 등에서의 소비자보호에 관한
                법률」 등 관련 법령이 해당 거래 당사자에게 우선적으로 적용되고, 거래 당사자는 이
                약관의 규정을 들어 거래 상대방에 대하여 면책을 주장할 수 없습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제22조. 관련 약관과의 관계</h3>
              <p>
                (1) 판매자의 콘텐츠 등 구매 서비스를 이용에 관해서는 [서비스 이용약관(일반회원)]의
                내용이 적용됩니다.
              </p>
              <p>
                (2) 회사와 판매자 사이의 관계에서, 본 약관과 [서비스 이용약관(일반회원)]의 내용이
                충돌하는 경우, 본 약관의 내용이 우선합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제23조. 손해배상</h3>
              <p>
                (1) 회사 또는 회원이 본 약관상 의무와 진술 및 보증을 위반하는 경우, 상대방에 대하여
                그러한 의무 위반과 상당인과관계에 있는 손해를 배상해야 합니다.
              </p>
              <p>
                (2) 회원이 본 약관상 회원의 의무를 위반하거나 타인의 저작권 등 기타 권리를 침해하여
                회사가 제3자로부터 손해배상청구 등의 이의 제기를 받은 경우, 회원은 회사를 면책하여야
                하며 회사에 발생한 모든 손해를 배상해야 합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제24조. 비밀유지</h3>
              <p>
                (1) 회사 또는 회원은 법령상 요구되는 경우를 제외하고는 상대방으로부터 취득한
                구매자명부, 기술정보, 생산 및 판매계획, 노하우 등 비밀로 관리되는 정보를 제3자에게
                누설하여서는 안되며, 그 정보를 이용계약의 이행 이외의 목적으로 이용하여서는 아니
                됩니다.
              </p>
              <p>(2) 전항의 의무는 이용계약의 종료 후에도 3년간 존속합니다.</p>
            </div>
            <div className='term-block'>
              <h3>제25조. 회사의 면책</h3>
              <p>
                (1) 회사는 통신판매중개자로서 콘텐츠 등의 거래를 위한 서비스를 제공할 뿐, 판매자가
                등록한 콘텐츠 등에 관한 정보 또는 구매자와의 거래에 관하여 분쟁이 발생한 경우 회사는
                그 분쟁에 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 판매자가 부담합니다. 단,
                회사는 분쟁의 합리적이고 원활한 조정을 위하여 회사가 설치 운영하는
                분쟁조정기구(고객센터 포함)를 통하여 예외적으로 당해 분쟁에 개입할 수 있으며,
                판매자는 분쟁조정센터의 결정을 신의칙에 입각하여 최대한 존중해야 합니다.
              </p>
              <p>
                (2) 회사는 적법한 권리자의 요구가 있는 경우에는 당해 콘텐츠 등에 관한 정보를
                삭제하거나 수정할 수 있으며, 판매자는 이로 인한 손해배상을 회사에 청구할 수
                없습니다.
              </p>
              <p>
                (3) 제11조 제(2)항의 사유로 인하여 서비스를 일시적으로 중단하는 경우 이로 인하여
                회원 또는 제3자가 입은 특별손해에 대하여 책임지지 않습니다.
              </p>
              <p>
                (4) 제11조 제(3)항의 사유로 인하여 서비스를 제한하거나 중단하는 경우 회사는 그
                책임을 면합니다.
              </p>
              <p>
                (5) 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
              </p>
              <p>
                (6) 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써, 발생하는 피해에 대해서
                회사는 일체의 책임을 지지 않습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제26조. 준거법 및 관할법원</h3>
              <p>(1) 회사와 판매자 간 제기된 소송은 대한민국법을 준거법으로 합니다.</p>
              <p>
                (2) 이 약관과 회사와 회원 간의 이용계약 및 회원 상호간의 분쟁에 대해 회사를 당사자로
                하는 소송이 제기될 경우에는 회사의 본사 소재지를 관할하는 법원을 합의관할법원으로
                정합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제27조. 기타조항</h3>
              <p>
                (1) 회사와 회원은 상대방의 사전 서면 동의 없이 본 약관상의 권리와 의무를 제3자에게
                양도 할 수 없습니다.
              </p>
              <p>
                (2) 본 약관과 관련하여 당사자 간의 합의에 의하여 추가로 작성된 계약서, 협정서,
                통보서 등과 회사의 정책변경, 법령의 제정·개정 또는 공공기관의 고시·지침 등에 의하여
                회사가 서비스를 통해 회원에게 공지하는 내용도 본 약관의 일부를 구성합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>부칙</h3>
              <p>1. 적용 일자</p>
              <p>(1) 이 약관은 2024년 02월 23일부터 시행합니다.</p>
              <p>(2) 2016년 12월 26일부터 시행되던 종전의 약관은 본 약관으로 대체합니다.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermKo;
