/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import { HOSTNAME, getPrinters, getSlicers, signup, validInvite } from '../../action/request';
import ModalContext from '../../context/modal/modal';
import { FOOTER_PRIVACY, FOOTER_TERMS } from '../../route/constant';
import './Signup.css';
import Apple from '../sns/Apple';
import Google from '../sns/Google';
import Facebook from '../sns/Facebook';
import Yahoo from '../sns/Yahoo';
import Twitter from '../sns/Twitter';
import Github from '../sns/Github';
import { loginSignupEn, loginSignupKo } from '../../context/lang/constant';
import LangContext from '../../context/lang/lang';
import { validateEmail } from '../../util/validate';
import { Ss } from '../../constant';
import { gtagOnClickSignup, gtagOnClickSignupAd } from '../../action/gTag';
import { useNavigate, useLocation } from 'react-router-dom';

const Signup = () => {
  const [printers, setPrinters] = useState([]);
  const [slicers, setSlicers] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedPrinter, setSelectedPrinter] = useState(0);
  const [selectedSlicer, setSelectedSlicer] = useState(0);
  const [inviteTokenState, setInviteTokenState] = useState();
  const [emailValue, setEmailValue] = useState(false);
  const [ladingPage, setLadingPage] = useState(false);
  const navigate = useNavigate();
  const { state, action } = useContext(ModalContext);
  const { state: langState } = useContext(LangContext);
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;

  //googleAds ad.fab365 => fab365 유저의 신규가입 추적
  const loction = useLocation();

  useEffect(() => {
    if (loction?.search === '?t=google') {
      setLadingPage(true);
    }

    // fetchData();
  }, []);

  useEffect(() => {
    // 초대 토큰
    if (sessionStorage.getItem(Ss.inviteToken) === 'google') return;
    setInviteTokenState(sessionStorage.getItem(Ss.inviteToken));
  }, [sessionStorage.getItem(Ss.inviteToken)]);

  const fetchData = async () => {
    // TODO:GET 새로고침시 매번 데이터 통신하는 이유 문의
    try {
      const { data: printers } = await getPrinters();
      setPrinters(printers.data);
      const { data: slicers } = await getSlicers();
      setSlicers(slicers.data);
    } catch (e) {}
  };

  const onCreateAccount = async () => {
    // email, password 유효성 검사
    // if (!email.trim().length) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_UNSET);
    if (!emailValue) return alert(loginSignupMessages.SIGNUP_ERROR_EMAIL_INVALID);

    if (!validateEmail(email)) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_INVALID);
    if (!password.trim().length) return alert(loginSignupMessages.SIGNUP_ERROR_PASSWORD_NEED);

    try {
      // 초대 렌딩페이지
      if (inviteTokenState !== 'null' && inviteTokenState !== 'undefined' && inviteTokenState) {
        try {
          const { data } = await validInvite(inviteTokenState);
          if (data.result) {
            // 초대 토큰 유효하면 회원가입 진행
            onSignUp();
          } else {
            // 초대 토큰 유효하지 않음
            return alert('request failed');
          }
        } catch (e) {
          return alert(e);
        }
      } else {
        // 일반 회원가입
        onSignUp();
      }
    } catch (e) {}
  };

  const onSignUp = async () => {
    const params = {
      action: 'signup',
      params: {
        email: email.trim(),
        password: password.trim(),
        printer: selectedPrinter,
        slicer: selectedSlicer,
        invite_token: inviteTokenState,
        siteUrl: HOSTNAME,
      },
    };
    try {
      const { data } = await signup(JSON.stringify(params));

      if (data.data) {
        action.setIsSignup(false);
        alert(
          'We have sent an account verification email to the email address you entered. Please log in after completing authentication. If you can’t find the verification email, please check your spam folder.'
        );
        // gtag 일반 회원가입 추적 태그
        if (ladingPage) {
          gtagOnClickSignupAd();
          setLadingPage(false);
        } else {
          gtagOnClickSignup('Fab365');
        }

        return action.setIsLogin(true);
      }
      if (data.message) return alert(data.message);
    } catch (e) {}
  };

  function isEmail(email) {
    const emailRegex =
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(email);
  }

  const onChangeEmail = e => {
    const ko = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    const regex = isEmail(e.target.value);
    if (!ko.test(e.target.value) && regex) {
      // console.log('통과', e.target.value);
      setEmailValue(true);
      setEmail(e.target.value);
    } else {
      setEmailValue(false);
      // console.log('통과아니야📌', e.target.value);
      setEmail(e.target.value);
    }
  };
  const onChangePassword = e => {
    setPassword(e.target.value);
  };
  const onChangeSelectedPrinter = e => {
    setSelectedPrinter(e.target.value);
  };
  const onChangeSelectedSlicer = e => {
    setSelectedSlicer(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onCreateAccount();
    }
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();
    action.setIsSignup(false);
    navigate(path);
  };

  const closeModal = () => {
    document.body.classList.remove('modal-opened');
  };

  return (
    <>
      <div className={'modal' + (state.isSignup ? ' active' : '')} id='modalSignUp'>
        <div className='modal-inner'>
          <div className='modal-box'>
            <div className='modal-box-header'>
              <h1 className='modal-title'>Sign up</h1>
            </div>
            <div className='modal-box-body'>
              <form action='' name='signup_form' className='signup-form m-b-lg'>
                <div className='signup-agree m-b-lg'>
                  <p>
                    By creating an account, you agree to Fab365's
                    <br />
                    <a href='' onClick={e => goTo(e, FOOTER_PRIVACY)} className='txt-green'>
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a href='' onClick={e => goTo(e, FOOTER_TERMS)} className='txt-green'>
                      Terms of Use
                    </a>
                  </p>
                </div>

                <div className='form-control'>
                  <input
                    type='text'
                    placeholder='E-Mail'
                    className='form-input'
                    name='email'
                    value={email}
                    onChange={onChangeEmail}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className='form-control'>
                  <input
                    type='password'
                    placeholder='Password'
                    className='form-input'
                    name='password'
                    value={password}
                    onChange={onChangePassword}
                    onKeyDown={handleKeyPress}
                    maxLength={36}
                  />
                </div>
                {/* <div className='form-control'>
                  <select
                    className='form-input'
                    name='printer'
                    onChange={onChangeSelectedPrinter}
                    value={selectedPrinter}
                    onKeyDown={handleKeyPress}
                  >
                    <option value='0'>Select your 3D printer {'>>>'}</option>
                    {printers.map(p => {
                      return (
                        <option key={p.id_printer} value={p.id_printer}>
                          {p.printer}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className='form-control'>
                  <select
                    className='form-input'
                    name='slicer'
                    onChange={onChangeSelectedSlicer}
                    value={selectedSlicer}
                    onKeyDown={handleKeyPress}
                  >
                    <option value='0'>Select your slicer {'>>>'}</option>
                    {slicers.map(s => {
                      return (
                        <option key={s.id_slicer} value={s.id_slicer}>
                          {s.slicer}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
                <button
                  type='button'
                  className='btn btn-full btn-fill btn-green signup'
                  onClick={onCreateAccount}
                >
                  Create Account
                </button>

                <p>
                  Have an account?{' '}
                  <a
                    onClick={() => {
                      action.setIsSignup(false);
                      action.setIsLogin(true);
                    }}
                    className='txt-green remodal-login'
                  >
                    Login
                  </a>
                </p>
                <hr className='or' />
                <Google />
                <Facebook />
                <Yahoo />
                <Apple />
                <Twitter />
                <Github />
              </form>
            </div>
            <a
              onClick={() => {
                action.setIsSignup(false);
                closeModal();
              }}
              data-hide-modal='true'
              className='btn-close'
            >
              Close
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
