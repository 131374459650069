import React, { useEffect, useState } from 'react';
import FollowerUserProfile from './FollowerUserProfile';
import styled from 'styled-components';
import { getDesignerFollowers } from '../../../action/request';
import Pagenation from '../Pagenation';
import { useRecoilValue } from 'recoil';
import { followStatusAtom } from '../../../atom/atom';

const Followrs = ({ moveScroll, selectedSort, data }) => {
  const studioIdNumber = Number(data);
  const [page, setPage] = useState(1);
  const [followList, setFollowList] = useState([]);
  const [count, setCount] = useState(0);
  const [pageNation, setPageNation] = useState(1);
  const followStatus = useRecoilValue(followStatusAtom);

  const fetchData = async studioId => {
    try {
      const requesBody = {
        page: page,
        sortType: selectedSort.value,
      };
      const { data: followerData } = await getDesignerFollowers(studioId, requesBody);
      setFollowList(followerData.data?.followList);
      setCount(followerData.data?.total);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(studioIdNumber);
  }, [selectedSort, page, followStatus, data]);

  return (
    <FollowersStyle>
      {followList && <FollowerUserProfile followList={followList} />}

      {count > 0 && (
        <Pagenation
          count={count}
          setPage={setPage}
          setPageNation={setPageNation}
          page={page}
          moveScroll={moveScroll}
          viewCount={32}
          value={'Followrs'}
        />
      )}
    </FollowersStyle>
  );
};

export default Followrs;

const FollowersStyle = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  margin-top: 25px;
`;
