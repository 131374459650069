import React from 'react';

const LicenseKo = () => {
  return (
    <div>
      <div className='container'>
        <div className='terms'>
          <div className='terms-header'>
            <h1 className='terms-title'>FAB365 사용자 라이선스 계약</h1>
          </div>
          <div className='terms-body'>
            <div className='term-block'>
              <h3>제1조 목적</h3>
              <p>
                본 라이선스 계약은 FAB365 Inc. (이하 '회사'라 함)에서 제공하는 온라인 3D 프린팅 모델
                전자 상거래 플랫폼(이하 '서비스'라 함)에 관한 콘텐츠의 소유권 및 라이선스 부여(사용
                목적 및 사용 범위 포함)을 목적으로 하며, 구매자와 판매자의 콘텐츠에 대한 저작권 및
                사용에 관한 권리, 의무, 책임 및 기타 필요한 사항에 대해 규정합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제2조 용어의 정의</h3>
              <p>(1) 본 계약에서 사용되는 용어의 정의는 다음과 같습니다.</p>
              <p style={{ marginLeft: 30 }}>
                1. 회원: 회사에 개인 정보를 제공하여 가입한 개인 또는 법인으로, 일반 회원과 디자이너
                회원으로 구분됩니다.
              </p>
              <p style={{ marginLeft: 50 }}>
                a. 일반 회원(이하 '일반 회원' 또는 '구매자'): 회사가 제공하는 구매 서비스를 이용할
                수 있는 14세 이상의 개인 또는 법인
                <br />
                b. 디자이너 회원(이하 '디자이너 회원' 또는 '판매자'): 회사가 제공하는 구매 및 판매
                서비스를 이용할 수 있는 14세 이상의 개인 또는 법인
              </p>
              <p style={{ marginLeft: 30 }}>
                2. 콘텐츠: 판매자와 구매자 또는 회사와 구매자 간에 회사가 제공하는 서비스를 통해
                거래되는 3D 프린팅 모델 데이터 및 관련 콘텐츠
              </p>
              <p>
                (2) 본 계약에서 정의되지 않은 용어의 의미는 일반적인 거래 표준/관행에 따라
                해석됩니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제3조 콘텐츠의 소유권</h3>
              <p>
                모든 콘텐츠의 저작권은 저작권 소유자에게 있으며, 본 계약의 어떠한 조항도 구매자에게
                저작권을 양도하는 것으로 해석되지 않습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제4조 판매자의 구매자에 대한 라이선스 부여</h3>
              <p>
                구매자가 서비스를 통해 어떠한 콘텐츠를 구매할 경우, 판매자는 구매 시점부터 콘텐츠의
                사용에 대한 라이선스를 구매자에게 부여합니다. 구매자의 콘텐츠 사용 목적 및 방법은
                다음과 같습니다.
              </p>
              <p>1. 콘텐츠 사용: 개인 및 비상업적 목적</p>
              <p>
                2. 사용 범위: 복사, 배포, 전시, 공연, 방송, 대여, 연극, 수정, 편집, 공개 전송 및
                파생 작업 작성은 불가능하며, 3D 프린터를 통해 읽고 인쇄할 수 있음
              </p>
              <p>3. 사용 조건: 양도 불가능, 비독점적이며 권리 없음, 무상 사용 가능</p>
              <p>
                4. 사용 매체 또는 플랫폼: 현재 알려진 또는 개발될 모든 종류의 미디어, 장비 및 기술
                포함
              </p>
            </div>
            <div className='term-block'>
              <h3>제5조 인쇄 콘텐츠 등의 판매</h3>
              <p>
                구매자는 콘텐츠를 3D 프린터에서 인쇄할 수 있습니다. 다만, 구매자는 상업적 목적으로
                인쇄된 콘텐츠를 판매, 복사, 배포, 전시, 공연, 방송, 대여, 연극, 수정, 편집, 공개
                전송하거나 파생 작업을 작성할 수 없습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제6조 로열티</h3>
              <p>
                구매자는 본 계약에 따른 콘텐츠의 사용 목적과 범위 내에서 사용하여야 하며, 별도의
                계약이 체결되지 않는 한 다른 로열티를 지불하지 않아야 합니다.
              </p>
            </div>

            <div className='term-block'>
              <h3>제7조 판매자가 구매자에게 다른 라이선스를 부여하는 계약</h3>
              <p>
                본 계약에 따른 규정에도 불구하고, 판매자와 구매자는 본 계약과 일치하지 않는 목적,
                사용 범위, 사용 매체 및 인쇄 콘텐츠 판매 등에 대한 조건 및 조항을 포함하여 별도의
                계약을 체결할 수 있습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제8조 회사의 역할</h3>
              <p>
                회사는 본 계약 또는 판매자와 구매자 간 별도로 체결된 계약의 조건 및 조항을 위반하는
                경우를 방지하기 위해 상업적으로 합리적인 조치를 취하겠습니다. 다만, 회사는 모든
                서비스에 등록된 모든 콘텐츠와 관련된 판매자의 저작권 또는 관련 권리를 침해한
                구매자에게 사실적이거나 법적인 조치를 취할 의무가 없으며, 해당 침해로 인한 구매자의
                손해를 배상할 의무가 없습니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제9조 콘텐츠 라이선스 우선조항</h3>
              <p>
                콘텐츠 파일 내 문서, 콘텐츠 상세 정보 및 기타 화면에 라이선스 조항이 포함된 경우,
                해당 내용이 본 계약에 우선합니다.
              </p>
            </div>
            <div className='term-block'>
              <h3>제10조 준거법 및 관할법원</h3>
              <p>
                (1) 회사와 구매자 간 또는 회사와 판매자 간에 제기된 소송은 대한민국법에 따라
                진행됩니다.
              </p>
              <p>(2) 본 계약에 관한 소송이 제기된 경우, 관할법원은 민사소송법에 따라 결정됩니다.</p>
            </div>

            <p>
              ﹡ 모든 지원 요청, 완료된 등록 및 기타 문의 사항은{' '}
              <a
                href='mailto:info@fab365.net'
                style={{ color: '#835fec', textDecoration: 'underline' }}
              >
                info@fab365.net
              </a>
              으로 이메일로 보내주시기 바랍니다.
              <br />
              또한{' '}
              <a
                href='http://www.fab365.net/help'
                style={{ color: '#835fec', textDecoration: 'underline' }}
              >
                http://www.fab365.net/help
              </a>{' '}
              에서 양식, 자주 묻는 질문에 대한 답변 및 기타 정보를 확인할 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseKo;
