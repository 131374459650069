import React from 'react';
import styled from 'styled-components';

const DesignersCountBox = ({ icon, title, count }) => {
  return (
    <Box className='box'>
      <Icon width={title === 'Reviews' ? '22px' : '25px'} src={icon} />
      <p className='title'>{title}</p>
      <span className='count'>{count}</span>
    </Box>
  );
};

export default DesignersCountBox;

const Box = styled.div`
  height: 130px;
  background: #272727;
  border-radius: 6px;

  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 10px 20px 10px;

  .title {
    font-weight: 100;
    font-size: 14px;
  }

  .count {
    font-size: 22px;
    font-weight: 600;
  }

  @media only screen and (max-width: 1140px) {
    width: 100%;
    height: 130px;
    max-width: 120px;
  }

  @media only screen and (max-width: 480px) {
    padding: 10px;
    height: 110px;

    .title {
      font-size: 13px;
    }

    .count {
      font-size: 20px;
    }
  }
`;

const Icon = styled.img`
  width: ${props => props.width};
  cursor: default !important;
`;
