import styled from 'styled-components';

export const Container = styled.div`
  /* -------------------------------------------------------------------------- */
  /*                                  초대 렌딩페이지                                  */
  /* -------------------------------------------------------------------------- */
  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
  #invite-landing div,
  #invite-landing span,
  #invite-landing p,
  #invite-landing img,
  #invite-landing section {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  #invite-landing button {
    border: none;
    cursor: pointer;
  }
  #invite-landing.invite-landing-container {
    font-family: 'Outfit', sans-serif;
    overflow: hidden;

    .invite-landing-sinup-btn {
      display: block;
      margin: 0 auto;
      background: linear-gradient(to right, #65fba1, #00ec5e);
      padding: 33px 0px;
      width: 500px;
      border-radius: 999px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .invite-landing-sinup-btn.size-m {
      padding: 24px 0px 24px 25px;
      width: 274px;
      margin-top: 49px;
    }

    .invite-landing-sinup-btn span {
      font-size: 40px;
      font-weight: 700;
      line-height: 50.4px;
      color: #000 !important;
      margin-right: 10px;
      margin-top: 2px;
    }

    .invite-landing-sinup-btn.size-m span {
      font-size: 28px;
      line-height: 36.54px;
      margin-right: 8px;
    }

    .invite-landing-sinup-btn p {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      line-height: 19.5px;
      font-weight: 500;
      color: #e1e1e1;
      position: absolute;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      cursor: default;
    }

    .slick-dots li button:before,
    .slick-next:before,
    .slick-prev:before {
      font-family: 'Montserrat', sans-serif;
    }

    /* -------------------------------------------------------------------------- */
    /*                                section1-main                               */
    /* -------------------------------------------------------------------------- */
    .invite-landing-main-section {
      background-color: #000;
      background-image: url('/assets/img/inviteLanding/bg_space.png');
      background-repeat: no-repeat;
      background-size: cover;
      padding: 41px 0 0;
    }

    .invite-landing-header img {
      display: block;
      margin: 0 auto;
      max-width: 75.62px;
    }

    .invite-landing-main-title {
      color: #ffffff;
      font-family: 'Bebas Neue', sans-serif;
      font-weight: 400px;
      font-size: 80px;
      line-height: 96px;
      text-align: center;
      margin-top: 101px;
      padding: 0px 20px;
    }

    .invite-landing-main-title span {
      background: linear-gradient(to bottom, #00ce78, #00ec5e);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .invite-landing-sub-title {
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      color: #c0c0c0;
      margin: 48px auto 0;
      max-width: 995px;
      padding: 0px 20px;
    }

    .invite-landing-main-section-bg {
      height: 1227px;
    }

    .invite-landing-main-slide {
      margin: 20px auto 0;
      max-height: 546px;
      max-width: 1920px;
      overflow: hidden;
    }

    .invite-landing-main-slide img {
      height: 546px;
      width: auto !important;
      max-width: 900px;
      margin: 0 75px;
    }

    .invite-landing-masking-tape-bg {
      height: 100px;
      background-color: black;
      margin-top: 136px;
      padding: 0 47px;
    }

    .invite-landing-masking-tape {
      height: 100px;
      background-image: url('/assets/img/inviteLanding/bg_masking_tape_pc.svg');
      background-repeat: repeat-x;
      background-position-y: center;
    }

    /* -------------------------------------------------------------------------- */
    /*                               section2-signup                              */
    /* -------------------------------------------------------------------------- */
    .invite-landing-signup-section {
      background-color: #fff;
      padding: 108px 20px 158px;
    }

    .invite-landing-freeitem-title-container {
      margin: 0 auto;
      max-width: 1280px;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
    }

    .invite-landing-freeitem-title {
      width: 657px;
      font-size: 40px;
      font-weight: 500;
      line-height: 50.4px;
    }

    .invite-landing-freeitem-sub {
      width: 423px;
      font-weight: 500px;
      font-size: 24px;
      line-height: 30.24px;
      text-align: right;
      opacity: 50%;
    }

    .invite-landing-freeitem-slot {
      margin: 92px auto 0;
      max-width: 1280px;
    }

    .invite-landing-freeitem-slot-inner-container {
      position: relative;
      max-width: 430px;
      overflow: hidden;
      padding: 0 5px;
    }

    .invite-landing-freeitem-slot-inner-container button {
      position: absolute;
      bottom: 20px;
      right: 20px;
      max-width: 140px;
      max-height: 50px;
      background-color: #2ce999;
      border-radius: 999px;
      padding: 16px 28px;
      font-size: 14px;
      font-weight: 600;
      color: #000 !important;
    }

    .invite-landing-freeitem-slot-inner-container img {
      width: 100%;
      height: auto;
    }

    .invite-landing-signup-section .slick-arrow {
      width: 50px;
      height: 50px;
      background-image: url('/assets/img/inviteLanding/slidebutton_left.svg');
      overflow: hidden;
      position: absolute !important;
      top: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      text-indent: -9999px;
    }

    .invite-landing-signup-section .slick-prev {
      left: -50px;
    }

    .invite-landing-signup-section .slick-next {
      left: calc(100% + 25px);
      transform: scaleX(-1);
    }

    .invite-landing-signup-section .slick-dots {
      position: absolute;
      bottom: -38px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      width: auto;
    }

    .invite-landing-signup-section .slick-dots li {
      margin: 0 8px 0 0 !important;
      height: 8px;
      width: 8px;
      overflow: hidden;
      border-radius: 999px;
    }

    .invite-landing-signup-section .slick-dots li:last-child {
      margin-right: 0 !important;
    }

    .invite-landing-signup-section .slick-dots li button {
      height: 8px;
      width: 8px;
      border-radius: 999px;
      position: absolute;
      background-color: #d9d9d9;
      padding: 0;
      text-indent: -9999px;
    }
    .invite-landing-signup-section li.slick-active button {
      background-color: #2b2b2b;
    }

    /* -------------------------------------------------------------------------- */
    /*                               section3-ticket                              */
    /* -------------------------------------------------------------------------- */
    .invite-landing-ticket-section {
      background-color: #313131;
      padding: 85px 20px 110px;
    }

    .invite-landing-ticket-title {
      margin: 0 auto;
      color: #fff;
      font-size: 40px;
      font-weight: 500;
      line-height: 50.4px;
      text-align: center;
      max-width: 930px;
    }

    .invite-landing-ticket-title span {
      font-weight: 200;
    }

    .invite-landing-ticket {
      display: block;
      margin: 100px auto 0;
    }

    /* -------------------------------------------------------------------------- */
    /*                               section4-howto                               */
    /* -------------------------------------------------------------------------- */
    .invite-landing-howto-section {
      background-color: #000;
      padding: 85px 20px 161px;
    }

    .invite-landing-howto-title {
      color: #fff;
      font-weight: 500;
      font-size: 40px;
      line-height: 50.4px;
      text-align: center;
    }

    .invite-landing-howto-section .invite-landing-image-wrapper {
      max-width: 1120px;
      max-height: 1080px;
      margin: 99px auto 117px;
    }

    .invite-landing-howto-process-pc {
      max-width: 1120px;
      display: block;
    }

    .invite-landing-howto-process-mo {
      display: none;
    }

    /* -------------------------------------------------------------------------- */
    /*                              section5-paiditem                             */
    /* -------------------------------------------------------------------------- */
    .invite-landing-paiditem-section {
      padding: 85px 20px 153px;
      background-color: #fff;
    }

    .invite-landing-paid-title {
      font-weight: 500;
      font-size: 40px;
      line-height: 50.4px;
      max-width: 669px;
      margin: 0 auto;
      text-align: center;
    }

    .invite-landing-paiditem-section img {
      display: block;
      margin: 0 auto;
      max-width: 1230px;
    }

    .invite-landing-paiditem-section .invite-landing-image-wrapper {
      max-width: 1230px;
      max-height: 679px;
      margin: 0 auto;
    }

    .invite-landing-paiditem-section .first-wrapper {
      margin: 80px auto 100px;
    }

    .invite-landing-paiditem-section .invite-landing-paid-item-bk-pc {
      display: block;
    }

    .invite-landing-paiditem-section .invite-landing-paid-item-mo {
      display: none;
    }

    /* -------------------------------------------------------------------------- */
    /*                               section6-review                              */
    /* -------------------------------------------------------------------------- */
    .invite-landing-review-section {
      background-color: #000;
      padding: 85px 20px 133px;
    }

    .invite-landing-review-title {
      font-weight: 500;
      font-size: 40px;
      line-height: 50.4px;
      text-align: center;
      color: #fff;
    }

    .invite-landing-review-section .invite-landing-image-wrapper {
      margin: 148px auto 0;
      max-width: 1214px;
      max-height: 1675px;
    }

    .invite-landing-review-pc {
      max-width: 1214px;
      display: block;
    }

    .invite-landing-review-mo {
      display: none;
    }

    /* -------------------------------------------------------------------------- */
    /*                               section7-enjoy                               */
    /* -------------------------------------------------------------------------- */
    .invite-landing-enjoy-section {
      background-color: #fff;
      color: #000;
      padding: 85px 20px 76px;
    }

    .invite-landing-enjoy-title {
      font-size: 40px;
      font-weight: 500;
      line-height: 50.4px;
      text-align: center;
    }

    .invite-landing-enjoy-section .invite-landing-sinup-btn {
      position: relative;
      margin-top: 60px;
    }

    .invite-landing-enjoy-bg {
      position: absolute;
      top: 0;
    }

    .invite-landing-enjoy-bg.left {
      right: calc(100% + 89px);
    }
    .invite-landing-enjoy-bg.right {
      left: calc(100% + 89px);
      transform: scaleX(-1);
    }

    .invite-landing-enjoy-sub-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24.38px;
      text-align: center;
      margin-top: 40px;
      font-family: 'Montserrat', sans-serif;
    }

    .invite-landing-social-container {
      text-align: center;
      margin-top: 24px;
    }

    .invite-landing-social-container button {
      border-radius: 100%;
      padding: 8px;
      margin-right: 6px;
    }

    .invite-landing-social-container button:last-child {
      margin-right: 0;
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                   이미지 반응형                                  */
  /* -------------------------------------------------------------------------- */
  @media screen and (max-width: 1160px) {
    /* -------------------------------------------------------------------------- */
    /*                               section4-howto                               */
    /* -------------------------------------------------------------------------- */
    #invite-landing.invite-landing-container {
      .invite-landing-howto-section .invite-landing-image-wrapper {
        position: relative;
        padding-bottom: calc(1080 / 1120 * 100%);
        overflow: hidden;
        box-sizing: content-box;
        left: 50%;
        transform: translateX(-50%);
      }

      .invite-landing-howto-process-pc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 1275px) {
    /* -------------------------------------------------------------------------- */
    /*                              section5-paiditem                             */
    /* -------------------------------------------------------------------------- */
    #invite-landing.invite-landing-container {
      .invite-landing-paiditem-section .invite-landing-image-wrapper {
        position: relative;
        padding-top: calc(679 / 1230 * 100%);
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);
      }

      .invite-landing-paiditem-section .invite-landing-paid-item-bk-pc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }

      .invite-landing-paiditem-section .invite-landing-paid-item-wh-pc {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  @media screen and (max-width: 1255px) {
    /* -------------------------------------------------------------------------- */
    /*                               section6-review                              */
    /* -------------------------------------------------------------------------- */
    #invite-landing.invite-landing-container {
      .invite-landing-review-section .invite-landing-image-wrapper {
        position: relative;
        padding-bottom: calc(1675 / 1214 * 100%);
        overflow: hidden;
        box-sizing: content-box;
        left: 50%;
        transform: translateX(-50%);
      }

      .invite-landing-review-pc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                슬라이드 이미지 반응형                                */
  /* -------------------------------------------------------------------------- */

  @media screen and (max-width: 900px) {
    /* -------------------------------------------------------------------------- */
    /*                                section1-main                               */
    /* -------------------------------------------------------------------------- */
    #invite-landing.invite-landing-container {
      .invite-landing-main-slide {
        max-height: 340px;
        max-width: 480px;
      }

      .invite-landing-main-slide img {
        height: 340px;
        max-width: 480px;
        margin: 0 40px;
      }
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                   모바일 반응형                                  */
  /* -------------------------------------------------------------------------- */
  @media screen and (max-width: 480px) {
    #invite-landing.invite-landing-container {
      .invite-landing-sinup-btn {
        padding: 19px 0px;
        width: 300px;
        height: 62px;
      }

      .invite-landing-sinup-btn.size-m {
        padding: 10px 0 10px 10px;
        width: 146px;
        height: 41px;
        margin-top: 26px;
      }

      .invite-landing-sinup-btn span {
        font-size: 20px;
        margin-right: 6px;
      }

      .invite-landing-sinup-btn.size-m span {
        font-size: 16px;
        margin-right: 4px;
        font-weight: 600;
        line-height: 16px;
      }

      .invite-landing-sinup-btn p {
        font-size: 14px;
        line-height: 17.07px;
        color: #929292;
        bottom: calc(-17px - 10px);
      }

      .invite-landing-sinup-btn-arrow {
        width: 20px;
        height: 20px;
      }

      /* -------------------------------------------------------------------------- */
      /*                                section1-main                               */
      /* -------------------------------------------------------------------------- */
      .invite-landing-header img {
        width: 56.38px;
      }

      .invite-landing-main-title {
        font-size: 40px;
        line-height: 48px;
        margin-top: 47px;
        padding: 0 45px;
      }

      .invite-landing-sub-title {
        font-size: 14px;
        line-height: 17.64px;
        margin-top: 41px;
        padding: 0 52px;
      }

      .invite-landing-main-slide {
        margin-top: 10px;
        max-height: 301px;
        max-width: 360px;
      }

      .invite-landing-main-slide img {
        height: 301px;
        max-width: 360px;
        margin: 0 5px;
      }

      .invite-landing-masking-tape-bg {
        height: 49px;
        background-color: black;
        margin-top: 75px;
        padding: 0;
      }

      .invite-landing-masking-tape {
        height: 49px;
        background-image: url('/assets/img/inviteLanding/bg_masking_tape_mo.svg');
        background-position-y: 65%;
      }

      /* -------------------------------------------------------------------------- */
      /*                               section2-signup                              */
      /* -------------------------------------------------------------------------- */
      .invite-landing-signup-section {
        background-color: #fff;
        padding: 50px 0 68px;
      }

      .invite-landing-freeitem-title-container {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .invite-landing-freeitem-title {
        width: 289px;
        font-size: 30px;
        line-height: 37.8px;
        text-align: center;
      }

      .invite-landing-freeitem-sub {
        width: 258px;
        font-size: 14px;
        line-height: 17.64px;
        text-align: center;
        margin-top: 40px;
      }

      .invite-landing-freeitem-slot {
        margin-top: 44px;
        height: 300px;
        width: 265px;
      }

      .invite-landing-freeitem-slot-inner-container {
        width: 255px;
        height: 300px;
        margin: 0 4.5px;
        padding: 0;
        overflow: hidden;
      }

      .invite-landing-freeitem-slot-inner-container button {
        bottom: 13px;
        right: 12px;
        max-width: 85px;
        max-height: 30px;
        padding: 10px 19px;
        font-size: 8px;
      }

      .invite-landing-freeitem-slot-inner-container img {
        width: 255px !important;
      }

      /* -------------------------------------------------------------------------- */
      /*                               section3-ticket                              */
      /* -------------------------------------------------------------------------- */
      .invite-landing-ticket-section {
        padding: 50px 20px 90px;
      }

      .invite-landing-ticket-title {
        font-size: 30px;
        line-height: 37.8px;
        max-width: 322px;
      }

      .invite-landing-ticket-title span {
        display: none;
      }

      .invite-landing-ticket {
        margin-top: 88px;
        width: 250px;
      }

      /* -------------------------------------------------------------------------- */
      /*                               section4-howto                               */
      /* -------------------------------------------------------------------------- */
      .invite-landing-howto-section {
        padding: 50px 30px 87px;
      }

      .invite-landing-howto-title {
        font-size: 30px;
        line-height: 37.8px;
      }

      .invite-landing-howto-title span {
        display: block;
      }

      .invite-landing-image-wrapper,
      .invite-landing-howto-process-pc {
        display: none;
      }

      .invite-landing-howto-process-mo {
        width: 309px;
        display: block;
        margin: 50px auto;
      }

      /* -------------------------------------------------------------------------- */
      /*                              section5-paiditem                             */
      /* -------------------------------------------------------------------------- */
      .invite-landing-paiditem-section {
        padding: 50px 0px 42px;
      }

      .invite-landing-paid-title {
        font-size: 30px;
        line-height: 37.8px;
        max-width: 302px;
      }

      .invite-landing-paiditem-section img {
        display: block;
        margin: 0 auto;
      }

      .invite-landing-paiditem-section .invite-landing-image-wrapper,
      .invite-landing-paiditem-section .first-wrapper,
      .invite-landing-paiditem-section .invite-landing-paid-item-bk-pc {
        display: none;
      }

      .invite-landing-paiditem-section .invite-landing-paid-item-mo {
        margin-top: 51px;
        display: block;
        width: 360px;
      }

      /* -------------------------------------------------------------------------- */
      /*                               section6-review                              */
      /* -------------------------------------------------------------------------- */
      .invite-landing-review-section {
        padding: 50px 20px;
      }

      .invite-landing-review-title {
        font-size: 30px;
        line-height: 37.8px;
      }

      .invite-landing-review-section .invite-landing-image-wrapper,
      .invite-landing-review-pc {
        display: none;
      }

      .invite-landing-review-mo {
        display: block;
        margin: 39px auto 0;
        width: 320px;
      }

      /* -------------------------------------------------------------------------- */
      /*                               section7-enjoy                               */
      /* -------------------------------------------------------------------------- */
      .invite-landing-enjoy-section {
        padding: 50px 20px 238px;
      }

      .invite-landing-enjoy-title {
        font-size: 30px;
        font-weight: 500;
        line-height: 37.8px;
      }

      .invite-landing-enjoy-section .invite-landing-sinup-btn {
        margin-top: 50px;
      }

      .invite-landing-enjoy-bg.left {
        display: none;
      }

      .invite-landing-enjoy-bg.right {
        width: 461px;
        top: 245px;
        left: -80px;
      }

      .invite-landing-enjoy-sub-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 19.5px;
        margin-top: 77px;
      }
    }
  }
`;
