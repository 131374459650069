/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import { CHECKOUT_FREE } from '../../../route/constant';
import { createCheckoutFree } from '../../../action/request';
import { useNavigate } from 'react-router-dom';
import CartContext from '../../../context/cart.js/carts';

const DownloadButton = ({
  isOwn,
  price,
  isLogin,
  freeTicketCount,
  id,
  setStep,
  isLoading,
  secretProduct,
}) => {
  const { action: modalAction } = useContext(ModalContext);
  const { action: cartAction } = useContext(CartContext);

  const navigate = useNavigate();

  // const buydataClick = () => {
  //   if (!isLogin) {
  //     modalAction.setIsLogin(true);
  //   } else {
  //     navigate(`/checkout-step1?item=${id}`);
  //   }
  // };

  // const checkoutFree = async itemID => {
  //   try {
  //     const { data: res } = await createCheckoutFree(itemID);
  //     cartAction.checkoutFreeProduct(res.data);

  //     // console.log(res);
  //     navigate(CHECKOUT_FREE);
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };

  const clickDownload = () => {
    setStep(1);
  };

  // if (isOwn && isLogin) {
  //   return (
  //     <button onClick={clickDownload} className='btn btn-green btn-fill btn-full'>
  //       Download
  //     </button>
  //   );
  // }

  // if (price > 0) {
  //   return (
  //     <button
  //       className={
  //         'notranslate btn btn-green btn-fill btn-full btn-buy cart-buynow' +
  //         (isLogin ? '' : 'need-login')
  //       }
  //       onClick={buydataClick}
  //     >
  //       Buy Data Now
  //     </button>
  //   );
  // }

  // 이제부터는 free 만 해당함
  // if (!isLogin) {
  //   return (
  //     <button
  //       className={'notranslate btn btn-green btn-fill btn-full btn-buy cart-buynow need-login'}
  //       onClick={() => modalAction.setIsLogin(true)}
  //     >
  //       Get Model Now
  //     </button>
  //   );
  // }

  // TODO: 소지티켓이 필요티켓보다 적은 경우 (API 연결 후)
  // if (freeTicketCount <= 0) {
  //   return (
  //     <>
  //       <div style={{ fontSize: '12px', marginBottom: '11px' }}>
  //         `You have ${1} reward ticket. (Not enough.)`
  //       </div>
  //       <button
  //         className='notranslate btn btn-fill btn-full'
  //         disabled
  //         style={{ backgroundColor: '#D9D9D9', color: '#9e9e9e !important' }}
  //       >
  //         Free Download
  //       </button>
  //     </>
  //   );
  // }

  return (
    <>
      {/* 로그인 && 티켓 충분 상태 */}

      {!isLoading && (
        <button onClick={clickDownload} className='btn btn-green btn-fill btn-full'>
          Download
        </button>
      )}
    </>
  );
};

export default DownloadButton;
