import React, { useContext, useEffect, useRef, useState } from 'react';

import DesingersBoxs from './DesingersBoxs';
import { Header, HeaderBg, Img, ViewDetailsBtn } from '../designers';
import { getDesignerFollow, getDesignerInfoId, getDesignerUnFollow } from '../../../action/request';

import UserContext from '../../../context/user/user';
import ModalContext from '../../../context/modal/modal';
import { useNavigate } from 'react-router-dom';
import { followIdAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';

const DesignersHeader = ({
  data,
  isFollow,
  setIsFollow,
  id,
  DesignerSectionPropsStyle,
  NewFollowData,
  index,
  localDesignerData,
  designerSection,
  setFollowId,
  value,
}) => {
  const [isReadMore, setIsReadMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { action: modalAction } = useContext(ModalContext);
  const { state: userData } = useContext(UserContext);
  const isLogin = userData?.isLogin;
  const textRef = useRef(null);
  const studioId = Number(data?.id_studio);
  const [designerFollow, setDesignerFollow] = useState();
  const [designerId, setdesignerId] = useRecoilState(followIdAtom);
  // const [totalCount, setTotalCount] = useRecoilState(commentTotalCountAtom);
  const navigate = useNavigate();

  const onClickRead = () => {
    setIsReadMore(!isReadMore);
  };

  const onClickFollow = async (e, index) => {
    if (!userData.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      let res;
      if (e.target.id === 'follow') {
        res = await getDesignerFollow(studioId);
        if (res.data.returnCode === 'C00000') {
          setIsFollow(true);
          setFollowId && setFollowId({ id: studioId, isFollow: true });
          setdesignerId({ id: studioId, isFollow: true });
        }
      } else if (e.target.id === 'unFollow') {
        res = await getDesignerUnFollow(studioId);
        if (res.data.returnCode === 'C00000') {
          setIsFollow(false);
          setFollowId && setFollowId({ id: studioId, isFollow: false });
          setdesignerId({ id: studioId, isFollow: false });
        }
      }
    }

    fetchData(index);
  };

  const fetchData = async index => {
    const { data: designerData } = await getDesignerInfoId({ isLogin, studioId });
    setDesignerFollow(designerData.data.follow_count);
  };

  useEffect(() => {
    if (!userData?.isLogin) {
      setIsFollow(false);
    }
  }, [userData?.isLogin]);

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        const element = textRef.current;
        if (element.scrollHeight >= 75) {
          setIsOverflowing(true);
        } else {
          setIsOverflowing(false);
        }
      }
    };
    checkTextOverflow();
    window.addEventListener('resize', checkTextOverflow);

    return () => {
      window.removeEventListener('resize', checkTextOverflow);
      window.removeEventListener('load', checkTextOverflow);
    };
  }, []);

  return (
    <Header
      borderRadius={DesignerSectionPropsStyle?.borderRadius}
      headerPadding={DesignerSectionPropsStyle?.headerPadding}
      designerSection={designerSection}
    >
      {data && (
        <HeaderBg
          className='headerbg'
          padding={DesignerSectionPropsStyle?.padding}
          designerSection={DesignerSectionPropsStyle?.designerSection}
        >
          {DesignerSectionPropsStyle?.viewDesignerList ? (
            <></>
          ) : (
            <button className='viewDesignerList' onClick={() => navigate('/designers')}>
              View Designer List
            </button>
          )}
          <div className='flex'>
            <div className='profileWrap'>
              <Img src={data?.logo} width={110} right={20} radius={100} className='profile' />
              <div className='desinerInfo'>
                <div className='designerName'>
                  <p className='name'>
                    {data?.name}{' '}
                    <Img alt='badge' src='/assets/img/designers/designer_badge.png' width={22} />
                  </p>
                </div>
                <div className='btnWrap'>
                  <button
                    onClick={e => onClickFollow(e, index)}
                    id={isFollow ? 'unFollow' : 'follow'}
                  >
                    <Img
                      src={`/assets/img/designers/${isFollow ? 'unfollow' : 'follow_button'}.png`}
                      width={120}
                      height={43}
                      right={10}
                    />
                  </button>
                  {/* TODO:디자이너 모델 상세 페이지의 디자이너 이름 부분*/}
                  {designerSection ? (
                    <ViewDetailsBtn
                      onClick={() =>
                        navigate(`/designers/${data?.name.replace(/\s+/g, '')}`, {
                          state: { location: 'fromDesignerSection' },
                        })
                      }
                    >
                      <img src={`/assets/img/designers/view_detail_icon.png`} />
                      <span>View Details</span>
                    </ViewDetailsBtn>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className='countBox'>
              <DesingersBoxs data={data} designerFollow={designerFollow} value={value} />
            </div>
          </div>
          {DesignerSectionPropsStyle?.descriptionNone ? (
            <></>
          ) : (
            <div className='description'>
              <span className={isReadMore ? '' : 'ellipsis'} ref={textRef}>
                {data?.desc_en}
              </span>
              {isOverflowing && (
                <button className='readMore' onClick={() => onClickRead()}>
                  {!isReadMore ? 'Read more' : 'Read less'}
                </button>
              )}
            </div>
          )}
        </HeaderBg>
      )}
    </Header>
  );
};

export default DesignersHeader;
