import { useContext } from 'react';
import CardSwiper from './CardSwiper/CardSwiper';
import UserContext from '../context/user/user';
import Layout from '../component/layout/Layout';
import WelcomeModal from '../component/modal/WelcomeModal';

const Test = () => {
  // console.log(tst.state);
  return (
    <Layout>
      <WelcomeModal />
    </Layout>
  );
};

export default Test;
