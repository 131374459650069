import React from 'react';
import styled from 'styled-components';
import { DEFAULT_AVATAR } from '../../avatar/const';
import DiscountPrice from '../../section/DiscountPrice';
import { calculateDiscountPercentage } from '../../section/util';
import { theme } from '../../../styles/theme';
import Skeleton from 'react-loading-skeleton';
import { ITEMS } from '../../../route/constant';
import { Link, useNavigate } from 'react-router-dom';

const ItemCard = ({ width, height, isSkeletonLoading, data, clickCart, clickLike }) => {
  const SKELETON_PADDINGTOP = '100%';
  const navigate = useNavigate();

  const goToItemDetailPage = (e, productId) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(ITEMS + '/' + productId);
  };

  return (
    <div className='shopItemCard' id='ItemCard'>
      {!isSkeletonLoading ? (
        <div className='imgWrap'>
          <div className='hover'>
            <div className='designerImg'>
              {!data.reward && (
                <img className='designerImg2' src={data?.designer_img || DEFAULT_AVATAR} alt='' />
              )}
            </div>
            <button
              tabIndex='0'
              type='button'
              className={`buttonFavorite ${data?.my_like && 'active'}`}
              title='add this item to the favorite list'
              onClick={() => clickLike(data.product_id)}
              style={{ bottom: !data?.reward ? '70px' : '20px' }}
            />
            {!data.reward && (
              <button
                tabIndex='0'
                type='button'
                className={`buttonCart ${data.in_cart && 'active'}`}
                title='app this item to the cart'
                onClick={() => clickCart(data.finalPrice, data.product_id, data.in_cart)}
              />
            )}
            <Img width={width} height={height}>
              <img src={data.product_listImg2} className='itemImg1 over' alt='' />
            </Img>
          </div>

          <Link
            to='#'
            className='itemLinkPart1'
            title='item detail'
            onClick={e => goToItemDetailPage(e, data.product_id)}
          >
            <div className='normal'>
              {/* 독점 표시*/}
              {data.exclusive && !data.reward && (
                <div className='itemLabel exclusive'>Exclusive</div>
              )}
              {data.reward && (
                <>
                  <div className='itemLabel reward'>Reward</div>
                  <div className='ticket'>
                    <div className='hiddenTxt'>Ticket</div>
                    <div className='amount'>{data.reward_qty}</div>
                  </div>
                </>
              )}
            </div>
            <Img width={width} height={height}>
              <img src={data.product_listImg} className='itemImg1' alt='' />
            </Img>
          </Link>
        </div>
      ) : (
        <Skeleton
          style={{
            paddingTop: SKELETON_PADDINGTOP,
            height: 0,
            borderRadius: '8px',
          }}
          baseColor={theme.skeleton_gray}
          highlightColor={theme.skeleton_light_gray}
        />
      )}

      <Link
        href='#'
        className='itemLinkPart2'
        title='item detail'
        // onClick={e => goTo(e, ITEMS + '/' + data.product_id)}
        onClick={e => goToItemDetailPage(e, data.product_id)}
      >
        {!isSkeletonLoading ? (
          <div className='title'>{!data.reward ? data.studio_name : 'FAB365'}</div>
        ) : (
          <Skeleton
            className='skeleton--title'
            width={160}
            baseColor={theme.skeleton_dark_gray}
            highlightColor={theme.sleleton_dark_gray_highlight}
          />
        )}
        {!isSkeletonLoading ? (
          <div className='subtitle'>{data.product_name}</div>
        ) : (
          <Skeleton
            className='skeleton--subtitle'
            width={200}
            baseColor={theme.skeleton_dark_gray}
            highlightColor={theme.sleleton_dark_gray_highlight}
          />
        )}
        <div className='priceRow'>
          {!isSkeletonLoading ? (
            <>
              <div className='price'>
                {/* {!data.reward ? `$ $` : 'Free'} */}
                {!data.reward ? (
                  <DiscountPrice
                    finalPrice={data.finalPrice}
                    originPrice={data.originPrice}
                    discountRate={data.discountRate}
                  />
                ) : (
                  'Free'
                )}
              </div>
              {/* discount 값이 있을 때만 표시 */}
              {data?.isDiscount && (
                <>
                  <div className='originalPrice'>$ {data.originPrice}</div>
                  <div className='discount'>
                    {calculateDiscountPercentage(data.originPrice, data.finalPrice)}%
                  </div>
                </>
              )}
            </>
          ) : (
            <Skeleton
              className='price'
              width={120}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />
          )}
        </div>

        {!isSkeletonLoading ? (
          <div className='etc'>
            <button tabIndex='0' type='button' className='favorites'>
              <span className='hiddenTxt'>favorites</span>
              {data.product_like_count || 0}
            </button>
            <button tabIndex='0' type='button' className='comments'>
              <span className='hiddenTxt'>comments</span>
              {data.product_comment_count || 0}
            </button>
            <button tabIndex='0' type='button' className='pictures'>
              <span className='hiddenTxt'>pictures</span>
              {data.review_count || 0}
            </button>
          </div>
        ) : (
          <Skeleton
            className='skeleton--etc'
            width={200}
            height={11}
            baseColor={theme.skeleton_dark_gray}
            highlightColor={theme.sleleton_dark_gray_highlight}
          />
        )}
      </Link>
    </div>
  );
};

export default ItemCard;

const Img = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 8px;
  background-color: gray;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;
