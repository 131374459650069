/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from 'react-router-dom';
import Price from '../../../component/Price/Price';
import { ITEMS } from '../../../route/constant';
import { ImgStyled } from '../../../styles/ImgStyled';

const ItemList = ({ data, limit }) => {
  const navigate = useNavigate();
  return (
    <>
      <ul className='aspectRatio'>
        {data?.map((d, idx) => {
          return (
            <li key={d.product_id}>
              <div className='product-list-item'>
                <div className='aspectRatio'>
                  <Link
                    to={ITEMS + '/' + d.product_id}
                    className='tb'
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(ITEMS + '/' + d.product_id);
                    }}
                  >
                    <ImgStyled src={d.product_listImg} alt={d.product_name} />
                    <ImgStyled src={d.product_listImg2} alt={d.product_name} className='over' />
                  </Link>
                </div>

                <div className='product-desc category-product-desc'>
                  <h3 className='product-name'>{d.product_name}</h3>
                  <p className='product-category'>{d.product_shortDesc}</p>
                  {Price(d, limit)}
                  <span className='post-stat'>
                    <span className='hearted'>{d.product_like_count}</span>
                    <span className='comment'>{d.product_comment_count}</span>
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ItemList;
