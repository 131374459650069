/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import MainSlide from './components/MainSlide';
import FreeitemSlide from './components/FreeitemSlide';
import { useContext } from 'react';
import ModalContext from '../../context/modal/modal';
import Layout from '../../component/layout/Layout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Ss, ls } from '../../constant';
import styled from 'styled-components';

function Landing() {
  const innerWidth = window.innerWidth <= 640;

  const [isMobile, setIsMobile] = useState(window.innerWidth);

  const { action } = useContext(ModalContext);
  const location = useLocation();
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  // 세션스토리지에 저장
  // signupPage 제외하고는 세션스토리지 비우기
  const getInviteToken = () => {
    const urlParams = new URLSearchParams(location.search);
    const inviteToken = urlParams.get('t');
    if (inviteToken !== undefined) {
      sessionStorage.setItem(Ss.inviteToken, inviteToken);
    }
  };

  useEffect(() => {
    if (isLoginUsingToken) {
      navigate('/');
    } else {
      getInviteToken();
    }
  }, []);

  useEffect(() => {
    // window.addEventListener('resize', () => {setIsMobile(window.innerWidth <= 640)});
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 640);
    });

    return () => {
      window.removeEventListener('resize', () => setIsMobile(window.innerWidth <= 640));
    };
  }, [isMobile]);

  const onClickSignUp = () => {
    action.setIsSignup(true);
  };

  // 소셜미디어 공유
  const link = window.location.href;

  // Facebook 공유
  const shareFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`);
  };

  // Twitter 공유
  const shareTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${link}`);
  };

  //  링크 복사
  const shareUrl = () => {
    let textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = link;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    alert('The invitation link has been copied. ');
  };

  return (
    <Layout>
      {isMobile && innerWidth ? (
        <LandingMobile>
          <div class='invite-landing-container_m mobile'>
            <section class='main_benner_m'>
              <LandingImg
                src='assets/img/inviteLanding/renewal/mo/main_benner_mo.png'
                alt=''
                className='img'
              />
            </section>
            <section class='five_model_m'>
              <LandingImg src='assets/img/inviteLanding/renewal/mo/main01_mo.png' alt='' />
              <button onClick={onClickSignUp} id='DOWNLOAD_BTN'>
                <img
                  class='five_model_m_btn'
                  src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/landing/renewal/mo/main01_Btn_mo.png'
                  alt=''
                />
              </button>
            </section>
            <section class='just_take_m'>
              <LandingImg
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/landing/renewal/mo/main02_mo.png'
                alt=''
              />
              <button onClick={onClickSignUp} id='SIGNUP_BTN'>
                <img
                  class='just_take_m_btn'
                  src='assets/img/inviteLanding/renewal/mo/main02_btn_mo.png'
                  alt=''
                />
              </button>
            </section>
            <section class='just_so_m'>
              <LandingImg src='assets/img/inviteLanding/renewal/mo/main03_mo.png' alt='' />
              <button onClick={onClickSignUp} id='SIGNUP_BTN'>
                <img
                  class='just_so_m_btn'
                  src='assets/img/inviteLanding/renewal/mo/main03_btn_mo.png'
                  alt=''
                />
              </button>
            </section>
            <section class='check_out_m'>
              <LandingImg
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/landing/renewal/mo/main04_mo.png'
                alt=''
              />
            </section>
            <section class='sign_up_m'>
              <LandingImg
                src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/landing/renewal/mo/main05_mo.png'
                alt=''
              />
              <button onClick={onClickSignUp} id='SIGNUP_BTN'>
                <img
                  class='sign_up_m_btn'
                  src='assets/img/inviteLanding/renewal/mo/main05_btn_mo.png'
                  alt=''
                />
              </button>
              <section class='invite-landing-social-container_mo'>
                <Link class='invite-landing-social-facebook' onClick={shareFacebook}>
                  <img src='assets/img/inviteLanding/renewal/facebook.png' alt='' />
                </Link>
                <Link class='invite-landing-social-x' onClick={shareTwitter}>
                  <img src='assets/img/inviteLanding/renewal/X.png' alt='' />
                </Link>
                <Link class='invite-landing-social-link' onClick={shareUrl}>
                  <img src='assets/img/inviteLanding/renewal/link.png' alt='' />
                </Link>
              </section>
            </section>
          </div>
        </LandingMobile>
      ) : (
        <LandingPc>
          <div id='invite-landing' class='invite-landing-container'>
            <section class='benner-img-wrapper'>
              <LandingImg src='assets/img/inviteLanding/renewal/pc/benner.png' alt='' />
            </section>
            <section class='five-model section'>
              <LandingImg src='assets/img/inviteLanding/renewal/pc/main01_pc.png' alt='' />
              <div class='five-model-img-wrapper'>
                <button onClick={onClickSignUp} id='DOWNLOAD_BTN'>
                  <img src='assets/img/inviteLanding/renewal/pc/main01_pc_btn.png' alt='' />
                </button>
              </div>
            </section>

            <section class='just-take section'>
              <LandingImg src='assets/img/inviteLanding/renewal/pc/main02_pc.png' alt='' />
              <div class='just-take-img-wrapper'>
                <button onClick={onClickSignUp} id='SIGNUP_BTN'>
                  <img src='assets/img/inviteLanding/renewal/pc/main02_pc_btn.png' alt='' />
                </button>
              </div>
            </section>

            <section class='check-out section'>
              <LandingImg src='assets/img/inviteLanding/renewal/pc/main03_pc.png' alt='' />
              <div class='check-out-wrapper'>
                <button onClick={onClickSignUp} id='SIGNUP_BTN'>
                  <img src='assets/img/inviteLanding/renewal/pc/main03_pc_btn.png' alt='' />
                </button>
              </div>
            </section>

            <section class='sign-up-now section'>
              <div>
                <LandingImg src='assets/img/inviteLanding/renewal/pc/main04_pc.png' alt='' />
              </div>
            </section>

            <section class='share-section'>
              <div>
                <LandingImg src='assets/img/inviteLanding/renewal/pc/main05_pc.png' alt='' />
                <div class='share-content-wrapper'>
                  <div class='share-img-wrapper'>
                    <button onClick={onClickSignUp} id='SIGNUP_BTN'>
                      <img src='assets/img/inviteLanding/renewal/pc/main05_pc_btn.png' alt='' />
                    </button>
                  </div>

                  <section class='invite-landing-social-container'>
                    <article>
                      <Link class='invite-landing-social-facebook' onClick={shareFacebook}>
                        <img src='assets/img/inviteLanding/renewal/facebook.png' />
                      </Link>
                      <Link class='invite-landing-social-x' onClick={shareTwitter}>
                        <img src='assets/img/inviteLanding/renewal/X.png' />
                      </Link>
                      <Link class='invite-landing-social-link' onClick={shareUrl}>
                        <img src='assets/img/inviteLanding/renewal/link.png' />
                      </Link>
                    </article>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </LandingPc>
      )}
    </Layout>
  );
}

export default Landing;

const LandingPc = styled.div`
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    vertical-align: top;
  }

  section img {
    width: 100%;
  }

  a {
    cursor: pointer;
  }

  section.five-model,
  section.just-take,
  section.check-out,
  section .share-section {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .five-model .five-model-img-wrapper {
    position: absolute;
    bottom: 15%;
    width: 30%;
  }

  .just-take .just-take-img-wrapper {
    position: absolute;
    bottom: 15%;
    width: 30%;
  }

  .check-out .check-out-wrapper {
    position: absolute;
    bottom: 15%;
    width: 30%;
  }

  .share-section {
    position: relative;
  }

  .share-section .share-img-wrapper {
    position: absolute;
    width: 17%;
    left: 60%;
    bottom: 46%;
  }

  .invite-landing-social-container {
    position: absolute;
    top: 68%;
    width: 100%;
  }

  .invite-landing-social-container article {
    width: 9%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 27%;
  }

  .invite-landing-social-container a {
    margin: 2%;
  }

  .invite-landing-social-container img {
    width: 100%;
  }
`;

const LandingMobile = styled.div`
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    vertical-align: top;
  }

  section img {
    width: 100%;
  }

  a {
    cursor: pointer;
  }

  section {
    position: relative;
  }

  .mobile {
    display: block;
  }

  .five_model_m_btn,
  .just_take_m_btn {
    position: absolute;
    width: 90%;
    top: 84%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .just_so_m_btn {
    position: absolute;
    width: 90%;
    top: 91%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .sign_up_m_btn {
    position: absolute;
    width: 90%;
    top: 64%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .invite-landing-social-container_mo {
    display: flex;
    position: absolute;
    transform: translate3d(32%, -180%, 0px);
    justify-content: center;
    width: 60%;
  }

  .invite-landing-social-container_mo a {
    margin: 2px;
  }
`;

const LandingImg = styled.img`
  cursor: auto !important;
`;
