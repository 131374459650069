import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import { MY_CREDIT } from '../../../route/constant';
import { useNavigate } from 'react-router-dom';
import { ImgStyled } from '../../../styles/ImgStyled';

const ItemBanner = ({ price, isLogin }) => {
  const { action } = useContext(ModalContext);
  const navigate = useNavigate();

  const clickMyPointBanner = () => {
    if (!isLogin) {
      action.setIsLogin(true);
    } else {
      navigate(MY_CREDIT);
    }
  };

  return (
    <>
      <div style={{ marginTop: '1rem' }}>
        {/* <a href='https://www.patreon.com/goodesign/posts' target='blank'>
          <img alt='' src='/assets/img/banner/banner-review-else.jpg' style={{ width: '100%' }} />
        </a> */}
      </div>
      {price > 0 && (
        <div style={{ marginTop: '1rem' }}>
          <div onClick={clickMyPointBanner} className={isLogin ? '' : 'need-login'}>
            <ImgStyled
              src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/point/credits.jpg'
              style={{ width: '100%' }}
              alt=''
            />
          </div>
        </div>
      )}
      {price <= 0 && (
        <>
          <div
            style={{ marginTop: '1rem', cursor: 'pointer' }}
            onClick={() => {
              action.setIsSignup(true);
            }}
          >
            <ImgStyled
              alt=''
              src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/free/five_free.jpg'
              style={{ width: '100%' }}
            />
          </div>
          <div
            style={{ marginTop: '1rem', cursor: 'pointer' }}
            onClick={() => {
              !isLogin ? action.setIsSignup(true) : navigate('/invite');
            }}
          >
            <ImgStyled
              alt=''
              src='/assets/img/banner/inviting_friend_subbanner.png'
              style={{ width: '100%' }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ItemBanner;

// height: 100%;
// display: inline-block;
