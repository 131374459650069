import React, { useContext, useEffect, useState } from 'react';
import { fillArray } from './util';
import { useNavigate } from 'react-router-dom';
import { CATEGORIES } from '../../route/constant';
import { createCartAdd, getHomeInfoDetail, toggleItemLike } from '../../action/request';
import { Swiper, SwiperSlide } from 'swiper/react';
import { gtagAddToCart } from '../../action/gTag';
import UserContext from '../../context/user/user';
import ModalContext from '../../context/modal/modal';
import CartContext from '../../context/cart.js/carts';
import { ls } from '../../constant';
import { replicateObject } from '../../util/skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { emptyCard } from './constant';

import SectionHeader from '../common/sectionHeader/SectionHeader';
import ItemCardMain from '../common/itemCards/ItemCardMain';
import { useRecoilState } from 'recoil';
import { likeAtom } from '../../atom/atom';
import { updateLikeState } from '../../util/updateLikeState';

function SectionC({ data, isMobile }) {
  const emptyCards = replicateObject(emptyCard, 8).map(card => {
    return { ...card, product_id: 'null' };
  });
  const [cards, setCards] = useState(emptyCards);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const [likeState, setLikeSatate] = useRecoilState(likeAtom);

  const navigate = useNavigate();

  const SECTIONC_LENGTH = 8;

  const options = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 25,
    loop: true,

    breakpoints: {
      440: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2,
      },
      1180: {
        slidesPerView: 3,
      },
    },
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSkeletonLoading) return;

    if (path === 'viewAll') {
      // view all 버튼을 누르면 categories > all 모델 목록의 single items, popular 정렬 페이지로 이동을 위한 state값 설정

      navigate(CATEGORIES, { state: 'popular' });
    } else {
      navigate(path);
    }
  };

  const fetchData = async () => {
    try {
      const { data: detailData } = await getHomeInfoDetail(data.location, userState.isLogin);
      if (detailData.data.items.length > SECTIONC_LENGTH) {
        setCards(detailData.data.items.slice(0, SECTIONC_LENGTH));
      } else {
        setCards(detailData.data.items);
      }
      setTimeout(() => {
        setIsSkeletonLoading(false);
      }, 500);
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, [userState?.isLogin]);

  useEffect(() => {
    if (likeState) {
      const newCards = cards.map(card =>
        card.product_id === likeState.id
          ? {
              ...card,
              product_like_count: `${Number(likeState.count)}`,
              my_like: likeState.value,
            }
          : card
      );

      setCards(newCards);
    }
  }, [likeState]);

  const clickLike = async (id, count) => {
    if (!userState.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeSatate,
          cards: cards,
          count: count,
          data: data,
        });
        setCards(updatedCards);
      }
    }
  };

  const clickCart = async (price, id, inCart) => {
    // 이미 카트에 담겨있을 경우
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = cards.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setCards(newCards);

          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  return (
    <div className='sectionTypeC'>
      <div className='centerWrap'>
        {data && (
          <SectionHeader
            isSkeletonLoading={isSkeletonLoading}
            data={data}
            isMobile={isMobile}
            goTo={goTo}
            sectionValue={'C'}
          />
        )}

        {/* Desktop */}
        <div className='col4List'>
          {fillArray([...cards], { product_id: false }, SECTIONC_LENGTH).map((card, idx) => {
            return card.product_id ? (
              <ItemCardMain
                key={idx}
                idx={idx}
                data={card}
                isSkeletonLoading={isSkeletonLoading}
                goTo={goTo}
                clickCart={clickCart}
                clickLike={clickLike}
                ranking={data?.ranking}
                skeleton={isMobile ? 200 : 800}
              />
            ) : (
              <div className='shopItemCard comingSoon' key={idx}>
                <div className='imgWrap'>
                  <img
                    src='/assets/img/2.0WebImg/content/coming_soon.jpg'
                    className='itemImg1 comming_soon downloadNone'
                    alt='FAB365 COMING SOON'
                  />
                </div>
              </div>
            );
          })}
        </div>

        {/* Mobile */}
        <Swiper {...options} className='sectionASwiper sectionCSwiper sectionASwiperC001'>
          <div className='swiper-wrapper'>
            {cards.map((card, idx) => {
              return (
                card.product_id && (
                  <SwiperSlide key={idx}>
                    <div className='swiper-slide'>
                      <ItemCardMain
                        idx={idx}
                        data={card}
                        isSkeletonLoading={isSkeletonLoading}
                        goTo={goTo}
                        clickCart={clickCart}
                        clickLike={clickLike}
                        ranking={data?.ranking}
                      />
                    </div>
                  </SwiperSlide>
                )
              );
            })}
          </div>
        </Swiper>
      </div>
    </div>
  );
}

export default SectionC;
