export const fileSize = (size) => {
  const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  if (!size)
    return {
      original: 0,
      unit: "B",
      size: 0,
    };

  const factor = Math.floor((size.length - 1) / 3);
  return {
    original: size,
    unit: units[factor],
    size: size / Math.pow(1024, factor),
  };
};
// static function convert($size, $pointLen = 1){
//     $sizeDic  = array('B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB');
//     $factor = floor((strlen($size) - 1) / 3);

//     $result = array(sprintf("%.{$pointLen}f", $size / pow(1024, $factor)), @$sizeDic[$factor]);

//     return [
//         "original" => $size,
//         "size" => $result[0],
//         "unit" => $result[1],
//     ];
// }
