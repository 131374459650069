const ReviewTag = ({ tags }) => {
  return (
    <>
      <div className='form-group form-group-h'>
        <label className='form-label'>Tags</label>
        <div className='form-control'>
          <input
            type='text'
            className='form-input'
            placeholder='Input your tags'
            name='tags'
            value={tags?.join(',')}
          />
        </div>
      </div>
    </>
  );
};

export default ReviewTag;
