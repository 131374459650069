import React from 'react';
import { SocialButton } from '../styles';
import { useLocation } from 'react-router-dom';
import { HOSTNAME } from '../../../action/request';

function Google() {
  const location = useLocation();
  const currentPath = location.pathname;
  const AWS_URL = '43.203.118.174';
  const FAB365_URL = 'fab365.net';
  const FAB365_URL_MOCK = 'java.fab365.net';
  const FAB365_DEV_URL = 'dev.fab365.net';
  const FAB365_DEV_URL_MOCK = 'dev-java.fab365.net';
  const FAB365_DEV_URL_MOCK_SECOND = 'dev2.fab365.net';

  let redirectBaseUrl;

  if (HOSTNAME === FAB365_URL) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL;
  } else if (HOSTNAME === FAB365_URL_MOCK) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL_MOCK;
  } else if (HOSTNAME === FAB365_DEV_URL) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL;
  } else if (HOSTNAME === FAB365_DEV_URL_MOCK) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK;
  } else if (HOSTNAME === FAB365_DEV_URL_MOCK_SECOND) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK_SECOND;
  }

  // console.log(redirectBaseUrl);
  const config = {
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    redirect_uri: redirectBaseUrl,
    access_type: 'offline',
    response_type: 'code',
    scope: 'email profile',
  };
  const queryString = Object.entries(config)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const onClickGoogle = async e => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;
    // 로그인할 때 현재 위치 로컬스토리지에 저장
    localStorage.setItem('loginPath', currentPath);
  };

  // console.log('env', process.env.REACT_APP_TEST_ENV);

  return (
    <SocialButton className='btn btn-full social-button'>
      <img src='/assets/img/login/google_new.png' alt='Google' onClick={onClickGoogle} />
    </SocialButton>
  );
}

export default Google;
