/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import { useNavigate } from 'react-router-dom';

const BuyDataNowButton = ({ isLogin, id, isLoading, showProduct }) => {
  const { action: modalAction } = useContext(ModalContext);

  const navigate = useNavigate();

  const buydataClick = () => {
    if (!isLogin) {
      modalAction.setIsLogin(true);
    } else {
      navigate(`/checkout-step1?item=${id}`);
    }
  };

  // if (price > 0) {
  //   return (
  //     <button
  //       className={
  //         'notranslate btn btn-green btn-fill btn-full btn-buy cart-buynow' +
  //         (isLogin ? '' : 'need-login')
  //       }
  //       onClick={buydataClick}
  //     >
  //       Buy Data Now
  //     </button>
  //   );
  // }

  return (
    <>
      {showProduct === 'secret' ? (
        <button
          className={
            'notranslate btn btn-gray btn-fill btn-full btn-buy cart-buynow' +
            (isLogin ? '' : 'need-login')
          }
          onClick={buydataClick}
          disabled
        >
          {!isLoading && 'No longer for sale'}
        </button>
      ) : (
        <button
          className={
            'notranslate btn btn-green btn-fill btn-full btn-buy cart-buynow' +
            (isLogin ? '' : 'need-login')
          }
          onClick={buydataClick}
        >
          {!isLoading && 'Buy Data Now'}
        </button>
      )}
    </>
  );
};

export default BuyDataNowButton;
