import styled, { css } from 'styled-components';
import { numberFormat } from '../../../util/number';
import { useContext, useEffect, useState } from 'react';
import SalesMonthly from '../../../page/My/Sales/component/SalesMonthly';
import BalanceHistory from '../../../page/My/Sales/component/BalanceHistory';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {
  emailRequestWithdrawal,
  getBalanceHistory,
  getSalse,
  requestWithdrawal,
} from '../../../action/request';
import { Link, useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';
import UserContext from '../../../context/user/user';
import { formatDesignerNumber } from '../../../page/My/Sales/utils';
import MySalesProducts from './MySalesProducts';
import { useRecoilState } from 'recoil';
import { seletecdMoreAtom, seletecdMonthAtom, seletecdYearAtom } from '../../../atom/atom';
import SalesProductDetail from '../../../page/My/Sales/SalesProductDetail';
import SalesDaily from '../../../page/My/Sales/SalesDaily';

const MySales = ({ moveScroll }) => {
  //Monthly Earnings option: years
  const [years, setYears] = useState([]);
  const [selectYear, setSelectYear] = useRecoilState(seletecdYearAtom);

  const [histories, setHistories] = useState([]);
  const [selectedHistories, setSelectedHistories] = useState([]);
  const [status, setStatus] = useState('all');
  const [selectedDate, setSelectDate] = useState([
    moment().subtract(7, 'day').format('YYYY-MM-DD'),
    moment().subtract(0, 'd').format('YYYY-MM-DD'),
  ]);

  const [step, setStep] = useState(0);
  const [balance, setBalance] = useState(0);
  const [requestCount, setRequestCount] = useState([]);
  const [thismonthDownload, setThismonthDownload] = useState(0);
  const [thisMonthRevenue, setThisMonthRevenue] = useState(0);
  const [totalDownload, setTotalDownload] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [productCount, setProductCount] = useState(0);

  const [style, setStyle] = useState({ display: 'none' });
  const [regexStyle, setRegexStyle] = useState({ visibility: 'hidden' });
  const [amountStyle, setAmountStyle] = useState({ visibility: 'hidden' });
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [withdrawalBalance, setWithdrawalBalance] = useState(balance);
  const [inputNum, setInputNum] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [seletecdMore, setSelectedMore] = useRecoilState(seletecdMoreAtom);
  const [seletecdMonth, setSelectedMonth] = useRecoilState(seletecdMonthAtom);
  const [compeletedRequest, setCompeletedRequest] = useState(false);
  const { state } = useContext(UserContext);
  const userName = state.name;
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchBalanceHistory();
  }, [selectedDate, compeletedRequest]);

  const onChangeYear = e => {
    setSelectYear(e.target.value);
  };

  const onChangeStatus = e => {
    setStatus(e.target.value);
    queryHistories(histories, e.target.value);
  };

  const fetchData = async () => {
    try {
      const { data: res } = await getSalse();

      if (res) {
        setBalance(res.data.balance);
        setRequestCount(res.data.requestCount);
        setThismonthDownload(res.data.thisDownload);
        setThisMonthRevenue(res.data.thisRevenue);
        setTotalDownload(res.data.totalDownload);
        setTotalRevenue(res.data.totalRevenue);
        setProductCount(res.data.productsCount);
        // console.log('res', res);
      }
    } catch (e) {
      alert(e);
    }
  };

  const fetchBalanceHistory = async () => {
    try {
      const { data: res } = await getBalanceHistory(selectedDate[0], selectedDate[1]);
      // const { data: res } = await getBalanceHistory(endDate);
      const request = res.data.request;
      const result = res.data.result;
      const h = [...request, ...result];

      h.sort((l, r) => {
        return l['date'] > r['date'] ? 1 : -1;
      });
      for (let i = 0; i < h.length; i++) {
        if (i === 0) {
          h[i].balance = parseFloat(h[i].earning);
        } else {
          if (h[i].details === 'paid') {
            h[i].balance = h[i - 1].balance - parseFloat(h[i].amounts);
          } else if (h[i].details === 'request') {
            h[i].balance = h[i - 1].balance - parseFloat(h[i].amounts);
          } else {
            h[i].balance = h[i - 1].balance + parseFloat(h[i].earning);
          }
        }
      }
      setHistories(h);
      setSelectedHistories(h);
      setCompeletedRequest(false);
    } catch (e) {
      alert(e);
    }
  };

  //Monthly Earnings에 년도 option
  useEffect(() => {
    const y = [];
    const thisYear = parseInt(new Date().getFullYear());
    for (let i = thisYear; i > 2020; i--) {
      y.push(i);
    }
    setYears(y);
  }, []);

  const queryHistories = (h, status) => {
    const result = [];

    if (status === 'all') {
      setSelectedHistories(histories);
    } else {
      for (let i = 0; i < h.length; i++) {
        if (status === 'withdrawal') {
          if (h[i].details) {
            result.push(h[i]);
          }
        } else {
          if (!h[i].details) {
            result.push(h[i]);
          }
        }
      }
    }

    if (status !== 'all') {
      setSelectedHistories(result.reverse());
    }
  };

  // 정산모달 > 정산 요청 버튼 클릭시 데이터 초기화
  const withdrawalBtn = () => {
    setInputNum('');
    setInputEmail('');
    setWithdrawalBalance(balance);
    setBtnDisabled(true);
    setRegexStyle({ visibility: 'hidden' });
    setStep(1);
    setStyle({ display: 'none' });
  };

  // 정산모달 > 정산요청 금액이 원래 금액보다 높으면 안내문구 표시
  useEffect(() => {
    if (withdrawalBalance < 0) {
      setAmountStyle({ visibility: 'visible' });
    } else {
      setAmountStyle({ visibility: 'hidden' });
    }
  }, [withdrawalBalance]);

  // 정산모달 > 정산 요청 금액 변화 감지
  const changeAmount = e => {
    if (style.display === 'none') {
      setStyle({ display: 'block' });
    }
    if (e.target.value >= 0) {
      setInputNum(e.target.value);
      setWithdrawalBalance(balance - e.target.value);
    }
  };

  // 정산모달 > 이메일 체인지 감지
  const changeEmail = e => {
    setInputEmail(e.target.value);
  };

  // 정산모달 > 이메일 유효성 검사
  useEffect(() => {
    const checkEmail = () => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (inputEmail === '') {
        setRegexStyle({ visibility: 'hidden' });
      } else if (emailRegex.test(inputEmail)) {
        setRegexStyle({ visibility: 'hidden' });
      } else {
        setRegexStyle({ visibility: 'visible' });
      }
    };
    checkEmail();
  }, [inputEmail]);

  // 정산모달 > 정산 버튼 활성화
  useEffect(() => {
    if (
      inputNum > 0 &&
      withdrawalBalance > 0 &&
      inputEmail !== '' &&
      regexStyle.visibility === 'hidden'
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [inputNum, withdrawalBalance, inputEmail, regexStyle]);

  const tryRequestWithdrawal = async () => {
    try {
      const RequestWithdrawal = await requestWithdrawal({
        paypal: inputEmail,
        amount: inputNum,
      });
      return RequestWithdrawal;
      // console.log('RequestWithdrawal', RequestWithdrawal);
    } catch (e) {
      alert(e);
    }
  };

  const tryEmailRequestWithdrawal = async () => {
    try {
      const EmailRequestWithdrawalRes = await emailRequestWithdrawal({
        paypal: inputEmail,
        username: userName,
        amount: inputNum,
      });
      return EmailRequestWithdrawalRes;
      // console.log('EmailRequestWithdrawalRes', EmailRequestWithdrawalRes);
    } catch (e) {
      alert(e);
    }
  };

  // const withdrawalConfirm = async () => {
  //   try {
  //     const [withdrawalResponse, emailResponse] = await Promise.all([
  //       tryRequestWithdrawal(),
  //       tryEmailRequestWithdrawal(),
  //     ]);

  //     //중복 정산 요청 방지
  //     if (
  //       //정산요정 . 기 정산요청이 아직 진행중일 때 새 정산요청을 받지 않습니다.
  //       withdrawalResponse.data.returnCode === 'C00008' ||
  //       //이메일 요청 메일발송은 하루 이내에 동일계정/동일 가격의 메일을 중복메일로 판정하고  거부합니다.
  //       emailResponse.data.returnCode === 'C00008'
  //     ) {
  //       alert(
  //         'There is already an ongoing withdrawal request. You cannot make a new withdrawal request until the existing one is completed.'
  //       );
  //     } else {
  //       setStep(3);
  //     }
  //     fetchData();
  //   } catch (e) {
  //     alert(e);
  //   }
  // };

  const withdrawalConfirm = async () => {
    try {
      const withdrawalResponse = await tryRequestWithdrawal();

      //  정산요청이 아직 진행중일 때 새 정산요청을 받지 않습니다.
      if (withdrawalResponse.data.returnCode === 'C00008') {
        alert(
          'There is already an ongoing withdrawal request. You cannot make a new withdrawal request until the existing one is completed.'
        );
        return;
      }

      //이메일 요청 메일발송은 하루 이내에 동일계정/동일 가격의 메일을 중복메일로 판정하고  거부합니다.
      const emailResponse = await tryEmailRequestWithdrawal();
      if (emailResponse.data.returnCode === 'C00008') {
        alert(
          'There is already an ongoing withdrawal request. You cannot make a new withdrawal request until the existing one is completed.'
        );
        return;
      }

      setStep(3);

      fetchData();
    } catch (e) {
      alert(e);
    }
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  const goToMySalesProduct = e => {
    e.preventDefault();
    e.stopPropagation();
    // navigate(MY_SALES_PRODUCTS);
    setSelectedMore('Earning');
    moveScroll();
  };

  return (
    <>
      {seletecdMore === 'Earning' ? (
        <MySalesProducts moveScroll={moveScroll} />
      ) : seletecdMore === 'Product' ? (
        <SalesProductDetail moveScroll={moveScroll} />
      ) : seletecdMore === 'Month' ? (
        <SalesDaily
          seletecdMonth={seletecdMonth}
          setSelectedMore={setSelectedMore}
          moveScroll={moveScroll}
        />
      ) : (
        <MySalesStyle>
          <SalesStyle id='main' step={step} style={{ all: 'unset' }}>
            <section className='mypage-section mysale'>
              <div className='content-box mysales'>
                <div className='content-box-body'>
                  <div className='__grid'>
                    <div className='fab-mypage-mysales-box __fullwidth'>
                      <div className='__header'>
                        <h3 className='__title'>My Balance</h3>
                      </div>
                      <div className='__body'>
                        <div className='__value'>
                          <strong>$ {formatDesignerNumber(balance?.toFixed(2))}</strong>
                        </div>
                        <button
                          id='start-withdrawal'
                          disabled={requestCount.count > 0}
                          className='btn'
                          onClick={withdrawalBtn}
                        >
                          <strong>Withdrawal</strong>
                        </button>
                        <p className='__msg mt-u2 progress'>
                          {requestCount.count > 0 &&
                            '( $ ' +
                              Math.round(requestCount.amount, 0) +
                              ' )  Withdrawal in progress....'}
                        </p>
                      </div>
                    </div>
                    <div className='fab-mypage-mysales-box-sm'>
                      <div className='__header'>
                        <h4 className='__title'>Earnings this month</h4>
                        <p className='__desc'>
                          It shows your revenue and number of downloads for the current month.
                        </p>
                      </div>
                      <div className='__body'>
                        <dl>
                          <dt>
                            <svg
                              width='12'
                              height='11'
                              viewBox='0 0 12 11'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M9.35463 7.14592C9.4012 7.19236 9.43814 7.24754 9.46334 7.30828C9.48855 7.36903 9.50153 7.43415 9.50153 7.49992C9.50153 7.56568 9.48855 7.63081 9.46334 7.69155C9.43814 7.7523 9.4012 7.80747 9.35463 7.85392L6.35463 10.8539C6.30819 10.9005 6.25301 10.9374 6.19227 10.9626C6.13152 10.9878 6.0664 11.0008 6.00063 11.0008C5.93486 11.0008 5.86974 10.9878 5.809 10.9626C5.74825 10.9374 5.69308 10.9005 5.64663 10.8539L2.64663 7.85392C2.55275 7.76003 2.5 7.63269 2.5 7.49992C2.5 7.36714 2.55275 7.2398 2.64663 7.14592C2.74052 7.05203 2.86786 6.99929 3.00063 6.99929C3.13341 6.99929 3.26075 7.05203 3.35463 7.14592L6.00063 9.79292L8.64663 7.14592C8.69308 7.09935 8.74825 7.06241 8.809 7.0372C8.86974 7.012 8.93486 6.99902 9.00063 6.99902C9.0664 6.99902 9.13152 7.012 9.19227 7.0372C9.25301 7.06241 9.30819 7.09935 9.35463 7.14592Z'
                                fill='black'
                              />
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M6 3C6.13261 3 6.25979 3.05268 6.35355 3.14645C6.44732 3.24021 6.5 3.36739 6.5 3.5V10C6.5 10.1326 6.44732 10.2598 6.35355 10.3536C6.25979 10.4473 6.13261 10.5 6 10.5C5.86739 10.5 5.74021 10.4473 5.64645 10.3536C5.55268 10.2598 5.5 10.1326 5.5 10V3.5C5.5 3.36739 5.55268 3.24021 5.64645 3.14645C5.74021 3.05268 5.86739 3 6 3ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z'
                                fill='black'
                              />
                            </svg>
                            {formatDesignerNumber(thismonthDownload)} Downloads
                          </dt>
                          <dd>$ {formatDesignerNumber(thisMonthRevenue)} </dd>
                        </dl>
                      </div>
                    </div>
                    <div className='fab-mypage-mysales-box-sm'>
                      <div className='__header'>
                        <h4 className='__title'>Lifetime earnings</h4>
                        <p className='__desc'>
                          It shows your revenue and number of downloads over the entire time period.
                        </p>
                      </div>
                      <div className='__body'>
                        <dl>
                          <dt>
                            <svg
                              width='12'
                              height='11'
                              viewBox='0 0 12 11'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M9.35463 7.14592C9.4012 7.19236 9.43814 7.24754 9.46334 7.30828C9.48855 7.36903 9.50153 7.43415 9.50153 7.49992C9.50153 7.56568 9.48855 7.63081 9.46334 7.69155C9.43814 7.7523 9.4012 7.80747 9.35463 7.85392L6.35463 10.8539C6.30819 10.9005 6.25301 10.9374 6.19227 10.9626C6.13152 10.9878 6.0664 11.0008 6.00063 11.0008C5.93486 11.0008 5.86974 10.9878 5.809 10.9626C5.74825 10.9374 5.69308 10.9005 5.64663 10.8539L2.64663 7.85392C2.55275 7.76003 2.5 7.63269 2.5 7.49992C2.5 7.36714 2.55275 7.2398 2.64663 7.14592C2.74052 7.05203 2.86786 6.99929 3.00063 6.99929C3.13341 6.99929 3.26075 7.05203 3.35463 7.14592L6.00063 9.79292L8.64663 7.14592C8.69308 7.09935 8.74825 7.06241 8.809 7.0372C8.86974 7.012 8.93486 6.99902 9.00063 6.99902C9.0664 6.99902 9.13152 7.012 9.19227 7.0372C9.25301 7.06241 9.30819 7.09935 9.35463 7.14592Z'
                                fill='black'
                              />
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M6 3C6.13261 3 6.25979 3.05268 6.35355 3.14645C6.44732 3.24021 6.5 3.36739 6.5 3.5V10C6.5 10.1326 6.44732 10.2598 6.35355 10.3536C6.25979 10.4473 6.13261 10.5 6 10.5C5.86739 10.5 5.74021 10.4473 5.64645 10.3536C5.55268 10.2598 5.5 10.1326 5.5 10V3.5C5.5 3.36739 5.55268 3.24021 5.64645 3.14645C5.74021 3.05268 5.86739 3 6 3ZM0 0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526785 0.367392 0 0.5 0H11.5C11.6326 0 11.7598 0.0526785 11.8536 0.146447C11.9473 0.240215 12 0.367392 12 0.5C12 0.632608 11.9473 0.759785 11.8536 0.853553C11.7598 0.947321 11.6326 1 11.5 1H0.5C0.367392 1 0.240215 0.947321 0.146447 0.853553C0.0526785 0.759785 0 0.632608 0 0.5Z'
                                fill='black'
                              />
                            </svg>
                            {formatDesignerNumber(numberFormat(totalDownload))} Downloads
                          </dt>
                          <dd>$ {formatDesignerNumber(numberFormat(totalRevenue.toFixed(2)))}</dd>
                        </dl>
                      </div>
                    </div>
                    <div className='fab-mypage-mysales-box-sm'>
                      <div className='__header'>
                        <h4 className='__title'>Earning by Products</h4>
                        <p className='__desc'>
                          It shows your revenue and number of downloads over the entire time period.
                        </p>
                      </div>
                      <div className='__body'>
                        <dl className='__left'>
                          <dt>Your Products</dt>
                          <dd>{formatDesignerNumber(productCount)} ea</dd>
                        </dl>
                        <div className='__action'>
                          <Link to='' onClick={goToMySalesProduct}>
                            <svg
                              width='25'
                              height='25'
                              viewBox='0 0 25 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M9.7033 2.59278L17.5738 10.4633L-0.000535746 10.4633L-0.000536793 14.1299L17.5738 14.1299L9.70329 22.0004L12.2956 24.5928L24.5918 12.2966L12.2956 0.000442176L9.7033 2.59278Z'
                                fill='#4200F8'
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className='fab-mypage-mysales-table __fullwidth'>
                      <div className='__header'>
                        <div className='__title-area'>
                          <h4 className='__title'>Monthly Earnings</h4>
                          <p className='__desc'>
                            It shows the number of monthly downloads and earnings.
                          </p>
                        </div>
                        {/* Monthly Earnings option: years */}
                        <div className='__utils'>
                          <select
                            className='form-input select-year'
                            onChange={onChangeYear}
                            value={selectYear}
                          >
                            {years.map(y => {
                              return (
                                <option value={y} key={y}>
                                  {y}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div>
                        <div className='fab-table-wrapper'>
                          {/* Monthly Earnings List */}
                          <SalesMonthly year={selectYear} moveScroll={moveScroll} />
                        </div>
                      </div>
                    </div>
                    <div className='fab-mypage-mysales-table __fullwidth'>
                      <div className='__header'>
                        <div className='__title-area'>
                          <h4 className='__title'>Balance History</h4>
                          <p className='__desc'>Shows the balance change history.</p>
                        </div>
                        {/* History : status */}
                        <div className='__utils'>
                          <select className='form-input fomt-status' onChange={onChangeStatus}>
                            <option value='all'>All Status</option>
                            <option value='withdrawal'>Only withdrawal</option>
                            <option value='earning'>Only earning</option>
                          </select>
                          <DateRangePicker
                            initialSettings={{
                              ranges: {
                                Today: [moment(), moment()],
                                Yesterday: [
                                  moment().subtract(1, 'days'),
                                  moment().subtract(1, 'days'),
                                ],
                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [
                                  moment().subtract(1, 'month').startOf('month'),
                                  moment().subtract(1, 'month').endOf('month'),
                                ],
                              },
                              startDate: new Date(moment().subtract(7, 'd').format('MM/DD/YYYY')),
                              endDate: new Date(moment().format('MM/DD/YYYY')),
                            }}
                            onCallback={(start, end, label) => {
                              setSelectDate([start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]);

                              // setStartDate(start.format('YYYY-MM-DD'));
                              // setEndDate(end.format('YYYY-MM-DD'));
                            }}
                          >
                            <input type='text' className='form-input history' />
                          </DateRangePicker>
                        </div>
                      </div>
                      <div>
                        <BalanceHistory data={selectedHistories} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </SalesStyle>

          {/* 정산 요청 모달 */}
          <div className={'sales modal fab-modal' + (step === 1 ? ' active' : '')} id='modal1'>
            <div className='modal-inner'>
              <div className='modal-box'>
                <Link
                  to='#'
                  className='btn-close'
                  onClick={e => {
                    e.preventDefault();
                    setStep(0);
                  }}
                >
                  &times;
                </Link>
                <div className='sales modal-box-body'>
                  <h2 className='fab-modal-title'>Withdrawal</h2>
                  <div className='fab-form-block'>
                    <label className='fab-form-label'>withdrawal amount</label>
                    <div className='fab-form-control' style={{ position: 'relative' }}>
                      <span
                        id='dollar-sign'
                        style={{
                          display: style.display,
                          position: 'absolute',
                          left: '15px',
                          top: ' 16px',
                        }}
                      >
                        $
                      </span>
                      <input
                        id='amount'
                        type='number'
                        className='form-input'
                        style={{ paddingLeft: '30px' }}
                        placeholder='How much would you like to withdraw?'
                        value={inputNum}
                        onChange={changeAmount}
                      />
                      <em
                        id='amount-error'
                        className='fab-form-alert'
                        style={{ visibility: amountStyle.visibility }}
                      >
                        You have exceeded your withdrawal limit.
                      </em>
                    </div>
                    <div className='fab-well'>
                      <dl>
                        <dt>Maximum Withdrawal Amount</dt>
                        <dd id='withdrawal-user-input-display'>
                          <span className={withdrawalBalance > 0 ? '__valid' : '__invalid'}>
                            $ {formatDesignerNumber(withdrawalBalance.toFixed(2))}
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                  <div className='fab-form-block'>
                    <label className='fab-form-label'>Paypal Account</label>
                    <div className='fab-form-control'>
                      <input
                        id='email'
                        type='email'
                        className='form-input'
                        placeholder='ex) abc@paypal.com'
                        style={{ paddingLeft: '30px' }}
                        value={inputEmail}
                        onChange={changeEmail}
                      />
                      <em
                        id='email-error'
                        className='fab-form-alert'
                        style={{ visibility: regexStyle.visibility }}
                      >
                        This is not a valid email format.
                      </em>
                    </div>
                  </div>
                  <div className='fab-form-action'>
                    <button
                      id='request-withdrawal'
                      className='btn btn-fill btn-green'
                      disabled={btnDisabled}
                      onClick={() => {
                        setStep(2);
                      }}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      Withdrawal request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'sales modal fab-modal' + (step === 2 ? ' active' : '')} id='modal2'>
            <div className='modal-inner'>
              <div className='modal-box'>
                <Link
                  to='#'
                  className='btn-close'
                  onClick={e => {
                    e.preventDefault();
                    setStep(0);
                  }}
                >
                  &times;
                </Link>
                <div className='sales modal-box-body'>
                  <h2 className='fab-modal-title'>Confirm</h2>
                  <div className='fab-withdrawal-msg'>
                    <h3>Is the information correct?</h3>
                    <p>Information cannot be changed after request.</p>
                  </div>
                  <div className='fab-withdrawal-info'>
                    <dl>
                      <dt>Withdrawal request amount</dt>
                      <dd>
                        <span id='confirm-amount' className='__valid'>
                          $ {inputNum}
                        </span>
                      </dd>
                    </dl>
                    <dl>
                      <dt>Paypal Account</dt>
                      <dd>
                        <span id='confirm-email' className='__email'>
                          {inputEmail}
                        </span>
                      </dd>
                    </dl>
                  </div>
                  <div className='fab-form-action'>
                    <button
                      id='modal2-back'
                      className='fab-btn-back'
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      <svg
                        width='36'
                        height='42'
                        viewBox='0 0 36 42'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.2957 30.7041L7.42523 22.8336L24.9996 22.8336L24.9996 19.1669L7.42523 19.1669L15.2957 11.2964L12.7034 8.7041L0.407226 21.0003L12.7034 33.2964L15.2957 30.7041Z'
                          fill='#4200F8'
                        />
                      </svg>{' '}
                      Back
                    </button>
                    <button
                      id='request-confirm'
                      className='btn btn-fill btn-green'
                      onClick={withdrawalConfirm}
                      style={{ textAlign: 'center' }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'sales modal fab-modal' + (step === 3 ? ' active' : '')} id='modal3'>
            <div className='modal-inner'>
              <div className='modal-box'>
                <Link
                  to='#'
                  id='complete'
                  className='btn-close'
                  onClick={e => {
                    e.preventDefault();
                    setStep(0);
                    setCompeletedRequest(true);
                  }}
                >
                  &times;
                </Link>
                <div className='sales modal-box-body'>
                  <h2 className='fab-modal-title'>Request complete</h2>
                  <div className='fab-withdrawal-msg'>
                    <h3>Your withdrawal request has been completed</h3>
                    <p>Payment will be made within 7 days of the withdrawal request.</p>
                  </div>
                  <div className='fab-withdrawal-info'>
                    <dl>
                      <dt>Withdrawal request amount</dt>
                      <dd>
                        <span id='complete-amount' className='__valid'>
                          $ {inputNum}
                        </span>
                      </dd>
                    </dl>
                    <dl>
                      <dt>Paypal Account</dt>
                      <dd>
                        <span id='complete-email' className='__email'>
                          {inputEmail}
                        </span>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MySalesStyle>
      )}
    </>
  );
};

export default MySales;

const SalesStyle = styled.div`
  /* 정산요청 모달 640px 이하에서 띄울 시 스크롤바 없애기 */
  @media screen and (max-width: 640px) {
    ${props =>
      props.step !== 0 &&
      css`
        display: none;
      `}
  }
`;

const MySalesStyle = styled.div`
  .webpage2-style & {
    all: unset;
  }

  .fab-mypage-mysales-box-sm .__body {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .__body .btn {
    display: flex;
    justify-content: center;
  }

  .progress {
    margin-top: 15px;
  }
  .form-input.history {
    padding: 0px 20px;
  }
`;
