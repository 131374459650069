/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { CATEGORIES, COLLECTIONS, DESIGNERS, SETITEMS } from '../../../route/constant';
import { useNavigate } from 'react-router-dom';
import { ls } from '../../../constant';
import { useRecoilState } from 'recoil';
import { ctgSortAtom } from '../../../atom/atom';
const Categoryist = ({ current, data }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [miniCategoryIsOpen, setMiniCategoryIsOpen] = useState(false);
  const navigate = useNavigate();
  const [, setCtgSort] = useRecoilState(ctgSortAtom);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function miniCategoryHandler() {
    setMiniCategoryIsOpen(!miniCategoryIsOpen);
  }

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();
    setCtgSort(false);
    // 페이지네이션, sort, type filter 값 초기화
    localStorage.removeItem(ls.itemPage);
    localStorage.removeItem(ls.itemSort);
    localStorage.removeItem(ls.itemType);

    navigate(path);
  };

  return (
    <>
      {windowWidth > 768 ? (
        <aside className='aside'>
          <div className='store-nav'>
            <div className='container'>
              <ul className='store-sub-menu'>
                <li className={current === 'Special Designers' ? 'active' : ''}>
                  <a href='' onClick={e => goTo(e, DESIGNERS)}>
                    <span title='Special Designers'>Special Designers</span>
                  </a>
                </li>
                <li className={current ? '' : 'active'}>
                  <a href='' onClick={e => goTo(e, CATEGORIES)}>
                    <span title='All'>All</span>
                  </a>
                </li>
                <li className={current === 'Collections' ? 'active' : ''}>
                  <a href='' onClick={e => goTo(e, COLLECTIONS)}>
                    <span title='Collections'>Collections</span>
                  </a>
                </li>
                <li className={current === 'set' ? 'active' : ''}>
                  <a href='' onClick={e => goTo(e, SETITEMS)}>
                    <span title='Set Items'>Set Items</span>
                  </a>
                </li>
                {data?.map((c, idx) => {
                  if (c.id_category < 1002) return <li key={c.id_category + '-' + idx}></li>;
                  return (
                    <li
                      key={c.id_category + '-' + idx}
                      className={current === c.name ? 'active' : ''}
                    >
                      <a href='' onClick={e => goTo(e, CATEGORIES + '/' + c.name)}>
                        <span title={c.name} style={{ lineBreak: 'auto', lineHeight: '22px' }}>
                          {c.length > 13 ? c.replace('&', '<br/>&') : c.name}
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </aside>
      ) : (
        <>
          <div onClick={miniCategoryHandler} className='current-menu'>
            <span className='menu-name'>{current ? current : 'All'}</span>
            <i className={miniCategoryIsOpen ? 'menu-open-icon' : 'menu-close-icon'}></i>
          </div>
          {miniCategoryIsOpen && (
            <div className='miniCategory'>
              <ul className='store-sub-menu'>
                <li className={current === 'Special Designers' ? 'active' : ''}>
                  <a href='' onClick={e => goTo(e, DESIGNERS)}>
                    <span title='Special Designers'>Special Designers</span>
                  </a>
                </li>
                <li className={current ? '' : 'active'}>
                  <a href='' onClick={e => goTo(e, CATEGORIES)}>
                    <span title='All'>All</span>
                  </a>
                </li>
                <li className={current === 'Collections' ? 'active' : ''}>
                  <a href='' onClick={e => goTo(e, COLLECTIONS)}>
                    <span title='Collections'>Collections</span>
                  </a>
                </li>
                <li className={current === 'set' ? 'active' : ''}>
                  <a href='' onClick={e => goTo(e, SETITEMS)}>
                    <span title='Set Items'>Set Items</span>
                  </a>
                </li>
                {data?.map((c, idx) => {
                  if (c.id_category > 1001) {
                    return (
                      <li key={c.id_category} className={current === c.name ? 'active' : ''}>
                        <a href='' onClick={e => goTo(e, CATEGORIES + '/' + c.name)}>
                          <span
                            title={c.name}
                            style={{ lineBreak: 'auto', lineHeight: '22px' }}
                            dangerouslySetInnerHTML={{
                              __html: c.name.length > 13 ? c.name.replace('&', '<br /> &') : c.name,
                            }}
                          ></span>
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Categoryist;
