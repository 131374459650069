export const isVideo = img => {
  return img.type === 'video';
};

export const isFree = free => {
  return free !== 'n';
};

//무료상품인지 확인 후 true false 반환
export const isIncluded = itemNumber => {
  const freeDownloadItems = [439, 410, 321, 277, 229];
  const isFreeItem = freeDownloadItems.includes(itemNumber);
  return isFreeItem;
};
