import React from 'react';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Pagenation = ({
  count,
  page,
  setPage,
  moveScroll,
  setPageNation,
  value,
  viewCount,
  margin,
}) => {
  const handlePageChange = page => {
    if (value === 'my') {
      setPage(page);
    } else {
      setPage(page);
      setPageNation(page);
      moveScroll();
    }
  };

  return (
    <PaginationBox margin={margin}>
      <Pagination
        activePage={page}
        itemsCountPerPage={viewCount}
        totalItemsCount={count}
        pageRangeDisplayed={isMobile ? 3 : 10}
        onChange={handlePageChange}
      />
    </PaginationBox>
  );
};

export default Pagenation;
const PaginationBox = styled.div`
  margin: ${props => props.margin || '100px 0px'};
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-right: 5px;
    border-radius: 2px;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: #747474;
    font-size: 1rem;
    font-weight: 500;
  }
  ul.pagination li.active a {
    color: white;
    font-weight: 500;
  }
  ul.pagination li.active {
    background-color: #242424;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: #fff;
  }
`;
