import React from 'react';
import DesignersCountBox from './DesignersCountBox';
import { localDesignerDataAtom, commentTotalCountAtom } from '../../../atom/atom';
import { useRecoilValue, useRecoilState } from 'recoil';

const DesingersBoxs = ({ data, designerFollow, value }) => {
  const checkValue = value === 'designerList';
  const [totalCount, setTotalCount] = useRecoilState(commentTotalCountAtom);
  // const localDesignerData = useRecoilValue(localDesignerDataAtom);
  const formatNumber = num => {
    if (num >= 10000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 10000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num;
  };

  return (
    <>
      <DesignersCountBox
        icon={'/assets/img/designers/deployed_code.png'}
        title={'Models'}
        count={formatNumber(data?.model_count || 0)}
      />
      <DesignersCountBox
        icon={'/assets/img/designers/group_add.png'}
        title={'Followers'}
        count={designerFollow ? designerFollow : data?.follow_count || 0}
      />
      <DesignersCountBox
        icon={'/assets/img/designers/download.png'}
        title={'Downloads'}
        count={formatNumber(data?.downloads || 0)}
      />
      <DesignersCountBox
        icon={'/assets/img/designers/mark_chat_read.png'}
        title={'Comments'}
        count={formatNumber(
          checkValue
            ? data?.comment_count || 0
            : totalCount?.total === 'update'
            ? totalCount.count
            : data?.comment_count || 0
        )}
      />
      <DesignersCountBox
        icon={'/assets/img/designers/photo_camera.png'}
        title={'Reviews'}
        count={formatNumber(data?.review_count || 0)}
        className={'camera'}
      />
    </>
  );
};

export default DesingersBoxs;
