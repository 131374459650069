import React, { useContext, useEffect, useRef, useState } from 'react';
import { createCartAdd, toggleItemLike } from '../../../action/request';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import UserContext from '../../../context/user/user';
import ModalContext from '../../../context/modal/modal';
import { gtagAddToCart } from '../../../action/gTag';
import { ls } from '../../../constant';
import CartContext from '../../../context/cart.js/carts';
import { replicateObject } from '../../../util/skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { updateLikeState, designerUpdateLikeState } from '../../../util/updateLikeState';
import { useRecoilState } from 'recoil';
import { likeAtom, designerListAtom } from '../../../atom/atom';

import ItemCardMain from '../../../component/common/itemCards/ItemCardMain';
import { emptyCard } from '../../../component/section/constant';
import styled from 'styled-components';
import { fillArray } from '../../../component/section/util';

function DesignerSectionBody({ data, isMobile, locationData, designerId }) {
  const emptyCards = replicateObject(emptyCard, 4);
  const [cards, setCards] = useState(emptyCards);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const [likeState, setLikeState] = useRecoilState(likeAtom);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);
  const navigate = useNavigate();

  const options = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 25,
    loop: true,

    navigation: {
      prevEl: `.buttonSwiperPrevS4${locationData}`,
      nextEl: `.buttonSwiperNextS4${locationData}`,
    },
    breakpoints: {
      440: {
        slidesPerView: 1.5,
      },
      700: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
      1180: {
        slidesPerView: 4,
      },
    },
  };

  const goTo = (e, path, id) => {
    e.preventDefault();
    e.stopPropagation();

    if (isSkeletonLoading) return;

    navigate(path, { state: { location: 'fromDesignerSection' } });
  };

  useEffect(() => {
    if (data?.length) {
      // 데이터가 있을 경우에만 setCards 호출
      setCards(data);
    }
    setTimeout(() => {
      setIsSkeletonLoading(false);
    }, 200);
  }, [data]);

  const clickLike = async (id, count) => {
    if (!userState.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeState,
          cards: cards,
          count: count,
          data: data,
        });
        const designerListUpdata = designerList?.map(item =>
          Number(item.designer.id_studio) === Number(designerId)
            ? {
                designer: item.designer,
                items: updatedCards,
              }
            : item
        );

        setDesignerList(designerListUpdata);
        setCards(updatedCards);
      }
    }
  };

  const clickCart = async (price, id, inCart) => {
    // 이미 카트에 담겨있을 경우
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = cards.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setCards(newCards);

          const designerListUpdata = designerList?.map(item =>
            Number(item.designer.id_studio) === Number(designerId)
              ? {
                  designer: item.designer,
                  items: newCards,
                }
              : item
          );

          setDesignerList(designerListUpdata);

          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  // swiper는 슬라이드 목록이 없을 때 autoplay, navigation 동작을 시작하지 않는 오류가 생기기 때문에 slide 값이 있을 때 동작하게 한다.
  if (!cards?.length) return null;
  return (
    <DesignerSectionBodyStyle className='sectionTypeB' id='designerSectionBody'>
      <div className='centerWrap'>
        <div className='swiperBigWrap'>
          <Swiper {...options} modules={[Navigation]} className='sectionASwiper sectionASwiper004'>
            <div className='swiper-wrapper'>
              {cards &&
                fillArray([...cards], { product_id: false }, 8)?.map((card, idx) => {
                  return card?.product_id ? (
                    <SwiperSlide key={idx}>
                      <div className='swiper-slide'>
                        <ItemCardMain
                          idx={idx}
                          data={card}
                          isSkeletonLoading={false}
                          goTo={goTo}
                          clickCart={clickCart}
                          clickLike={clickLike}
                          ranking={data?.ranking}
                          skeleton={isMobile ? 200 : 800}
                          isLike={likeState}
                          value={'designerList'}
                        />
                      </div>
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide key={idx}>
                      <div className='swiper-slide'>
                        <div className={`shopItemCard comingSoon`}>
                          <div className='imgWrap'>
                            <img
                              src='/assets/img/2.0WebImg/content/coming_soon.jpg'
                              className='itemImg1 comming_soon'
                              alt='FAB365 COMING SOON'
                            />
                          </div>

                          {/* commin soon 높이를 다른 카드와 맞추기 위한 의미없는 태그 */}
                          <div href='#' className='itemLinkPart2' title='item detail'>
                            <div className='title'>&nbsp;</div>
                            <div className='subtitle'>&nbsp;</div>
                            <div className='priceRow'>
                              <div className='price'>&nbsp;</div>
                            </div>

                            <div className='etc'>
                              <>
                                <button
                                  tabIndex='0'
                                  type='button'
                                  className='favorites'
                                  style={{ opacity: 0 }}
                                >
                                  &nbsp;
                                </button>
                                <button
                                  tabIndex='0'
                                  type='button'
                                  className='comments'
                                  style={{ opacity: 0 }}
                                >
                                  &nbsp;
                                </button>
                                <button
                                  tabIndex='0'
                                  type='button'
                                  className='pictures'
                                  style={{ opacity: 0 }}
                                >
                                  &nbsp;
                                </button>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </div>
          </Swiper>

          <button
            tabIndex='0'
            type='button'
            className={`buttonSwiperPrev buttonSwiperPrevS4${locationData}`}
            title='prev'
            style={{ opacity: isSkeletonLoading ? 0 : 1 }}
          ></button>
          <button
            tabIndex='0'
            type='button'
            className={`buttonSwiperNext buttonSwiperNextS4${locationData}`}
            title='next'
            style={{ opacity: isSkeletonLoading ? 0 : 1 }}
          ></button>
        </div>
      </div>
    </DesignerSectionBodyStyle>
  );
}

export default DesignerSectionBody;

const DesignerSectionBodyStyle = styled.div`
  .centerWrap {
    padding: 10px 0px 70px !important;
    margin: 10px 0px 50px;
    border-bottom: 1px solid #eeeeee !important;
  }

  .sectionTypeB {
    padding: 0px !important;
  }
`;
