import Layout from '../../../component/layout/Layout';
import RequestMedia from '../../../component/request/RequestMedia';
import { MY_REQUEST, REQUESTS } from '../../../route/constant';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getMyRequestDetail, requestEdit } from '../../../action/request';
import RequestTitle from './component/RequestTitle';
import RequestBody from './component/RequestBody';
import { ls } from '../../../constant';
import { RETURN_CODE } from '../../../action/constant';

const RequestEdit = ({}) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [medias, setMedias] = useState([]);
  const [mainIdx, setMainIdx] = useState(0);
  const { oid } = useParams();
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await getMyRequestDetail({ oid });

      if (data.returnCode !== RETURN_CODE.C00000) {
        navigate(REQUESTS);
        return;
      }

      setBody(data.data.request.body);
      setSubject(data.data.request.subject);
      setMedias(data.data.request.medias);
      setMainIdx(data.data.request.mainImg);
    } catch (e) {
      navigate(REQUESTS);
    }
  };

  const tryRequestEdit = async e => {
    e.preventDefault();

    if (subject.trim() === '') return alert('Title is empty');
    if (body.trim() === '') return alert('Description is empty');
    if (medias.length === 0) return alert('Add image');

    try {
      const { data: res } = await requestEdit({
        subject: subject,
        body: body,
        medias: medias,
        mainImg: mainIdx,
        oid: oid,
      });

      if (res) {
        window.location.href = MY_REQUEST;
      }
    } catch (e) {
      alert(e);
    }
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  return (
    <>
      <Layout>
        <div id='main'>
          <section className='story-section'>
            <div className='container'>
              <div className='write-review'>
                <div className='write-review-header'>
                  <h2>
                    <strong>Upload</strong> Request
                  </h2>
                  <p>Request the design you want.</p>
                </div>
                <div className='write-review-body'>
                  <form
                    action=''
                    className='write-review-form'
                    id='request-write'
                    onSubmit={tryRequestEdit}
                  >
                    <input type='hidden' name='oid' value={oid} />
                    <RequestTitle subject={subject} setSubject={setSubject} />

                    <RequestBody
                      body={body}
                      setBody={setBody}
                      medias={medias}
                      setMedias={setMedias}
                      mainIdx={mainIdx}
                      setMainIdx={setMainIdx}
                    />

                    <div className='form-action form-action2'>
                      <button type='submit' className='btn btn-green btn-fill'>
                        OK
                      </button>
                      <a href='' className='btn' onClick={e => goTo(e, MY_REQUEST)}>
                        Cancel
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default RequestEdit;
