import React, { useEffect } from 'react';
import { SocialButton } from '../styles';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../../action/request';

function Twitter() {
  const location = useLocation();
  const currentPath = location.pathname;

  const onClickTwitter = async e => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${baseUrl}sns/twitter/callback`;
    // console.log(window.location.href);
    // 로그인할 때 현재 위치 로컬스토리지에 저장
    localStorage.setItem('loginPath', currentPath);
  };

  return (
    <SocialButton
      id='twitter-signup'
      className='btn btn-full social-button'
      onClick={onClickTwitter}
    >
      <img src='/assets/img/login/Twitter-X.png' alt='Twitter' />
    </SocialButton>
  );
}

export default Twitter;
