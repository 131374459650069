import { sanitize } from 'dompurify';
import { fileSize } from '../../../util/file';
import uuid from 'react-uuid';
import moment from 'moment';
import './ItemInfo.css';
import ItemDataText from '../../../component/ItemData/ItemDataText';
import ItemDataVideo from '../../../component/ItemData/ItemDataVideo';
const ItemInfo = ({ item, itemDatas, itemdata2s, iframeSrc }) => {
  const size = fileSize(item.allFileSize);
  // console.log(itemDatas);
  return (
    <>
      <div className='store-detail-tab-content active' id='store_detail_information'>
        <div className='container'>
          <div className='store-detail-content-block'>
            <div className='store-detail-content-block-left'>
              <div className='description'>
                <ItemDataVideo videos={itemdata2s?.videos} />
                {iframeSrc &&
                  iframeSrc.map(i => {
                    return (
                      <iframe
                        key={uuid()}
                        width='100%'
                        height='472'
                        style={{ maxWidth: '840px' }}
                        src={i}
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowFullScreen
                      ></iframe>
                    );
                  })}

                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitize(item?.desc?.replaceAll('\n', '<br/>')),
                  }}
                  className='html'
                ></p>
              </div>
              <table className='spec'>
                <colgroup>
                  <col width='200'></col>
                  <col></col>
                </colgroup>
                <tbody>
                  {itemDatas?.infos?.map((info, idx) => {
                    return (
                      <tr key={`${info.name}${idx}`}>
                        <th>{info.name}</th>
                        <th>{info.text}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className='store-detail-content-block-right'>
              <div className='product-meta-item'>
                <i className='icon-file'></i>
                <dl>
                  <dt>File</dt>
                  <dd>
                    File
                    {size.size.toFixed(1) + ' ' + size.unit}
                  </dd>
                </dl>
              </div>
              {item?.released && (
                <div className='product-meta-item'>
                  <i className='icon-released'></i>
                  <dl>
                    <dt>Release Date</dt>
                    <dd>{moment(item.released).format('YYYY-MM-DD')}</dd>
                  </dl>
                </div>
              )}
              {itemDatas?.printers?.length > 0 && (
                <div className='product-meta-item'>
                  <i className='icon-printer'></i>
                  <dl>
                    <dt>Recommend Print</dt>
                    {itemDatas?.printers?.map((p, idx) => {
                      return (
                        <dd key={uuid()}>
                          <ItemDataText info={p} />
                        </dd>
                      );
                    })}
                  </dl>
                </div>
              )}
              {itemDatas?.colors?.length > 0 && (
                <div className='product-meta-item'>
                  <i className='icon-material'></i>
                  <dl>
                    <dt>Material Color</dt>
                    {itemDatas?.colors?.map((c, idx) => {
                      return (
                        <dd key={uuid()}>
                          <ItemDataText info={c} />
                        </dd>
                      );
                    })}
                  </dl>
                </div>
              )}
              {itemDatas?.times?.length > 0 && (
                <div className='product-meta-item'>
                  <i className='icon-time'></i>
                  <dl>
                    <dt>Average Time</dt>
                    {itemDatas?.times?.map((t, idx) => {
                      return (
                        <dd key={uuid()}>
                          <ItemDataText info={t} />
                        </dd>
                      );
                    })}
                  </dl>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemInfo;
