import styled from 'styled-components';
import { ImgStyled } from '../../styles/ImgStyled';

//designersPage
export const DesignersContainer = styled.div`
  width: 100%;
  height: ${props => props.height};
  overflow: hidden;

  .designerNav {
    margin: 0 auto;
    max-width: 1340px;
    position: relative;
    padding: 0 1.5rem;
  }

  @media screen and (max-width: 480px) {
    .designerNav {
      padding: 0px;
    }

    .shopItemCard .priceRow > .price {
      font-size: 18px;
    }
  }
`;

// models , reivews
export const CardWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  margin-top: 40px;

  @media screen and (max-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Card = styled.div`
  width: 100%;
`;

//designerHeader

export const Header = styled.div`
  padding-top: 40px;
  padding: ${props => props.headerPadding && props.headerPadding};
  background-image: ${props =>
    props.designerSection
      ? 'url("/assets/img/designers/designersBg.png")'
      : 'url("/assets/img/designers/designerBg.png")'};
  background-size: cover;
  border-radius: ${props => props.borderRadius && props.borderRadius + 'px'};

  .profileWrap .profile {
    background-color: gray;
  }

  @media screen and (max-width: 1100px) {
    .headerbg .flex {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .headerbg .countBox {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
    }

    .headerbg .description {
      margin-left: 0px;
      width: 89%;
    }

    .headerbg .countBox {
      margin-top: 30px;
    }

    .headerbg .countBox .box {
      min-width: 100px;
    }
  }

  @media screen and (max-width: 650px) {
    .headerbg .countBox .box {
      min-width: 90px;
    }
  }

  @media screen and (max-width: 600px) {
    background-size: auto;

    .headerbg {
      width: 100%;
      padding: 50px 20px;
      box-sizing: border-box;
    }
    .profileWrap {
      display: flex;
      flex-direction: column;
    }

    .headerbg .flex {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .headerbg .desinerInfo {
      margin-top: 20px;
    }

    .headerbg .countBox {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      /* gap: 10px; */
      margin-top: 30px;
      margin-left: 0px;
      justify-items: center;
    }

    .headerbg .countBox .box {
      margin-right: 0px;
    }

    .headerbg .designerName {
      font-size: 34px;
    }
  }
`;

export const HeaderBg = styled.div`
  padding: ${props => (props.padding ? props.padding : '50px 30px 30px 30px')};
  width: 100%;
  height: 100%;
  max-width: 1360px;
  margin: 0px auto;
  position: relative;
  display: flex;
  flex-direction: column;

  .viewDesignerList {
    color: #fff;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 16px !important;

    &:before {
      display: inline-block;
      content: '';
      background-image: url('/assets/img/designers/arrow_back_ios.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
    }
  }

  .flex {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .designer {
    display: flex;
  }

  .profileWrap {
    display: flex;
  }

  .desinerInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .btnWrap {
    display: flex;
  }

  .designerName {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .countBox {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    margin-left: 20px;
  }

  .description {
    width: 65%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-left: 140px;

    span {
      color: #fff;
      line-height: 25px;
      font-size: 14px;
      font-weight: 200;
    }

    .ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .readMore {
      text-decoration: underline;
      text-underline-offset: 3px;
      color: #fff;
      font-size: 15px;
      font-weight: 500;

      margin-top: 30px;
    }
  }
`;

export const Img = styled(ImgStyled)`
  width: ${props => props.width}px;
  height: ${props => props.height || props.width}px;
  margin-right: ${props => props.right || 0}px;
  margin-left: ${props => props.left || 0}px;
  border-radius: ${props => props.radius || 0}%;
`;

export const Contents = styled.div`
  min-height: 400px;
  padding-bottom: 100px;
`;

export const MyContents = styled.div`
  margin-top: 0px !important;
  padding-top: 0px !important;

  .fab-mypage-mysales-table {
    margin-top: 0px !important;
  }

  @media screen and (max-width: 480px) {
    .__scroll-area {
      min-width: 480px;
    }
  }
`;

export const BackBtn = styled.div`
  margin-bottom: ${props => props.bottom || 0}px;

  .fab-mypage-mysales-table .__footer {
    margin-top: 0px !important;
  }
  .__footer .fab-btn-back {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0);
    border-radius: 0;
    font-family: Montserrat, sans-serif !important;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.2;
  }
  .__footer .fab-btn-back svg {
    display: block;
    margin-right: 0.4444444444em;
    width: 2em;
  }
`;

export const ViewDetailsBtn = styled.button`
  color: #fff !important;
  width: 158px;
  height: 43px;
  border: 1px solid #ffffffb2 !important;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 15px;
    font-weight: 600;
  }

  img {
    width: 18px;
    height: 13px;
    margin: 0 5px;
  }
`;
