import { useEffect, useState } from 'react';
import Layout from '../../../component/layout/Layout';
import { MY_REVIEW } from '../../../route/constant';
import ReviewBody from './component/ReviewBody';
import ReviewTag from './component/ReviewTag';
import ReviewTitle from './component/ReviewTitle';
import SelectReviewItems from './component/SelectReviewItem';
import './ReviewCreate.css';
import { getMyReviewDetail, reviewEdit } from '../../../action/request';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ls } from '../../../constant';
import { RETURN_CODE } from '../../../action/constant';
const ReviewEdit = ({}) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [tags, setTags] = useState([]);
  const [medias, setMedias] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [items, setItems] = useState([]);
  const [review, setReview] = useState([]);
  const [mainIdx, setMainIdx] = useState(0);
  const { oid } = useParams();
  const [selectItem, setSelectItem] = useState('');
  const [stringTags, setStringTags] = useState('');
  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await getMyReviewDetail({ oid });

      if (data.returnCode !== RETURN_CODE.C00000) {
        navigate('/reviews');
        return;
      }

      setItems(data.data.items);
      setBody(data.data.reviews.body);
      setSubject(data.data.reviews.subject);
      setMedias(data.data.reviews.medias);
      setTags(data.data.reviews.tags);
      setReview(data.data.reviews);
      setMainIdx(data.data.reviews.mainImg);
      setSelectedItem(data.data.reviews.item_id);
      selectize();
    } catch (e) {}
  };

  const selectize = () => {
    setTimeout(() => {
      window.$('select[name=item]').selectize({
        create: false,
        copyClassesToDropdown: false,
      });
      window.$('input[name=tags]').selectize({
        delimiter: ',',
        persist: false,
        copyClassesToDropdown: false,
        create: function (input) {
          return {
            value: input,
            text: input,
          };
        },
      });
      const inputTags = window.$('input[name=tags]')[0].selectize;

      inputTags.on('change', function (value) {
        setStringTags(value);
        setTags(value.split(','));
      });
      const selectItem = window.$('select[name=item]')[0].selectize;

      selectItem.on('change', function (value) {
        setSelectItem(value);
      });
    }, 300);
  };

  const tryReviewEdit = async e => {
    e.preventDefault();
    let item = '';
    if (selectItem === '') {
      item = selectedItem;
    } else {
      item = selectItem;
    }

    if (subject.trim() === '') return alert('Title is empty');
    if (body.trim() === '') return alert('Description is empty');
    if (medias.length === 0) return alert('Add image');
    if (medias.length === 0) return alert('Add image');

    try {
      const { data: res } = await reviewEdit({
        action: 'review-edit',
        params: {
          item: item,
          subject: subject,
          body: body,
          tags: tags?.join(','),
          medias: medias,
          mainImg: mainIdx,
          oid: oid,
        },
      });
      if (res) {
        window.location.href = MY_REVIEW;
      }
    } catch (e) {
      alert(e);
    }
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;
  return (
    <>
      <Layout>
        <Helmet>
          <script src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/css/selectize/selectize.js'></script>
        </Helmet>
        <div id='main' className='review-create'>
          <section className='story-section'>
            <div className='container'>
              <div className='write-review'>
                <div className='write-review-header'>
                  <h2>
                    <strong>Write</strong> Review
                  </h2>
                  <p>Share your making story</p>
                </div>
                <div className='write-review-body'>
                  <form
                    action=''
                    className='write-review-form'
                    id='review-write'
                    onSubmit={tryReviewEdit}
                  >
                    <SelectReviewItems
                      data={items}
                      selected={selectedItem}
                      onChange={setSelectedItem}
                    />
                    <ReviewTitle subject={subject} setSubject={setSubject} />
                    <ReviewBody
                      body={body}
                      setBody={setBody}
                      medias={medias}
                      setMedias={setMedias}
                      mainIdx={mainIdx}
                      setMainIdx={setMainIdx}
                    />
                    <ReviewTag tags={tags} setTags={setTags} />
                    <div className='review-warning'>
                      Reviews that include irrelevant photos and write-ups to the designs may be
                      deleted
                    </div>
                    <div className='form-action border-none'>
                      <button type='submit' className='btn btn-green btn-fill'>
                        OK
                      </button>
                      <a href='' onClick={e => goTo(e, MY_REVIEW)} className='btn'>
                        Cancel
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default ReviewEdit;
