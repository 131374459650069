import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import DiscountPrice from '../../../component/section/DiscountPrice';
import { freeDownloadItems, isIncluded } from '../../../util/itemData';
import { useEffect, useState } from 'react';

const ItemPrice = ({
  price,
  freeTicketCount,
  isLogin,
  qty,
  finalPrice,
  discountRate,
  originPrice,
  secretProduct,
  itemNumber,
}) => {
  const [isFreeItem, setIsFreeItem] = useState(false);
  useEffect(() => {
    const freeItem = isIncluded(Number(itemNumber));

    setIsFreeItem(freeItem);
  }, []);

  return (
    <>
      {/* 할인율이 적용 유무 */}
      {price !== 0 && discountRate === '0' ? (
        <p className='price font-nanumsquare'>$&nbsp;{price}</p>
      ) : (
        <DiscountPrice
          finalPrice={finalPrice}
          discountRate={discountRate}
          originPrice={originPrice}
          detailPage={'detailPage'}
          fontSize={'30px'}
          spanFontSize={'20px'}
        />
      )}
      {price === 0 && (
        <TextContainer>
          <p className='price font-nanumsquare'>REWARD</p>
          {isFreeItem || (
            <p style={{ marginTop: '10px' }}>You can get this model with reward ticket</p>
          )}
          <div style={{ color: '#511AD0', marginTop: '20px' }}>
            {/* 비로그인 상태 */}
            {!isLogin && price === 0 && secretProduct !== 'secret' && isFreeItem && (
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: '700',
                  display: 'block',
                  marginBottom: '40px',
                  // backgroundColor: 'yellow',
                }}
              >
                Sign up and get it
              </span>
            )}
            {/* 로그인 상태 */}
            {(isLogin || !isFreeItem) && (
              <>
                {/* TODO: 구매에 필요한 티켓 (API 연결 후) */}
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: '700',
                  }}
                >
                  {qty < 2 ? `${qty} Reward ticket needed` : `${qty} Reward tickets needed`}
                </span>
              </>
            )}
          </div>
          {(isLogin || !isFreeItem) && (
            <>
              {/* TIPPY */}
              <div
                style={{
                  color: '#511AD0',
                  fontSize: '14px',
                  marginTop: '10px',
                  fontWeight: '600',
                }}
              >
                Reward ticket?&nbsp;
                <span className='fab-btn-i--tooltip-f' aria-describedby='tippy-1'>
                  <NewTippyStyle
                    className='tippy-1'
                    content={[
                      <li>&bull; Reward tickets are coupons for downloading reward model.</li>,
                      <li>
                        &bull; Reward tickets can only be used for reward models, not for paid
                        models.
                      </li>,
                      <li>
                        &bull; The reward models are created as a form of fan art, filled with
                        utmost respect for masterpieces. And we share them for FREE with our
                        customers.
                      </li>,
                      <li>
                        &bull; You can receive a reward ticket immediately after signing up for
                        Fab365.
                      </li>,
                      <li>
                        &bull; You can earn additional reward tickets for each purchased paid model
                        (including models purchased with Fab365 credit).
                      </li>,
                      <li>&bull; The issued reward ticket is valid for 6 months.</li>,
                      <li>
                        &bull; Please note that reward tickets are only available for accounts that
                        have completed email authentication..
                      </li>,
                    ]}
                  >
                    <img
                      src='https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/svg/icon-tooltip.svg'
                      alt=''
                    />
                  </NewTippyStyle>
                </span>
              </div>
              {isLogin && (
                <>
                  {freeTicketCount - qty < 0 ? (
                    <p style={{ fontSize: '14px', paddingTop: '30px', color: '#FF0000' }}>
                      {freeTicketCount < 2
                        ? `You have ${freeTicketCount} reward ticket. (Not enough.)`
                        : `You have ${freeTicketCount} reward tickets. (Not enough.)`}
                    </p>
                  ) : (
                    <p style={{ fontSize: '14px', paddingTop: '30px' }}>
                      {freeTicketCount < 2
                        ? `You have ${freeTicketCount} reward ticket.`
                        : `You have ${freeTicketCount} reward tickets.`}
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </TextContainer>
      )}
    </>
  );
};

export default ItemPrice;

const TippyStyle = styled(Tippy)`
  line-height: 1.4em;
  font-size: 14px;
  padding: 0.2rem;
  /* Styling the arrow for different placements */
  &[data-placement^='top'] {
    .tippy-arrow {
      position: absolute;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 7px 7px 0;
      border-color: lightgray transparent transparent transparent;
    }
  }
  &[data-placement^='bottom'] {
    .tippy-arrow {
      z-index: 999;
      position: absolute;
      top: -7px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
      border-style: solid;
      border-width: 0 7px 7px;
      border-color: transparent transparent lightgray transparent;
    }
  }
`;

const NewTippyStyle = styled(TippyStyle)`
  li {
    list-style: none;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const TextContainer = styled.div`
  /* background-color: #e0e0e0; */
  width: 100%;
  height: 100%;
`;
