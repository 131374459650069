export const AVATAR_ERROR_FILE_TOO_BIG = 'avatar.error.fileTooBig';
export const AVATAR_ERROR_INVALID_IMAGE_FORMAT = 'avatar.error.invalidImageFormat';

export const CHANGE_PASSWORD_ERROR_SNS_CANNOT = 'service.changePassword.error.snsUserCannot';

export const CHECKOUT_ERROR_ALREADY_PURCHASED = 'service.checkout.error.alreadyPurchased';
export const CHECKOUT_ERROR_CANNOT_BUYABLE = 'service.checkout.error.cannotBuyableItems';
export const CHECKOUT_ERROR_EXCEED_MAX_ITEMS = 'service.checkout.error.exceedMaxCountItems';
export const CHECKOUT_ERROR_INVALID_PRICE = 'service.checkout.error.invalidPrice';
export const CHECKOUT_ERROR_MILEAGE_NOT_ENOUGH = 'service.checkout.error.mileageNotEnough';
export const CHECKOUT_ERROR_MIN_PRICE = 'service.checkout.error.minPrice';
export const CHECKOUT_STRING_CANNOT_BUYABLE = 'service.checkout.term.cannotBuyable';

export const COUPON_ERROR_ALREADY_REGISTERED = 'service.coupon.error.alreadyRegistered';
export const COUPON_ERROR_DATE_EXPIRED = 'service.coupon.error.dateExpired';
export const COUPON_ERROR_GUID_NOT_EXIST = 'service.coupon.error.guid.notExist';
export const COUPON_ERROR_ALL_ITEM_IS_PAID = 'service.coupon.error.allItemIsPaid';

export const ERROR_PASSWORD_INVALID = 'service.error.passwordInvalid';
export const ERROR_PASSWORD_NOT_MATCH = 'service.error.passwordNotMatch';

export const FORGOT_PASSWORD_ERROR_USER_NOT_ACTIVE = 'service.forgotPassword.error.userNotActive';
export const FORGOT_PASSWORD_ERROR_USER_NOT_EXIST = 'service.forgotPassword.error.userNotExist';

export const LOGIN_ERROR_USER_NOT_EXIST = 'service.login.error.user.notExist';
export const LOGIN_ERROR_CANNOT_LOGINABLE = 'service.login.error.cannotLoginable';
export const LOGIN_ERROR_NOT_ACTIVE_USER = 'service.login.error.user.notActive';

export const LOGIN_ERROR_EMAIL_INVALID = 'service.login.error.emailInvalid';
export const LOGIN_ERROR_PASSWORD_INVALID = 'service.login.error.passwordInvalid';

export const PRINTER_PROFILE_ERROR_SETTING_NOT_READY =
  'service.printerProfile.error.settingNotReady';
export const PRINTER_PROFILE_ERROR_NEED_PAID_ORDER = 'service.printerProfile.error.needPaidOrder';

export const MILEAGE_ERROR_EXCEED_MAX_AMOUNT = 'service.coupon.error.exceedMaxAmount';

export const SIGNUP_ERROR_EMAIL_EXIST = 'service.signup.error.existEmail';
export const SIGNUP_ERROR_SNS_TOKEN_EXIST = 'service.signup.error.existSnsToken';
export const SIGNUP_ERROR_USERID_EXIST = 'service.signup.error.existUserid';

export const TICKET_ERROR_EXPIRED = 'service.ticket.error.ticketExpired';
export const TICKET_ERROR_NOT_ACTIVE = 'service.ticket.error.ticketNotActive';

//COMMON
export const COMMON_FORM_REQUIRE_EMAIL = 'common.form.require.email';
export const COMMON_FORM_REQUIRE_PASSWORD = 'common.form.require.password';

export const COMMON_FORMAT_EMAIL_INVALID = 'common.format.email.invalid';

//PAGE
export const PAGE_FOOTER_HEADER_ABOUT = 'html.page.footer.header.about';
export const PAGE_FOOTER_HEADER_CONTACT = 'html.page.footer.header.contact';
export const PAGE_FOOTER_HEADER_COPYRIGHT = 'html.page.footer.header.copyright';
export const PAGE_FOOTER_HEADER_HELP = 'html.page.footer.header.help';
export const PAGE_FOOTER_HEADER_PRIVACY = 'html.page.footer.header.privacy';
export const PAGE_FOOTER_HEADER_TERMS = 'html.page.footer.header.terms';
export const PAGE_FOOTER_HEADER_TERMS_POINT = 'html.page.footer.header.termsPoint';

export const PAGE_FOOTER_COMPANY = 'html.page.footer.company';

export const PAGE_MY_POINT_HISTORY_TYPE_BUY = 'html.page.my.point.history.type.buy';
export const PAGE_MY_POINT_HISTORY_TYPE_USE = 'html.page.my.point.history.type.use';
export const PAGE_CHECKOUT_LICENSE = 'html.page.checkout.license';
export const PAGE_CHECKOUT_LICENSE_AGREEMENT = 'html.page.checkout.license.agreement';
export const BUSINESS_EN = {
  title:
    'For customers who want to use the contents of FAB365 for non-personal or commercial purposes, please refer to the following.',
  h1: 'If you would like to use the printout at a non-profit event, please refer to educational license.',
  h_shop: 'Selling 3D printouts',
  h_edu: 'Educational institution',

  shop_1: 'For customers who want to sell the printed fab365 models.',
  shop_2:
    'You pay a license fee of USD $50-100 per piece of content and can only be used for 6 months. (The exact content license fee varies from content to content.)',
  shop_i1: 'Shop Name',
  shop_i2: 'Seller Name',
  shop_i3: 'Address',
  shop_i4: 'Contact Email',
  shop_i5: 'What is your 3D printer model?',
  shop_i6: 'Fab365 models you want to use',
  shop_i7: '(Only for online sellers) Online Shop Address',

  common_w1: 'Currenly we do not offer a license that pays a sales fee per printout.',
  common_w2:
    'We recommend print the fab365 models before license purchase. Print quality may vary depending on printer conditions.',
  common_w3: 'NOT ALLOWED modifying the STL data. (But printing a smaller/larger size is ALLOWED)',
  common_w5: 'The Fab365 logo on the model cannot be removed.',

  common_duration:
    'The license purchase agreement is valid for 6 months. The contract ends after 6 months. If you wish to renew, you must sign a renewal contract.',
  common_paypal: "Payment of the license fee must be made to Fab365's paypal account.",
  common_email:
    'If you want to purchase the license, please fill out the following information and send an email to biz@fab365.net. Then We will guide you through the contract process.',

  edu_1: 'For customers who want to use the fab365 models for the purpose of education.',
  edu_2:
    'You pay a license fee of USD $20-50 per piece of content and can only be used for 6 months. (The exact content license fee varies from content to content.)',
  edu_i1: 'Organization Name',
  edu_i2: 'Manager Name',
  edu_i7: 'Photo showing 3D Printers. (Remove personnal information)',

  business1: 'For customers who want to sell the printed Fab365 models.',

  business2:
    "A commercial license for selling printed products can be purchased as a monthly subscription on Patreon's Fab365 page.",
  business3: 'The main benefits of subscribing are as follows:',

  business4: ' • Selling rights for printed products of all Fab365 designs',
  business5:
    '(Limited to STL purchased through the official route, excluding non-sellable designs)',
  business6: '• 3 New Designs every month from Fab365.net (includes 1 early access)',
  business7: '• 3 Designs in last month from Fab365.net',

  business8: 'Click the link for more information and subscribe to the license!',
  business9: 'For any questions, please contact info@fab365.net.',
};
export const BUSINESS_KO = {
  title: '개인적, 비상업적 외의 용도로 콘텐츠를 이용하고 싶은 고객님은 아래를 참조하시기 바랍니다.',
  h1: '출력물을 행사에서 이용하려는 경우에는 교육기관 라이센스를 참조하시기 바랍니다.',
  h_shop: '출력물 판매',
  h_edu: '교육기관',

  shop_1: 'Fab365의 콘텐츠를 출력해서 출력물의 판매를 원하는 고객에 해당합니다.',
  shop_2:
    '콘텐츠 1개당 USD $50~100 의 라이센스 비용을 지급하고 6개월 동안만 사용할 수 있습니다. (정확한 콘텐츠 라이센스 비용은 콘텐츠마다 달라집니다.)',
  shop_i1: '상점명',
  shop_i2: '담당자명',
  shop_i3: '주소',
  shop_i4: '연락처(이메일)',
  shop_i5: '사용하는 3D 프린터 정보',
  shop_i6: '원하는 Fab365 콘텐츠',
  shop_i7: '(온라인 판매일 경우에만 해당) 샵의 온라인 url 주소',

  common_w1: '출력물 1개 당 판매수수료를 지급하는 방식의 라이센스는 현재는 제공하지 않습니다.',
  common_w2:
    '콘텐츠를 미리 출력해보시기 바랍니다. 프린터, 콘텐츠의 상황에 따라 출력품질이 달라질 수 있습니다.',
  common_w3: 'STL 데이터의 변경은 불가능합니다. (크기 변경은 가능합니다)',
  common_w5: '모델에 있는 Fab365 로고는 제거할 수 없습니다.',

  common_duration:
    '라이센스 구매 계약의 유효 기간은 6개월 입니다. 6개월이 지나면 계약이 종료됩니다. 갱신을 원하시면 재계약을 체결해야 합니다.',
  common_paypal: '라이센스 비용 지급은 Fab365 의 paypal 계정으로 입금하셔야 합니다.',
  common_email:
    '라이센스 구매를 원하시면 biz@fab365.net 으로 아래의 내용을 기재해서 이메일을 보내주시면 계약 진행에 대해 안내드리겠습니다.',

  edu_1: '교육을 위해 Fab365의 콘텐츠를 이용하고 싶은 고객에 해당합니다.',
  edu_2:
    '콘텐츠 1개당 USD $20~50 의 라이센스 비용을 지급하고 6개월 동안만 사용할 수 있습니다. (정확한 콘텐츠 라이센스 비용은 콘텐츠마다 달라집니다.)',
  edu_i1: '교육기관명',
  edu_i2: '담당자명',
  edu_i7: '프린터가 보이는 사진 (개인정보 제거할 것)',
  business1: '인쇄된 Fab365 모델을 판매하려는 고객을 위한 것입니다.',

  business2:
    '인쇄 제품 판매를 위한 상업용 라이센스는 Patreon의 Fab365 페이지에서 월간 구독으로 구매할 수 있습니다.',
  business3: '구독 시 주요 혜택은 다음과 같습니다.',

  business4: '• 모든 Fab365 디자인의 인쇄물에 대한 판매 권한',
  business5: '(공식 루트를 통해 구매한 STL에 한함, 판매 불가 디자인 제외)',
  business6: '• 매월 Fab365.net에서 제공하는 3개의 새로운 디자인(1개의 얼리 액세스 포함)',
  business7: '• 지난 달 Fab365.net의 3가지 디자인',

  business8: '자세한 내용은 링크를 확인하시고, 라이센스를 구독하세요!',
  business9: '궁금한 사항은 info@fab365.net으로 문의하시기 바랍니다.',
};

export const loginSignupEn = {
  COMMON_ERROR_REQUEST_FAIL: 'The request has failed. Please try again later.',

  LOGIN_ERROR_EMAIL_UNSET: 'Enter your email',
  LOGIN_ERROR_EMAIL_INVALID: 'Enter a valid email address like john@fab365.net',
  LOGIN_ERROR_PASSWORD_UNSET: 'Passwords must be at least 6 characters',
  LOGIN_ERROR_PASSWORD_INCORRECT: 'Your password is incorrect',
  LOGIN_ERROR_PASSWORD_INCORRECT_COUNT: "Your password is incorrect: '%s'",
  LOGIN_ERROR_ACCOUNT_BLOCK: 'Your account has been temporarily blocked. Please try again later.',
  LOGIN_ERROR_NOT_VERIFIED_USER:
    'We have sent an account verification email to the email address you entered. Please log in after completing authentication.',

  LOGIN_ERROR_NOT_ACTIVE_USER: 'closed user',
  LOGIN_ERROR_PASSWORD_INVALID: 'Passwords do not match.',
  LOGIN_ERROR_PASSWORD_NEED: 'Password is empty',
  LOGIN_ERROR_USER_NOT_EXIST:
    'The email address you entered does not exist. Please enter another email address or make sure you have logged in with Facebook before.',

  MY_PROFILE_ERROR_CURRENT_PASSWORD_NOT_MATCH: 'Current password do not match',
  MY_PROFILE_ERROR_NEW_PASSWORD_NEED: 'New password is empty',

  FORGOT_PASSWORD_ERROR_USER_NOT_ACTIVE: 'user not active',

  SIGNUP_ERROR_PASSWORD_NEED: 'Password is empty',
  SIGNUP_ERROR_PASSWORD_NOT_VALID: 'Passwords max length is 36 charater',
  SIGNUP_ERROR_EXIST_EMAIL: 'Email address is already in use',
  SIGNUP_ERROR_EXIST_SNS_TOKEN: 'already signed',
  SIGNUP_ERROR_EXIST_USERID: 'userid exist',

  TICKET_ERROR_EXPIRED: 'ticket expired',
  TICKET_ERROR_NOT_ACTIVE: 'ticket not active',
  SIGNUP_ERROR_EMAIL_INVALID: 'Please enter a valid email address.',
};

export const loginSignupKo = {
  COMMON_ERROR_REQUEST_FAIL: 'The request has failed. Please try again later.',

  LOGIN_ERROR_EMAIL_UNSET: 'Enter your email',
  LOGIN_ERROR_EMAIL_INVALID: 'Enter a valid email address like john@fab365.net',
  LOGIN_ERROR_PASSWORD_UNSET: 'Passwords must be at least 6 characters',
  LOGIN_ERROR_PASSWORD_INCORRECT: 'Your password is incorrect',
  LOGIN_ERROR_PASSWORD_INCORRECT_COUNT: "Your password is incorrect: '%s'",
  LOGIN_ERROR_ACCOUNT_BLOCK: 'Your account has been temporarily blocked. Please try again later.',

  LOGIN_ERROR_NOT_VERIFIED_USER:
    'We have sent an account verification email to the email address you entered. Please log in after completing authentication.',

  LOGIN_ERROR_NOT_ACTIVE_USER: 'closed user',
  LOGIN_ERROR_PASSWORD_INVALID: '암호가 일치하지 않습니다.',
  LOGIN_ERROR_PASSWORD_NEED: '암호를 입력해야 합니다',
  LOGIN_ERROR_USER_NOT_EXIST:
    '당신이 입력한 이메일 주소가 존재하지 않습니다. 또 다른 이메일주소를 입력하거나, 이전에 페이스북으로 로그인했는지 확인해주세요.',

  MY_PROFILE_ERROR_CURRENT_PASSWORD_NOT_MATCH: '기존 암호가 일치하지 않습니다',
  MY_PROFILE_ERROR_NEW_PASSWORD_NEED: '새 암호를 입력해야 합니다',

  FORGOT_PASSWORD_ERROR_USER_NOT_ACTIVE: 'user not active',

  SIGNUP_ERROR_PASSWORD_NEED: '암호를 입력해야 합니다',
  SIGNUP_ERROR_PASSWORD_NOT_VALID: '암호는 최대 36자 입니다',
  SIGNUP_ERROR_EXIST_EMAIL: 'Email address is already in use',
  SIGNUP_ERROR_EXIST_SNS_TOKEN: 'already signed',
  SIGNUP_ERROR_EXIST_USERID: 'userid exist',

  TICKET_ERROR_EXPIRED: 'ticket expired',
  TICKET_ERROR_NOT_ACTIVE: 'ticket not active',
  SIGNUP_ERROR_EMAIL_INVALID: '올바른 형식의 이메일 주소를 입력하세요.',
};
