import { sanitize } from 'dompurify';
import { useContext, useEffect, useState } from 'react';
import CommentBody from '../../../component/comment/CommentBody';
import CommentHeader from '../../../component/comment/CommentHeader';
import CommentReply from '../../../component/comment/CommentReply';
import UserContext from '../../../context/user/user';
import Pagination from '../../Category/component/Pagination';
import ItemCommentReplyWrite from './ItemCommentReplyWrite';
import { deleteItemComment, editItemComment } from '../../../action/request';
import { RETURN_CODE } from '../../../action/constant';
import { useRef } from 'react';

const ItemComment = ({
  id,
  comments,
  count,
  page,
  onCommentPageConfirm,
  onReload,
  setShowImgViewer,
  setImgData,
  showProduct,
}) => {
  const [isReply, setIsReply] = useState(false);
  const [showReply, setShowReply] = useState([]);
  const { state } = useContext(UserContext);
  // console.log('comments', comments);
  const pageChange = value => {
    onCommentPageConfirm && onCommentPageConfirm(value);
  };
  const pageConfirm = value => {
    onCommentPageConfirm && onCommentPageConfirm(value);
  };

  const uploadCommentImage = async () => {};
  const createComment = async () => {};
  const editComment = async ({ comment, msg, medias, imgs }) => {
    try {
      const { data: res } = await editItemComment({
        comment,
        msg,
        medias,
        imgs,
      });
      if (res.returnCode === RETURN_CODE.C00000) {
        onReload();
      }
    } catch (e) {
      alert('request failed');
    }
  };

  const deleteComment = async ({ oidComment }) => {
    try {
      if (window.confirm('Delete?')) {
        const { data: res } = await deleteItemComment({
          oidComment,
        });
        if (res.returnCode === RETURN_CODE.C00000) {
          onReload();
        }
      }
    } catch (e) {
      alert('request failed');
    }
  };

  const createReply = async () => {};

  const showCommentReply = idx => {
    const showList = [...showReply];
    showList[idx] = { ...showList[idx], show: true };
    setShowReply(showList);
    const target = showList[idx]?.target;
    if (target) {
      target.current.scrollIntoView();
    }
  };

  const setCommentReply = (target, idx) => {
    setShowReply(sr => {
      const showList = [...sr];
      showList[idx] = { target, show: false };
      return showList;
    });
  };

  // 리플 박스 모두 닫기 처리
  const resetCommentReply = () => {
    const newArr = [...showReply];
    newArr.forEach(reply => {
      if (reply !== undefined) {
        reply.show = false;
      }
    });
    setShowReply(newArr);
  };

  useEffect(() => {
    resetCommentReply();
  }, [comments]);

  return (
    <>
      <div className='store-detail-tab-content active' id='store_detail_comments'>
        <div className='container'>
          <div className='store-detail-content-block'>
            <div className='store-detail-content-block-left'>
              <div className='comments'>
                {state.isLogin && true && showProduct !== 'secret' && (
                  <CommentHeader
                    thumbnail={state.thumbnail}
                    id={id}
                    onReload={onReload}
                    showProduct={showProduct}
                  />
                )}
                <div className='comments-body'>
                  <div className='comment-list'>
                    <ul>
                      {comments?.map((c, idx) => {
                        return (
                          <li key={c.id_comment}>
                            <div
                              className='comment-list-item'
                              data-json={sanitize(
                                JSON.stringify({
                                  comment: c.id_comment,
                                  root: c.id_item,
                                })
                              )}
                            >
                              <CommentBody
                                comment={c}
                                pkComment='id_comment'
                                onDelete={() => deleteComment({ oidComment: c.id_comment })}
                                showReply={() => showCommentReply(idx)}
                                onEdit={editComment}
                                setShowImgViewer={setShowImgViewer}
                                setImgData={setImgData}
                                showProduct={showProduct}
                              />
                              {c['replys'] && c['replys'].length > 0 && (
                                <CommentReply
                                  replys={c['replys']}
                                  showReply={showCommentReply}
                                  onEdit={editComment}
                                  onDelete={deleteComment}
                                  idx={idx}
                                  setCommentReply={setCommentReply}
                                  pkComment={'id_comment'}
                                  setShowImgViewer={setShowImgViewer}
                                  setImgData={setImgData}
                                  showProduct={showProduct}
                                />
                              )}

                              {showReply[idx]?.show && (
                                <ItemCommentReplyWrite
                                  thumbnail={state.thumbnail}
                                  onReload={onReload}
                                  root={id}
                                  parent={c.id_comment}
                                />
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <Pagination
                      page={page}
                      total={Math.ceil(count / 10)}
                      onpagechange={pageChange}
                      onconfirm={pageConfirm}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='store-detail-content-block-right'>
              <div className='store-detail-notice'>
                <h3>Notice !</h3>
                <p>
                  Feel free to talk about your product.
                  <br />
                  Comments that are irrelevant to the topic may be deleted
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemComment;
