import React, { useEffect } from 'react';
import { SocialButton } from '../styles';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../../action/request';

function Github() {
  const location = useLocation();
  const currentPath = location.pathname;

  const onClickGithub = e => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `${baseUrl}sns/github/callback`;
    // 로그인할 때 현재 위치 로컬스토리지에 저장
    localStorage.setItem('loginPath', currentPath);
  };

  return (
    <SocialButton id='github-signup' className='btn btn-full social-button' onClick={onClickGithub}>
      <img src='/assets/img/login/github_new.png' alt='Github' />
    </SocialButton>
  );
}

export default Github;
