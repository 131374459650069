import { useRecoilState } from 'recoil';
import { followStatusAtom, followIdAtom } from '../../../atom/atom';
import DesignersHeader from '../../../component/designers/Header/DesignersHeader';
import DesignerSectionBody from './DesignerSectionBody';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../context/user/user';

const DesignerSectionContent = ({ designerList, setDesignerList, NewFollowData }) => {
  // 기본값 설정
  const [isFollow, setIsFollow] = useRecoilState(followStatusAtom);
  const { state: userData } = useContext(UserContext);
  const [followId, setFollowId] = useRecoilState(followIdAtom);

  function makeFollowStatus() {
    const followStatusList = designerList?.map(item => item.designer.my_follow !== 0);
    setIsFollow(followStatusList);
  }
  useEffect(() => {
    makeFollowStatus();
  }, [designerList, setIsFollow]);

  function updateFollow() {
    const result = designerList?.map(item =>
      Number(item.designer.id_studio) === Number(followId.id)
        ? {
            designer: {
              ...item.designer,
              follow_count: followId.isFollow
                ? Number(item.designer.follow_count) + 1
                : Number(item.designer.follow_count) - 1,
              my_follow: followId.isFollow ? 1 : 0,
            },
            items: item.items,
          }
        : item
    );

    setDesignerList(result);
    setFollowId(null);
  }

  useEffect(() => {
    if (followId) {
      updateFollow();
    }
  }, [followId]);

  const DesignerSectionPropsStyle = {
    designerSection: true,
    borderRadius: 10,
    padding: '0px 23px 1px',
    descriptionNone: true,
    headerPadding: '15px 0',
    viewDesignerList: true,
  };

  return (
    <div style={{ marginTop: 25 }}>
      {designerList.length > 0 ? (
        designerList?.map((item, index) => (
          <div key={index}>
            <DesignersHeader
              data={item.designer}
              setIsFollow={newFollowStatus => {
                if (userData.isLogin) {
                  const updatedFollowStatus = [...isFollow];
                  updatedFollowStatus[index] = newFollowStatus;
                  setIsFollow(updatedFollowStatus);
                }
              }}
              isFollow={isFollow && isFollow[index]}
              designerSection={true}
              DesignerSectionPropsStyle={DesignerSectionPropsStyle}
              NewFollowData={NewFollowData}
              index={index}
              setFollowId={setFollowId}
              value={'designerList'}
            />
            <DesignerSectionBody
              data={item?.items}
              locationData={index}
              index={index}
              designerId={item?.designer?.id_studio}
            />
          </div>
        ))
      ) : (
        <div>
          <DesignersHeader
            data={{}} // 빈 데이터로 기본 UI 렌더링
            setIsFollow={() => {}}
            isFollow={false}
            designerSection={true}
            DesignerSectionPropsStyle={DesignerSectionPropsStyle}
            NewFollowData={() => {}}
            index={0}
          />
          <DesignerSectionBody data={{}} locationData={'DesignerSectionBody'} />
        </div>
      )}
    </div>
  );
};

export default DesignerSectionContent;
