import styled from 'styled-components';

const DiscountPrice = ({
  finalPrice,
  discountRate,
  originPrice,
  detailPage,
  cartProduct,
  fontSize,
  spanFontSize,
}) => {
  return (
    <DiscountWrapper
      detailPage={detailPage}
      cartProduct={cartProduct}
      fontSize={fontSize}
      spanFontSize={spanFontSize}
    >
      {finalPrice !== '0.00' && <p> {cartProduct ? `${finalPrice}` : `$${finalPrice}`}</p>}

      {discountRate !== '0' && (
        <>
          <span>{`$${originPrice}`}</span>
          <div>{`${discountRate}%`}</div>
        </>
      )}
    </DiscountWrapper>
  );
};

export default DiscountPrice;

const DiscountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: ${props => props.cartProduct && 'flex-end'};
  flex-direction: ${props => (props.cartProduct ? 'row-reverse' : '')};

  p {
    font-size: ${props => props.fontSize};
  }

  span,
  div {
    font-size: 16px;
    font-size: ${props => props.spanFontSize};
  }
  span {
    text-decoration: line-through;
    color: #8c96a0;
  }

  @media only screen and (max-width: 360px) {
    p {
      font-size: 15px;
    }
    span,
    div {
      font-size: 14px;
    }
  }

  div {
    color: rgb(31, 190, 5);
  }

  @media screen and (max-width: 480px) {
    gap: 5px;
  }
`;
