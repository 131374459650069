import { TAB_ORDER } from '../../component/constant';
import MyTab from '../../component/MyTab';
import moment from 'moment';
import { fileSize } from '../../../../util/file';
import { useEffect, useState } from 'react';
import OrderBill from './OrderBill';
import { getOrderDetail } from '../../../../action/request';
import { useNavigate } from 'react-router-dom';
import { ImgStyled } from '../../../../styles/ImgStyled';
import { ITEMS } from '../../../../route/constant';
const OrderDetail = ({ oid, setStep, setItemId }) => {
  const [isFree, setIsFree] = useState(false);
  const [order, setOrder] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const fetchData = async () => {
    try {
      const {
        data: { data: resOrderDetail },
      } = await getOrderDetail({
        oid,
      });
      setOrder(resOrderDetail);
      // console.log('디테일', resOrderDetail);

      const frees = resOrderDetail.orderItems.filter(oi => {
        return oi.price_usd <= 0;
      });
      setIsFree(frees.length > 0);
    } catch (e) {}
  };

  const goToItemDetailPage = (e, productId) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(ITEMS + '/' + productId);
  };

  const clickDownload = async id => {
    setStep(1);
    setItemId(id);
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(path);
  };

  // 초기 ui 깜빡임 오류 수정
  if (!order.orderno) return;

  return (
    <>
      <div className='page-top mypage'>
        <div className='container'>
          <h1 className='page-title align-left'>My Page</h1>
        </div>
      </div>

      <MyTab tab={TAB_ORDER} />

      <section className='mypage-section'>
        <div className='container'>
          <div className='content-box orders'>
            <div className='content-box-header'>
              <h2>
                Your Order
                {order.pay?.status === 'order_pending' && ' - Processing payment'}
              </h2>
            </div>
            <div className='content-box-body'>
              <div className='order-detail clearfix'>
                <div className='your-order-meta'>
                  <dl>
                    <dt>Order Number</dt>
                    <dd>
                      <span className='txt-green'>#{order.orderno}</span>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Order Date</dt>
                    <dd>{moment(order.date_order).format('MMMM DD YYYY')}</dd>
                  </dl>
                </div>

                <div className='mypage-order-detail-left'>
                  <div className='purchase-product-list my-order-list per-one'>
                    <ul className='clearfix'>
                      {order?.orderItems?.map(oi => {
                        const size = fileSize(oi.item.all_file_size);
                        return (
                          <li key={oi.id_item}>
                            <div className='purchase-product-list-item'>
                              <div className='purchase-product-list-item-inner'>
                                <div
                                  className='product-click-pointer'
                                  onClick={e => goToItemDetailPage(e, oi.id_item)}
                                >
                                  <ImgStyled
                                    src={oi.item.list_img}
                                    className='tb '
                                    alt=''
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                                <div className='product-meta'>
                                  <span
                                    className='product-name  product-click'
                                    onClick={e => goToItemDetailPage(e, oi.id_item)}
                                  >
                                    {oi.item.name_en}
                                  </span>
                                  <span className='price'>
                                    {isFree ? 'REWARD' : '$ ' + oi.price_usd}
                                  </span>
                                  <span className='file-size'>
                                    {size.size.toFixed(1) + '' + size.unit}
                                  </span>
                                  {order.pay?.status === 'order_paid' && (
                                    <button
                                      className='btn btn-sm btn-download'
                                      onClick={() => {
                                        clickDownload(oi?.id_item);
                                      }}
                                    >
                                      Download
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {order.pay?.status === 'order_paid' &&
                  (isFree ? (
                    <>
                      <div className='mypage-order-detail-right'>
                        <div className='your-order-box m-b-sm'>
                          <h3>Reward recieved</h3>
                          <div className='your-order-info'>
                            <div className='price-info'>
                              <dl>
                                <dd>
                                  <span className='txt-red' style={{ fontSize: '16px' }}>
                                    {/* 구매시 소진된 티켓 수 표시 */}
                                    {order.orderItems[0].item.used_qty.split('.')[0]} Reward ticket
                                    used
                                  </span>
                                </dd>
                              </dl>
                            </div>
                            <dl>
                              <dt>Total</dt>
                              <dd>
                                <strong className='total-price'>$ 0.00</strong>
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div className='text-right'>
                          <a href='' onClick={e => goTo(e, '/my/orders')}>
                            Go to order list &gt;
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    <OrderBill pay={order.pay} order={order} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderDetail;
