/* eslint-disable jsx-a11y/iframe-has-title */
import { useState } from 'react';
import Slider from 'react-slick';
import uuid from 'react-uuid';
import { ImgStyled } from '../../styles/ImgStyled';
const Gallery = ({ imgs, isRequest, studioName }) => {
  const [index, setIndex] = useState(0);

  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 7,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const clickTbs = (e, idx) => {
    e.stopPropagation();
    e.preventDefault();
    setIndex(idx);
  };
  const stop = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  if (!imgs) return <></>;
  return (
    <>
      <div className='store-detail-gallery-desktop'>
        <div className='large-img'>
          <a
            //뒤로가기 버튼 눌렀을때 새창으로 열리는 버그가 발생하여 해당 부분 주석처리
            // href={imgs[index]?.fullscreen}
            data-index={index}
            className='show-image-viewer'
            onClick={stop}
            onContextMenu={e => {
              e.preventDefault();
            }}
          >
            {imgs[0]?.type === 'img' && (
              <>
                {isRequest && (
                  <ImgStyled style={{ cursor: 'pointer' }} src={imgs[index]?.url} alt='' />
                )}
                {!isRequest && studioName && (
                  <>
                    <ImgStyled src={imgs[index]?.img} alt='' />
                    <div className='watermark'>
                      <span>✕ {studioName}</span>
                      <ImgStyled src='/assets/img/icon/watermark_final.png' alt='watermark' />
                    </div>
                  </>
                )}
              </>
            )}
            {/* 이미지 0번째 데이터에 type이 없는 경우 wartermark bType (예시 128아이템) */}
            {imgs[0]?.type === undefined && (
              <>
                {isRequest && (
                  <ImgStyled style={{ cursor: 'pointer' }} src={imgs[index]?.url} alt='' />
                )}
                {!isRequest && studioName && (
                  <>
                    <ImgStyled src={imgs[index]?.img} alt='' />
                    <div className='watermark bType'>
                      <span>✕ {studioName}</span>
                      <ImgStyled src='/assets/img/icon/watermark.png' alt='watermark' />
                    </div>
                  </>
                )}
              </>
            )}
            {imgs[index]?.type === 'video' && (
              <a>
                <div className='vc'>
                  <iframe
                    src={'https://youtube.com/embed/' + imgs[index]?.url}
                    frameborder='0'
                    allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                    allowfullscreen
                    style={{ width: '100%', height: '100%' }}
                  ></iframe>
                </div>
              </a>
            )}
          </a>
        </div>
        <div className='tbs'>
          <div className='slider'>
            <div className='slides'>
              <Slider {...settings}>
                {imgs.map((img, idx) => {
                  if (isRequest) {
                    return (
                      <a
                        href={img?.thumbs[0]?.url}
                        data-large-img={img?.thumbs[0]?.url}
                        data-index={idx}
                        className={'slide' + (idx === index ? ' active' : '')}
                        data-type={img?.type}
                        key={uuid()}
                        onClick={e => clickTbs(e, idx)}
                        onContextMenu={e => {
                          e.preventDefault();
                        }}
                      >
                        <span className='tb'>
                          <ImgStyled src={img.thumbs[0]?.url} alt='' className='request' />
                        </span>
                      </a>
                    );
                  }
                  if (img?.type === 'video') {
                    return (
                      <a
                        href={img.img}
                        data-large-img={img.img}
                        data-index={idx}
                        className={'slide' + (idx === index ? ' active' : '')}
                        data-type={img?.type}
                        key={uuid()}
                        onClick={e => clickTbs(e, idx)}
                      >
                        <span className='tb'>
                          <ImgStyled src={`//img.youtube.com/vi/${img.img}/0.jpg`} alt='' />
                        </span>
                      </a>
                    );
                  }
                  return (
                    <a
                      data-large-img={img?.img}
                      data-index={idx}
                      className={'slide' + (idx === index ? ' active' : '')}
                      data-type={img?.type}
                      key={uuid()}
                      onClick={e => clickTbs(e, idx)}
                      onContextMenu={e => {
                        e.preventDefault();
                      }}
                    >
                      <span className='tb'>
                        <ImgStyled src={img?.img} alt='' className='request' />
                      </span>
                    </a>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className='store-detail-gallery-mobile'>
        <div className='slider'>
          <div className='slides'>
            <Slider
              {...{
                arrows: false,
                dots: true,
              }}
            >
              {imgs?.map((img, idx) => {
                if (img?.type === 'video') {
                  return (
                    <div className='slide'>
                      <div className='vc'>
                        <iframe
                          src={`https://youtube.com/embed/${img?.img}`}
                          frameborder='0'
                          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  );
                }
                return (
                  <a
                    href={img?.img}
                    data-large-img={img?.img}
                    data-index={idx}
                    key={uuid()}
                    onClick={stop}
                    className='slide show-image-viewer'
                    onContextMenu={e => {
                      e.preventDefault();
                    }}
                  >
                    <ImgStyled src={img?.url || img?.img} alt='' />
                  </a>
                );
              })}
            </Slider>
          </div>
          {!isRequest && studioName && imgs[index]?.type === 'img' && (
            <div className='watermark-mobile'>
              <span>✕ {studioName}</span>
              <ImgStyled
                style={{ pointerEvents: 'none' }}
                src='/assets/img/icon/watermark_final.png'
                alt='watermark'
              />
            </div>
          )}
          {!isRequest && studioName && imgs[index]?.type === undefined && (
            <div className='watermark-mobile bType'>
              <span>✕ {studioName}</span>
              <ImgStyled
                style={{ pointerEvents: 'none' }}
                src='/assets/img/icon/watermark.png'
                alt='watermark'
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Gallery;
