import { useContext, useState } from 'react';
import { MY_REVIEW_WRITE, REVIEW } from '../../../route/constant';
import { getMainThumb } from '../../../util/image';
import Pagination from '../../Category/component/Pagination';
import ReviewList from '../../Review/component/ReviewList';
import ModalContext from '../../../context/modal/modal';
import { ls } from '../../../constant';
import { useNavigate } from 'react-router-dom';
import { getSelectReviewItems } from '../../../action/request';

const ItemReview = ({
  id,
  reviews,
  count,
  onReviewPageChange,
  onReviwPageConfirm,
  page,
  showProduct,
}) => {
  const { action: modalAction } = useContext(ModalContext);
  const navigate = useNavigate();
  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  const pageChange = value => {
    onReviwPageConfirm && onReviwPageConfirm(value);
  };
  const pageConfirm = value => {
    onReviwPageConfirm && onReviwPageConfirm(value);
  };
  const onClickWriteReview = async () => {
    if (!isLoginUsingToken) {
      modalAction.setIsLogin(true);
    } else {
      const { data: resultReivews } = await getSelectReviewItems(id);
      if (resultReivews?.returnCode === 'C00008') {
        alert(resultReivews?.message);
      } else {
        navigate(`${MY_REVIEW_WRITE}/${id}`);
      }
    }
  };

  // console.log('reviews', reviews);
  return (
    <>
      <div className='store-detail-tab-content active' id='store_detail_review'>
        <div className='container'>
          {showProduct !== 'secret' && (
            <div className='review-cta'>
              <p>did you make it? please write making story !</p>
              <div
                onClick={onClickWriteReview}
                id='writeReviewButton'
                className='btn btn-green btn-fill'
              >
                Write Review
              </div>
            </div>
          )}

          <div className={showProduct !== 'secret' && 'review-lis'}>
            <div className='review-list-header'>
              <select className='form-input'>
                <option value=''>Newest</option>
              </select>
            </div>

            <div className='review-list-body'>
              <ReviewList data={reviews} />
              <Pagination
                page={page}
                total={Math.ceil(count / 8)}
                onpagechange={pageChange}
                onconfirm={pageConfirm}
              />

              {/* <?php echo PagingRenderer::renderItem($paging, EnumUrl::ITEM . "/" . $idItem, "reviewPage", "#review"); ?> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemReview;
