import { useEffect, useState } from 'react';
import { isVideo } from '../../util/itemData';
import Gallery from '../gallery/Gallery';
import './ItemSlide.css';
const ItemSlide = ({ data, studioName }) => {
  return (
    <>
      <div className='store-detail-gallery'>
        <div className='gallery-item'>
          <Gallery imgs={data} studioName={studioName} />
        </div>
      </div>
    </>
  );
};

export default ItemSlide;
