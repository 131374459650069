import React, { useRef, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DesignersNav from '../Nav/DesignersNav';
import { isMobile } from 'react-device-detect';
import Followrs from './Followrs';
import Models from './Models';
import Reviews from './Reviews';
import Comments from './Comments';
import MySales from './MySales';
import Dropdown from '../Dropdown';
import UserContext from '../../../context/user/user';
import { useRecoilState } from 'recoil';
import {
  designerPaginationAtom,
  selectedTabAtom,
  selectedSortAtom,
  seletecdMoreAtom,
  seletecdYearAtom,
  commentPrevAtom,
} from '../../../atom/atom';
import { Contents } from '../designers';
import moment from 'moment';

const DesignersContents = ({ designerURL, designerId }) => {
  const scrollRef = useRef(null);
  const { state } = useLocation();
  const initalValue = { title: 'Latest', value: 'Latest' };
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabAtom);
  const [selectedSort, setSeletedSort] = useRecoilState(selectedSortAtom);
  const [, setPageNation] = useRecoilState(designerPaginationAtom);
  const { state: userState } = useContext(UserContext);
  const [, setSelectedMore] = useRecoilState(seletecdMoreAtom);
  const [, setPrevComment] = useRecoilState(commentPrevAtom);

  const [, setSelectYear] = useRecoilState(seletecdYearAtom);
  const scrollD = localStorage.getItem('scrollDesigner');

  const ModelsSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Most Downloaded', value: 'Downloads' },
    { title: 'Most Commented', value: 'Comment' },
    { title: 'Most Liked ', value: 'Like' },
  ];

  const FollowerSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Top Downloaders', value: 'Download' },
  ];

  const ReviewSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Most Commented', value: 'Comment' },
    { title: 'Most Liked', value: 'Like' },
  ];

  const sortNone = selectedTab === 2 || selectedTab === 'my';

  useEffect(() => {
    if (selectedTab === 'my' && userState.idStudio !== designerURL) {
      setSelectedTab(0);
    }
  }, [designerURL]);

  const moveScroll = () => {
    const moveTo = isMobile ? 200 : 150;
    window.scrollTo({ top: scrollRef.current.offsetTop - moveTo });
  };

  const onClickTab = nav => {
    setSelectedTab(nav === 'my' ? nav : nav.value);
    setPageNation(1);
    setPrevComment('null');
    setSeletedSort(initalValue);

    if (selectedTab !== 'my') {
      setSelectedMore('my');
    }

    setSelectYear(moment().format('YYYY'));
    moveScroll();
  };

  function sortData(tab) {
    if (tab === 0) {
      return ModelsSortOption;
    } else if (tab === 1) {
      return FollowerSortOption;
    } else if (tab === 3) {
      return ReviewSortOption;
    }
  }

  const onChangeSort = sort => {
    setSeletedSort(sort);
    setPageNation(1);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: scrollD });
    }, 800);
  }, [scrollD]);

  return (
    <div>
      <div ref={scrollRef} id='nav' className='designerNav'>
        <DesignersNav
          selectedTab={selectedTab}
          onClickTab={onClickTab}
          userState={userState}
          designerURL={designerURL}
        />

        {sortNone ? (
          ''
        ) : (
          <Dropdown
            options={sortData(selectedTab)}
            onChangeSort={onChangeSort}
            selectedSort={selectedSort}
          />
        )}
      </div>

      <Contents className='container'>
        {selectedTab === 0 ? (
          <Models
            moveScroll={moveScroll}
            selectedSort={selectedSort}
            designerURL={designerURL}
            selectedTab={selectedTab}
          />
        ) : selectedTab === 1 ? (
          <Followrs selectedSort={selectedSort} moveScroll={moveScroll} data={designerURL} />
        ) : selectedTab === 2 ? (
          <Comments
            userState={userState}
            designerURL={designerURL}
            designerId={designerId}
            scrollRef={scrollRef}
            isMobile={isMobile}
            moveScroll={moveScroll}
          />
        ) : selectedTab === 3 ? (
          <Reviews
            moveScroll={moveScroll}
            value='review'
            selectedSort={selectedSort}
            designerURL={designerURL}
          />
        ) : (
          <MySales moveScroll={moveScroll} />
        )}
      </Contents>
    </div>
  );
};

export default DesignersContents;
