import React, { useContext, useEffect, useState } from 'react';
import { getCategories, getDesigner, getFreeLimit } from '../../action/request';
import Layout from '../../component/layout/Layout';
import Categoryist from './component/CategoryList';
import Pagination from './component/Pagination';
import DesignerList from './component/DesignerList';
import UserContext from '../../context/user/user';
import styled from 'styled-components';
import { ls } from '../../constant';
import { ImgStyled } from '../../styles/ImgStyled';
import { selectedTabAtom } from '../../atom/atom';
import { useSetRecoilState } from 'recoil';

const SpecialDesigners = () => {
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(Number(localStorage.getItem(ls.specialDesignersPage)) || 1);
  const [, setLimit] = useState({});
  const [designers, setDesigners] = useState({ count: 0 });
  const { state } = useContext(UserContext);
  const setSelectedTab = useSetRecoilState(selectedTabAtom);

  useEffect(() => {
    fetchData();
    //디자이너 페이지로 진입시 tab 초기화
    setSelectedTab(0);

    // 디자이너 아이템 리스트 페이지네이션 초기화
    localStorage.removeItem(ls.designerItemListPage);
  }, []);

  // 로그인 상태 변경때마다 fetchData() 호출
  useEffect(() => {
    fetchData();
  }, [state.isLogin]);

  const fetchData = async () => {
    try {
      const { data: categories } = await getCategories();
      setCategories(categories.data);

      const { data: resFreeLimit } = await getFreeLimit();
      setLimit(resFreeLimit.data);

      updateListPage(page);
    } catch (error) {}
  };

  const pageChange = async value => {
    updateListPage(value);
  };

  const pageConfirm = value => {
    updateListPage(value);
  };

  const updateListPage = async value => {
    setPage(value);
    localStorage.setItem(ls.specialDesignersPage, value);
    // 디자이너 리스트만 조회
    const { data: designersData } = await getDesigner({ page: value });
    setDesigners(designersData.data);
    // console.log(value, designersData.data);
  };

  return (
    <>
      <Layout>
        <div id='main'>
          <div className='page-top page-top-with-breadcrumb'>
            <div className='container container-lg'>
              <h1 className='page-title'>Special Designers</h1>
            </div>
          </div>

          <div className='main-content-with-aside'>
            <Categoryist current={'Special Designers'} data={categories.items} />
            <div className='main-content'>
              <section className='store-collection'>
                <TopBannerContainer>
                  <ImgStyled
                    alt=''
                    className='specialDesigners-topbanner-pc'
                    src='/assets/img/banner/s1_specialdesigner_topbanner_pc.jpg'
                  />
                  <ImgStyled
                    alt=''
                    className='specialDesigners-topbanner-mo'
                    src='/assets/img/banner/s1_specialdesigner_topbanner_mo.jpg'
                  />
                </TopBannerContainer>
                <div className='container'>
                  <ContentSectionDiv
                    className='content-section content-section-has-top-nav'
                    style={{ minHeight: '900px' }}
                  >
                    <div className='featured-collections'>
                      <DesignerList data={designers} />
                    </div>
                    <Pagination
                      page={page}
                      total={Math.ceil(designers.count / 5)}
                      onpagechange={pageChange}
                      onconfirm={pageConfirm}
                    />
                  </ContentSectionDiv>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SpecialDesigners;

const TopBannerContainer = styled.div`
  height: 260px;
  padding: 0 1.5rem;
  padding-top: 5rem;

  img {
    width: 100%;
    height: 100%;
  }

  .specialDesigners-topbanner-mo {
    display: none;
  }

  @media screen and (max-width: 768px) {
    height: 257px;
    padding: 0;
    padding-top: 2.5rem;

    .specialDesigners-topbanner-pc {
      display: none;
    }
    .specialDesigners-topbanner-mo {
      display: inline-block;
    }
  }
`;

const ContentSectionDiv = styled.div`
  padding-top: 60px !important;

  @media screen and (max-width: 768px) {
    padding-top: 20px !important;
  }
`;
