import { useContext, useEffect } from "react";
import Layout from "../../component/layout/Layout";
import LangContext from "../../context/lang/lang";
import PrivacyEn from "./component/PrivacyEn";
import PrivacyKo from "./component/PrivacyKo";

const Privacy = () => {
  const { action } = useContext(LangContext);

  return (
    <>
      <Layout>
        <div id="main">{action.isKo() ? <PrivacyKo /> : <PrivacyEn />}</div>
      </Layout>
    </>
  );
};

export default Privacy;
