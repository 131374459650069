import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import Layout from '../../component/layout/Layout';
import d from '../../component/layout/Layout';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import DesignersHeader from '../../component/designers/Header/DesignersHeader';
import DesignersContents from '../../component/designers/Contents/DesignersContents';
import { getDesignerInfoName, getDesignerInfoId } from '../../action/request';
import { DesignersContainer } from '../../component/designers/designers';

import { useRecoilState } from 'recoil';
import { followStatusAtom, commentTotalCountAtom, preDesignerListAtom } from '../../atom/atom';
import UserContext from '../../context/user/user';

const OnlyDesigners = () => {
  const { state } = useLocation();
  const { state: location } = useLocation();
  const fromDesignerSection = location?.location === 'fromDesignerSection';
  const { designerURL } = useParams();
  const [designerInfo, setDesignerInfo] = useState();
  const [followValue, setFollowValue] = useState();
  const { state: userData } = useContext(UserContext);
  const isLogin = userData?.isLogin;
  const isDesigner = userData?.isDesigner;
  const [isFollow, setIsFollow] = useRecoilState(followStatusAtom);
  const [totalCount, setTotalCount] = useRecoilState(commentTotalCountAtom);
  //list가 유지되는 조건의 atom
  const [preDesignerList, setPreDesignerList] = useRecoilState(preDesignerListAtom);
  useEffect(() => {
    if (state?.id) {
      fetchDeignerInfoId();
    } else {
      fetchDeignerInfoName();
    }
    setTotalCount(false);
  }, [designerURL, state?.id, isFollow, isLogin]);

  const fetchDeignerInfoName = async () => {
    try {
      const { data: designerData } = await getDesignerInfoName({ isLogin, designerURL });
      if (designerData.returnCode === 'C00000') {
        setDesignerInfo(designerData.data);
        setIsFollow(designerData.data.my_follow !== 0 ? true : false);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDeignerInfoId = async () => {
    try {
      const studioId = state.id;
      const { data: designerData } = await getDesignerInfoId({ isLogin, studioId });
      if (designerData.returnCode === 'C00000') {
        setDesignerInfo(designerData.data);
        setIsFollow(designerData.data.my_follow !== 0 ? true : false);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setPreDesignerList(fromDesignerSection);
  }, []);

  return (
    <Layout>
      <Webpage2StylesContainer>
        <DesignersContainer height={designerInfo ? '100%' : '100vh'}>
          {designerInfo && (
            <>
              <DesignersHeader
                data={designerInfo}
                designerURL={designerURL}
                id={state?.id}
                setIsFollow={setIsFollow}
                isFollow={isFollow}
                followValue={followValue}
                setFollowValue={setFollowValue}
              />
              <DesignersContents
                designerURL={designerInfo?.id_studio}
                isDesigner={isDesigner}
                designerId={designerInfo?.id_designer}
              />
            </>
          )}
        </DesignersContainer>
      </Webpage2StylesContainer>
    </Layout>
  );
};

export default OnlyDesigners;
