import { PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react';
import {
  createCheckoutPayment,
  createCompletePaypalInPoint,
  createPaypalInPoint,
} from '../../../../action/request';
import { PROCESSING_PAYMENT } from './constant';
import { useNavigate } from 'react-router';
import { gtagCheckoutProgress, gtagException, gtagPurchase } from '../../../../action/gTag';
import { ls } from '../../../../constant';

function Paypal({
  checkReadyForPay,
  setIsLoading = () => {},
  onRefresh = () => {},
  setIsProcessing = () => {},
  setCheckoutMessage = null,
  requestId = null,
}) {
  const navigate = useNavigate();

  const onClick = async () => {
    const currentChargePoint = localStorage.getItem(ls.chargePoint);
    // gtag 페이팔 구매 버튼 클릭 추적 태그
    gtagCheckoutProgress(Number(localStorage.getItem(ls.totalAmount)));
    if (checkReadyForPay()) {
      if (currentChargePoint) {
        setIsLoading(true);
      } else {
        setCheckoutMessage('');
      }
    } else {
      return false;
    }
  };

  const onSubmit = async () => {
    const currentChargePoint = localStorage.getItem(ls.chargePoint);
    // console.log('[point] step1. 결제 point 값 : ', currentChargePoint);

    if (currentChargePoint) {
      // my point 페이지
      try {
        const { data } = await createPaypalInPoint(currentChargePoint);
        // console.log('[point] step1. 결제 api 호출 후 data : ', data);
        setIsLoading(false);
        return data.data.token;
      } catch (e) {
        // console.log('[point] step1. 결제 api 호출 후 error : ', e);
        setIsLoading(false);
        if (e.message) {
          // gtag 구매 과정 에러 추적 태그
          gtagException(e.message);
          return alert(e.message);
        }
      }
    } else {
      // checkout 페이지
      // console.log('[checkout] step1. 결제 requestId 값 : ', requestId);
      try {
        const { data } = await createCheckoutPayment('checkoutRequest-create', {
          method: 'paypal',
          requestId,
        });
        // console.log('[checkout] step1. 결제 api 호출 후 data : ', data);
        setCheckoutMessage('');
        return data.data.pgData.pgOrderKey;
      } catch (e) {
        // console.log('[checkout] step1. 결제 api 호출 후 error : ', e);
        setCheckoutMessage('');
        if (e.message) {
          // gtag 구매 과정 에러 추적 태그
          gtagException(e.message);
          return alert(e.message);
        }
      }
    }
  };

  const onSuccess = async data => {
    const currentChargePoint = localStorage.getItem(ls.chargePoint);
    const paypalData = data;
    const params = JSON.stringify({
      data: paypalData,
    });
    // console.log('[point] step2. 결제 진행 params : ', params);

    if (currentChargePoint) {
      setIsProcessing(true);
      try {
        const { data } = await createCompletePaypalInPoint(params);
        if (data) setIsProcessing(false);
        // console.log('[point] step2. 결제 진행 api 호출 후 data : ', data);
        onRefresh();
      } catch (e) {
        // console.log('[point] step2. 결제 진행 api 호출 후 error : ', e);
        setIsProcessing(false);
        if (e.message) {
          // gtag 구매 과정 에러 추적 태그
          gtagException(e.message);
          return alert(e.message);
        }
      }
    } else {
      // checkout 페이지
      setCheckoutMessage(PROCESSING_PAYMENT);
      // console.log('[checkout] step2. 결제 진행 orderID : ', paypalData.orderID);
      try {
        const { data } = await createCheckoutPayment('checkoutRequest-process', {
          pgData: { pg: 'paypal', pgOrderKey: paypalData.orderID },
          pgBag: null,
        });
        // console.log('[checkout] step2. 결제 진행 api 호출 후 data : ', data);
        // 구글 태그
        gtagPurchase({
          value: Number(localStorage.getItem(ls.totalAmount)),
          orderno: data.data.orderno,
        });
        setCheckoutMessage('');
        navigate(`/checkout/${data.data.orderno}`);
      } catch (e) {
        // console.log('[checkout] step2. 결제 진행 api 호출 후 error : ', e);
        setCheckoutMessage('');
        if (e.message) {
          // gtag 구매 과정 에러 추적 태그
          gtagException(e.message);
          return alert(e.message);
        }
      }
    }
  };
  return <PayPalButtons onClick={onClick} createOrder={onSubmit} onApprove={onSuccess} />;
}

export default Paypal;
