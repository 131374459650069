import styled from 'styled-components';

export const SocialButton = styled.button`
  padding: 0;
  border: none;
  margin-bottom: 16px;

  img {
    width: 100%;
    cursor: pointer;
  }
`;
