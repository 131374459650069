import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../../context/user/user';
import { ITEMS, SNS_SIGNUP } from '../../route/constant';
import { Ss, ls } from '../../constant';
import { yahooLogin } from '../../action/request';
import { gtagOnClickLogin } from '../../action/gTag';
import { RETURN_CODE } from '../../action/constant';
import { isWelcomeModal } from '../../atom/atom';
import { useRecoilValue } from 'recoil';

function YahooCallback() {
  const isWelcomeModalValue = useRecoilValue(isWelcomeModal);
  const location = useLocation();
  const [code, setCode] = useState();
  const { state: userState, action: userAction } = useContext(UserContext);
  const navigate = useNavigate();

  // 인가코드 가져오기
  const getCode = async () => {
    const urlParams = new URLSearchParams(location.search);
    const authorizationCode = urlParams.get('code');
    setCode(authorizationCode);
    // console.log('authorizationCode', authorizationCode);
    return code;
  };

  // 코드로 유저 정보 가져오기
  const getUserInfo = async code => {
    try {
      const { data: res } = await yahooLogin(code);
      // console.log('res', res);
      // console.log('Yahoo get User 요청 완료');

      // block된 유저일 경우
      if (res.returnCode === RETURN_CODE.C00008) {
        alert(res.message);
        return navigate('/');
      }

      const userInfo = res.data.userInfo;
      const userToken = res.data.token;
      // console.log(typeof res.data.id_uesr);

      // 로그인처리
      if (userInfo) {
        // token을 localStorage에 저장
        localStorage.setItem(ls.id, userInfo.id_uesr);
        localStorage.setItem(ls.accessToken, userToken.access_token);
        localStorage.setItem(ls.refreshToken, userToken.refresh_token);
        // userInfo context에 저장
        userAction.setEmail(userInfo.email);
        userAction.setId(userInfo.id_uesr);
        userAction.setIsLogin(true);
        userAction.setThumbnail(userInfo.thumbnail);
        userAction.setIsLogin(false);
        if (sessionStorage.getItem(Ss.itemNumber)) {
          navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
          sessionStorage.removeItem(Ss.itemNumber);
        }
        if (isWelcomeModalValue) {
          navigate('/');
          localStorage.removeItem(ls.loginPath);
        } else {
          navigate(localStorage.getItem(ls.loginPath));
          localStorage.removeItem(ls.loginPath);
        }
        //
        // else {
        //   // 로그인하면 로컬스토리지에 저장 된 기존에 있던 위치로 이동
        //   navigate(localStorage.getItem(ls.loginPath));
        //   // 로그인 위치로 이동 후 로그인 위치 정보 삭제
        //   localStorage.removeItem(ls.loginPath);
        // }
        //
        // gtag 야후 로그인 추적 태그
        gtagOnClickLogin('Yahoo');
      }
      // 회원가입 처리
      if (res.data.id_user === null) {
        // console.log('회원가입');
        // user context에 data 저장
        userAction.setSnsUserInfo(res.data);
        navigate(SNS_SIGNUP);
      }
    } catch (error) {
      // console.error(error);
      navigate('/');
      alert('Request failed.');
    }
  };

  // 뒤로가기 방지
  // TODO: 에러 화면 처리
  useEffect(() => {
    if (userState.snsUserInfo && console.error) {
      navigate(1);
    }
  }, [navigate]);

  useEffect(() => {
    getCode();
  }, [location.search, navigate]);

  useEffect(() => {
    if (code !== undefined) {
      getUserInfo(code);
    }
  }, [code]);

  return null;
}

export default YahooCallback;
