import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './route/Router';
import { LangProvider } from './context/lang/lang';
import { ModalProvider } from './context/modal/modal';
import { HelmetProvider } from 'react-helmet-async';
import { UserProvider } from './context/user/user';
import { CartProvider } from './context/cart.js/carts';
import { PAYPAL_CLIENT_ID } from './page/My/Point/component/constant';
import { downloadSdk } from './util/sdk';
import ReactGA4 from 'react-ga4';
import { StickyContainer } from 'react-sticky';
import { RecoilRoot } from 'recoil';

// 형식: 일(날짜) - 버전 업
// ex) 24년 01월 05일 첫번째 버전 > 5-1
// ex) 24년 01월 05일 세번째 버전 > 5-3

console.info('*** version: 08-03 ***');

const root = ReactDOM.createRoot(document.getElementById('root'));

const paypalUrl = 'https://www.paypal.com/sdk/js?client-id=';

const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS; // 환경 변수에 저장된 추적ID 가져오기
// react-ga 초기화
ReactGA4.initialize(gaTrackingId, {
  debug: true,
  alwaysSendToDefaultTracker: false,
  titleCase: false,
});

// page view 추적
if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
  ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
}

downloadSdk(paypalUrl + PAYPAL_CLIENT_ID);
root.render(
  // <React.StrictMode>
  <RecoilRoot>
    <HelmetProvider>
      <LangProvider>
        <ModalProvider>
          <UserProvider>
            <CartProvider>
              <StickyContainer>
                <RouterProvider router={router} />
              </StickyContainer>
            </CartProvider>
          </UserProvider>
        </ModalProvider>
      </LangProvider>
    </HelmetProvider>
  </RecoilRoot>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
