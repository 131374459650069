import React, { useState } from 'react';
import styled from 'styled-components';

const Dropdown = ({ options, onChangeSort, selectedSort }) => {
  const optionData = options?.filter(el => el.value !== selectedSort.value);
  const [isOpen, setIsOpen] = useState(false);

  const onClickSort = sort => {
    onChangeSort(sort);
    setIsOpen(false);
  };

  return (
    <DropdownWrap>
      <div className='seleted' onClick={() => setIsOpen(!isOpen)}>
        <button>{selectedSort?.title}</button>
        <img src='/assets/img/designers/icon_down.png' alt='' className='icon' />
      </div>
      <Options>
        {isOpen ? (
          <>
            {optionData?.map((option, key) => {
              return (
                <Option key={key} onClick={() => onClickSort(option)}>
                  {option.title}
                </Option>
              );
            })}
          </>
        ) : (
          ''
        )}
      </Options>
    </DropdownWrap>
  );
};

export default Dropdown;

const DropdownWrap = styled.div`
  position: relative;

  .seleted {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
  }
  button {
    border: none;
    font-weight: 600;
    color: #171717;
    font-size: 16px;
    background-color: #fff;
    margin-right: 5px;
    cursor: pointer;
  }

  .icon {
    width: 10px;
    padding-bottom: 5px;
    cursor: pointer;
  }
`;

const Options = styled.ul`
  position: absolute;
  right: 0px;
  padding-top: 30px;
  z-index: 999;
  background-color: #ffffff;
  padding: 0px 20px !important;
`;

const Option = styled.li`
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  color: #171717;
  font-size: 16px;
  cursor: pointer;
  img {
    width: 11px;
    padding-bottom: 8px;
    margin-left: 5px;
  }
`;
