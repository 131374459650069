import { useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import CheckoutProcess from './component/CheckoutProcess';
import CheckoutProduct from './component/CheckoutProduct';
import License from './component/License';
import './CheckoutConfirm.css';
import Payment from './component/Payment';
import { NOT_AGREED_MESSAGE, PROCESSING_PAYMENT } from '../My/Point/component/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { createCheckoutPayment, createCheckoutStep2 } from '../../action/request';
import { ls } from '../../constant';
import { gtagBeginCheckout } from '../../action/gTag';
import TroubleCheckOut from '../../component/checkOut/TroubleCheckOut';

const CheckoutConfirm = () => {
  const [isAgree, setIsAgree] = useState(false);
  const [checkoutMessage, setCheckoutMessage] = useState('');
  const [checkout, setCheckout] = useState({});
  const [requestId, setRequestId] = useState('');

  const location = useLocation();
  const credit = location.state.coin;
  const code = location.state.code;

  const navigate = useNavigate();

  const isLoginUsingToken =
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== 'null' &&
    localStorage.getItem(ls.accessToken);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const { data: res } = await createCheckoutStep2(credit, code);
      setCheckout(res.data.checkoutDto);
      setRequestId(res.data.checkoutDto.requestId);
      // gtag 결제 페이지 로드 추적 태그
      gtagBeginCheckout(Number(res.data.checkoutDto.finalPrice.toFixed(2)));
    } catch (e) {}
  };

  const checkReadyForPay = () => {
    if (!isAgree && localStorage.getItem(ls.isAgree) !== 'true') {
      setCheckoutMessage(NOT_AGREED_MESSAGE);
      return false;
    }

    setCheckoutMessage('');
    return true;
  };

  const onCheckoutFree = async () => {
    if (checkReadyForPay()) {
      setCheckoutMessage(PROCESSING_PAYMENT);
      await createFreeCheckout();
    }
  };

  // free checkout 결제 생성
  const createFreeCheckout = async () => {
    try {
      const { data } = await createCheckoutPayment('checkoutRequest-create', {
        method: 'free',
        requestId,
      });
      setCheckoutMessage('');
      await confirmFreeCheckout(data.data.pgData, data.data.pgBag);
    } catch (e) {
      setCheckoutMessage('');
      if (e.message) return alert(e.message);
    }
  };

  // free checkout 결제 진행
  const confirmFreeCheckout = async (pgData, pgBag) => {
    try {
      const { data } = await createCheckoutPayment('checkoutRequest-process', {
        pgData,
        pgBag,
      });
      setCheckoutMessage('');
      navigate(`/checkout/${data.data.orderno}`);
    } catch (e) {
      setCheckoutMessage('');
      if (e.message) return alert(e.message);
    }
  };

  useEffect(() => {
    localStorage.setItem(ls.totalAmount, Number(checkout.finalPrice));
  }, [checkout]);

  useEffect(() => {
    localStorage.removeItem(ls.isAgree);
    localStorage.removeItem(ls.totalAmount);
    localStorage.removeItem(ls.chargePoint);

    return () => {
      localStorage.removeItem(ls.isAgree);
      localStorage.removeItem(ls.totalAmount);
    };
  }, []);

  // 로그인 안한 상태일 때 접근 불가 (프론트 처리)
  useEffect(() => {
    if (!isLoginUsingToken) navigate('/');
  }, []);
  if (!isLoginUsingToken) return null;

  if (!checkout.products) return null;
  return (
    <>
      <Layout>
        <div id='main'>
          <CheckoutProcess step={2} />
          <section className='shoppingbag-section checkout-confirm-page'>
            <div className='container'>
              <div className='shoppingbag'>
                <div className='cart'>
                  <div className='content-box'>
                    <div className='content-box-header'>
                      <h2>
                        Cart <small className='badge'>{checkout.products.length}</small>
                      </h2>
                    </div>
                    <div className='content-box-body'>
                      <CheckoutProduct data={checkout.products} />
                      <hr />
                      <License
                        isAgree={isAgree}
                        setIsAgree={setIsAgree}
                        checkoutMessage={checkoutMessage}
                      />
                      <hr />
                    </div>
                  </div>
                </div>

                <div className='total'>
                  <div
                    id='checkout-alert'
                    className={
                      !checkoutMessage.length
                        ? 'hidden'
                        : checkoutMessage.includes('License')
                        ? 'error show'
                        : 'loading show'
                    }
                  >
                    {checkoutMessage}
                  </div>
                  <div className='your-order-box'>
                    <h3>Checkout</h3>
                    <div className='your-order-info'>
                      <div className='price-info'>
                        <dl>
                          <dt>Product price</dt>
                          <dd>
                            <span className='notranslate'>
                              {Number(checkout.salesPrice).toFixed(2)}$
                            </span>
                          </dd>
                        </dl>
                        <dl>
                          <dt>Discount</dt>
                          <dd>
                            <span className='txt-red notranslate'>
                              - {Number(checkout.discount).toFixed(2)}$
                            </span>
                          </dd>
                        </dl>
                      </div>
                      <div className='price-info'>
                        <dl>
                          <dt>Sub total</dt>
                          <dd>
                            <span className='notranslate'>
                              {Number(checkout.subtotal).toFixed(2)}$
                            </span>
                          </dd>
                        </dl>
                        <dl>
                          <dt>Use Credit</dt>
                          <dd>
                            <span className='txt-red notranslate'>
                              - {Number(checkout.mileage).toFixed(2)}$
                            </span>
                          </dd>
                        </dl>
                      </div>

                      <dl>
                        <dt>Total</dt>
                        <dd>
                          <span className='notranslate'>
                            <strong className='total-price'>
                              {Number(checkout.finalPrice).toFixed(2)}$
                            </strong>
                          </span>
                        </dd>
                      </dl>
                    </div>
                    {parseFloat(checkout.finalPrice) === 0 && (
                      <button
                        className='btn btn-fill btn-green btn-full'
                        data-action='1501-checkout-free'
                        onClick={onCheckoutFree}
                      >
                        Free Checkout
                      </button>
                    )}
                    {parseFloat(checkout.finalPrice) > 0 && (
                      <Payment
                        checkout={checkout}
                        checkReadyForPay={checkReadyForPay}
                        setCheckoutMessage={setCheckoutMessage}
                        requestId={requestId}
                        totalAmount={Number(checkout.finalPrice)}
                        checkoutMessage={checkoutMessage}
                      />
                    )}
                  </div>
                  <TroubleCheckOut />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default CheckoutConfirm;
