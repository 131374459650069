/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { downloadItem, welcomeUserFreeDownload } from '../../action/request';
import { RETURN_CODE } from '../../action/constant';
import { useSetRecoilState } from 'recoil';
import { isWelcomeModal } from '../../atom/atom';
import { Link } from 'react-router-dom';

const DownloadProcessingModal = ({ step, setStep, itemId, WelcomeUser }) => {
  const isWelcomeModalState = useSetRecoilState(isWelcomeModal);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    const randomDuration = Math.random() * (0.6 - 0.3) + 0.3; // 0.3부터 0.6 사이의 랜덤한 값
    const startTime = Date.now();

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const percentage = Math.min((elapsedTime / (randomDuration * 1000)) * 100, 100);
      setPercent(Math.floor(percentage)); // 정수로 변환

      // progress가 100이 되면 step 증가
      if (Math.floor(percentage) === 100) {
        setStep(prev => prev + 1);
      }
    };

    const interval = setInterval(() => {
      updateProgress();

      // 조건을 추가하여 3번 동작하면 clearInterval 호출

      if (step >= 4) {
        clearInterval(interval);
        setPercent(100);
      }
    }, 40);

    // Cleanup function
    return () => clearInterval(interval);
  }, [step]);

  useEffect(() => {
    // step 변경될 때마다 새로운 동작을 시작하도록 설정
    if (step < 4) {
      setPercent(0);
    }
  }, [step]);

  // step 3번 동작하고 100% 되면 'I Agree'버튼 활성화
  useEffect(() => {
    if (step === 4 && percent === 100) {
      setBtnDisabled(false);
    }
  }, [step, percent]);

  // 'I Agree'버튼 눌러서 다운로드
  const clickAgree = async itemId => {
    //만약 WelcomeUser 라면 5개의 배열이 담긴 free 아이템 get
    if (WelcomeUser) {
      try {
        const { data: downloadRes } = await welcomeUserFreeDownload();

        if (downloadRes.returnCode === RETURN_CODE.C00000) {
          for (const url of downloadRes.data.urls) {
            window.location.href = url;
            await new Promise(resolve => setTimeout(resolve, 2000)); // 5초 동안 대기

            isWelcomeModalState(false);
          }
        }
      } catch (e) {
        console.error('Error during download:', e);
        alert(e.response.data.message);
      } finally {
        //로컬에서 welcome 모달 완전 삭제
        let storedState = JSON.parse(window.localStorage.getItem('atom'));
        delete storedState.isWelcomeModal;
        window.localStorage.setItem('atom', JSON.stringify(storedState));
      }
    } else {
      try {
        const { data: downloadRes } = await downloadItem({ idItem: itemId });

        if (downloadRes.returnCode === 'C00000') {
          window.location.href = downloadRes.data.url;
        }
      } catch (e) {
        console.error('Error during download:', e);
        alert(e.response.data.message);
      }
    }

    setStep(0);
  };

  return (
    <>
      {/* 다운로드 모달 */}
      {step > 0 && (
        <ModaStyle className='DownloadProcessingModal' isWelcomeModalState={isWelcomeModalState}>
          <div className={'modal active downLoad'} id='modal1'>
            <div className='modal-inner'>
              <div className='modal-box'>
                <a
                  href='#'
                  className='btn-close'
                  onClick={e => {
                    e.preventDefault();
                    setStep(0);
                  }}
                >
                  &times;
                </a>
                <div className='sales modal-box-body'>
                  <h2 className='title'>Download Processing</h2>
                  <div className='fab-form-block'>
                    {/* 다운로드 프로그레스 */}
                    <ProgressBarStyle percent={percent}>
                      <ul>
                        <li style={{ color: step === 1 && '#222222' }}>
                          &gt; Generating unique identification code
                        </li>
                        <li style={{ color: step === 2 && '#222222' }}>&gt; Encrypting File</li>
                        <li style={{ color: step >= 3 && '#222222' }}>&gt; Finishing process</li>
                      </ul>
                      <div className='progress-bar-parent'>
                        <div className='progress-bar-chlid' style={{ width: `${percent}%` }}></div>
                      </div>
                      <p>{percent}%</p>
                    </ProgressBarStyle>
                    {/* 다운로드 notice */}
                    <NoticeStyle className='download-notice' percent={percent}>
                      <div>
                        <img
                          src='../../assets/img/notice/download_notice.png'
                          alt='download notice icon'
                        />
                        <span>Do not share the STL file</span>
                        <img
                          src='../../assets/img/notice/download_notice.png'
                          alt='download notice icon'
                        />
                      </div>
                      <p>
                        Fab365 respects the right to intellectual property. Each user is
                        distinguished by the unique identification code that is encrypted when the
                        user downloads an STL file. Any unauthorized sharing of downloaded STL files
                        can lead to negative consequences.
                      </p>
                      <div className='licenseWrap'>
                        <p className='license'>For more details, please refer to the </p>
                        <Link target='_blank' className='userLicense' to={'/user-license'}>
                          User License Agreement.
                        </Link>
                      </div>
                    </NoticeStyle>
                  </div>
                  <div className='fab-form-action'>
                    <button
                      id='request-withdrawal'
                      className='btn btn-fill btn-light-green'
                      disabled={btnDisabled}
                      onClick={() => {
                        clickAgree(itemId);
                      }}
                      style={{ textAlign: 'center' }}
                    >
                      I Agree
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModaStyle>
      )}
    </>
  );
};

export default DownloadProcessingModal;

const ModaStyle = styled.div`
  .modal-box-body {
    padding-top: 3.125rem;
  }
  .title {
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    min-height: 2.125rem;
    border-bottom: 1px solid #999;
  }
  .fab-form-action {
    margin: 2.5rem auto 3.5rem;
    display: flex;
    gap: 1.25rem;
  }
  .fab-form-action {
    button {
      font-family: Montserrat, sans-serif !important;
      font-weight: 700;
      margin: auto;
      width: auto;
      border-radius: 5px;
      min-width: 50%;
    }
    button[disabled] {
      background: #c6c6c6 !important;
      border-color: #c6c6c6 !important;
      color: #fff !important;
      cursor: not-allowed;
    }
  }
  .download-notice {
    text-align: center;
  }
  /* @media screen and (max-width: 640px) {
    .modal-box-body {
      overflow: scroll;
      padding: 3.125rem 0;
    }
  } */
`;

const ProgressBarStyle = styled.div`
  width: 100%;
  ul {
    color: #c6c6c6;
    padding-bottom: 2rem;
    li {
      padding: 0.4rem 0;
      font-size: 0.9rem;
    }
  }
  p {
    text-align: center;
    font-size: 2rem;
    color: #4c00fb;
    padding: 1rem 0 2.5rem 0;
  }
  .progress-bar-parent {
    width: 100%;
    height: 10px;
    background-color: #c6c6c6;
  }
  .progress-bar-chlid {
    height: 100%;
    background-color: #28ca40;
  }
`;

const NoticeStyle = styled.div`
  width: 100%;
  margin: auto;
  span {
    font-size: 1.9rem;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.2rem;
    text-align: left;
    padding-top: 2rem;
  }
  div {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    width: 36px;
    height: 36px;
  }

  .licenseWrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    .license {
      display: inline-block;
      padding-top: 0px !important;
    }
  }

  .licenseWrap .userLicense {
    color: #4200f6;
    font-size: 0.9rem;
    line-height: 1.2rem;

    border: none;
    background-color: #fff;
    cursor: pointer;
    margin-left: 5px;
  }
  @media screen and (max-width: 640px) {
    width: 95%;

    div {
      width: 90%;
    }
    .modal.active {
      position: static;
      overflow: visible;
    }
  }
`;
